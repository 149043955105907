import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useEmploymentDataState } from '../_employmentDataState'
import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import Loader from '../../../components/Loader'

interface FormType {
  employmentType: string
}

const EmploymentScreen = () => {
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: employmentData } = useEmploymentDataState()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<FormType>({
    defaultValues: {
      employmentType: investData?.employment?.employmentType || '',
    },
  })

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    nav(
      `/invest/${investData?.investment
        .id}/employment/${data.employmentType.toLowerCase()}`
    )
  })

  // const backLocation = `/invest/${investData?.investment.id}/aboutyou/1`

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={8} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={7} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('employment:employment')}
          </h1>
          {employmentData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {t('employment:provideEmploymentInfo')}
              </p>
              <p className="mb-2 text-16 text-content-black">
                {t('employment:employmentStatus')}
              </p>
              <form onSubmit={onSubmit}>
                <Controller
                  name="employmentType"
                  control={control}
                  render={({ field }) => (
                    <div className="mb-6 flex flex-col gap-px">
                      <Radio
                        {...field}
                        value="employed"
                        label={t('employment:employed')}
                        checked={field.value === 'employed'}
                      />
                      <Radio
                        {...field}
                        value="selfEmployed"
                        label={t('employment:selfEmployed')}
                        checked={field.value === 'selfEmployed'}
                      />
                      <Radio
                        {...field}
                        value="retired"
                        label={t('employment:retired')}
                        checked={field.value === 'retired'}
                      />
                      <Radio
                        {...field}
                        value="student"
                        label={t('employment:student')}
                        checked={field.value === 'student'}
                      />
                      <Radio
                        {...field}
                        value="notEmployed"
                        label={t('employment:notEmployed')}
                        checked={field.value === 'notEmployed'}
                      />
                    </div>
                  )}
                />
                <FormFooter submitFunc={onSubmit} disabled={!isDirty} />
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default EmploymentScreen
