import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useInvestDataState } from '../_investDataState'
import { useAccountDataState } from '../_accountDataState'

import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import Loader from '../../../components/Loader'

interface AccountFormTypes {
  type: string
  id: string
}

const AccountScreen = () => {
  const nav = useNavigate()
  // const location = useLocation()
  const { t } = useTranslation()
  const { data: investData } = useInvestDataState()
  const { data: accountData } = useAccountDataState()

  const { control, handleSubmit } = useForm<AccountFormTypes>({
    defaultValues: {
      type: investData?.account?.type || 'individual',
      id: investData?.account?.id || '',
    },
  })

  // const backLocation = location?.state?.from
  //   ? location?.state?.from
  //   : `/invest/${investData?.investment.id}/start`

  const onSubmit = handleSubmit((data) => {
    switch (data.type) {
      case 'individual':
        nav(`/invest/${investData.opportunity.id}/account/individual`)
        break
      case 'joint':
        nav(`/invest/${investData.opportunity.id}/account/joint`)
        break
      case 'trust':
        nav(`/invest/${investData.opportunity.id}/account/trust`)
        break
      case 'entity':
        nav(`/invest/${investData.opportunity.id}/account/entity`)
        break
      case 'sdira':
        nav(`/invest/${investData.opportunity.id}/account/retirement`)
        break
    }
  })

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={0} currentStep={2} totalStepCount={3} />
      ) : (
        <Stepper
          currentSection={0}
          currentStep={2}
          totalStepCount={4}
          isPledge={
            investData.opportunity.status.toLowerCase() === 'open for pledging'
          }
        />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('common:ownershipType')}
          </h1>
          {accountData ? (
            <>
              <p className="mb-6 text-16 text-content-black">
                {t('account:step')}
              </p>
              <form onSubmit={onSubmit} className="xl:">
                <div className="mb-6">
                  <p className="mb-2 text-16 text-content-black">
                    {t('common:ownershipType')}
                  </p>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field, fieldState }) => (
                      <div
                        className={`flex flex-col gap-px ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        <Radio
                          {...field}
                          value="individual"
                          label={t('common:individual')}
                          checked={field.value === 'individual'}
                        />
                        <Radio
                          {...field}
                          value="joint"
                          label={t('common:joint')}
                          checked={field.value === 'joint'}
                        />
                        <Radio
                          {...field}
                          value="trust"
                          label={t('common:trust')}
                          checked={field.value === 'trust'}
                        />
                        <Radio
                          {...field}
                          value="entity"
                          label={t('common:entity')}
                          checked={field.value === 'entity'}
                        />
                        {accountData?.ia_create_type?.includes('foe_sdira') && (
                          <Radio
                            {...field}
                            value="sdira"
                            label={t('common:sdira')}
                            checked={field.value === 'sdira'}
                          />
                        )}
                      </div>
                    )}
                  />
                </div>
                <FormFooter submitFunc={onSubmit} />
              </form>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default AccountScreen
