import { create } from 'zustand'

type NotificationsStore = {
  hideNewMessages: boolean
  hideTodos: boolean
  removeNewMessagesNotification: () => void
  removeTodosNotification: () => void
}

export const useNotificationsStore = create<NotificationsStore>((set) => ({
  hideNewMessages: false,
  hideTodos: false,
  removeNewMessagesNotification: () => set({ hideNewMessages: true }),
  removeTodosNotification: () => set({ hideTodos: true }),
}))
