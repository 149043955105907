interface StepperProps {
  currentSection: number
  currentStep: number
  totalStepCount: number
  isPledge?: boolean
}

export const Stepper = (props: StepperProps) => {
  const {
    currentStep,
    totalStepCount,
    currentSection = 0,
    isPledge = false,
  } = props

  const Steps = () => (
    <div className="flex flex-1 select-none gap-[3px] leading-[6px] xl:ml-3">
      {[...Array(totalStepCount)].map((_, index) => (
        <div
          className={`${
            index + 1 <= currentStep ? 'bg-primary' : 'bg-action-lighter'
          } w-full first:rounded-l last:rounded-r xl:w-10`}
          key={index}
        >
          &nbsp;
        </div>
      ))}
    </div>
  )

  return (
    <>
      <div className="mb-6 w-full rounded bg-bg-lighter p-4 xl:hidden">
        <div className="mb-2 flex justify-between font-sans text-[13px]">
          <div
            className={`${
              currentSection === 0
                ? 'font-medium text-content-black'
                : 'font-normal text-content-lighter'
            }`}
          >
            Investor Info
          </div>
          <div
            className={`${
              currentSection === 1
                ? 'font-medium text-content-black'
                : 'font-normal text-content-lighter'
            }`}
          >
            {!isPledge ? 'Suitability' : 'Suitability & Submit'}
          </div>
          {!isPledge && (
            <div
              className={`${
                currentSection === 2
                  ? 'font-medium text-content-black'
                  : 'font-normal text-content-lighter'
              }`}
            >
              Sign & Fund
            </div>
          )}
        </div>
        <Steps />
      </div>
      <div className="hidden w-full text-[14px] xl:block mb-8">
        <div className="flex justify-start font-sans">
          <div
            className={`${
              currentSection === 0 ? 'bg-white' : 'bg-transparent'
            } flex items-center rounded-t pr-4 py-4`}
          >
            <div className="mr-3">
              {currentSection > 0 ? (
                <i className="fa-solid fa-circle-check fa-lg text-success"></i>
              ) : (
                <i className="fa-solid fa-circle-1 fa-lg text-primary"></i>
              )}
            </div>
            <div
              className={`${
                currentSection === 0
                  ? 'font-medium text-content-black'
                  : 'font-normal text-content-light'
              }`}
            >
              Investor Info
            </div>
            {currentSection === 0 && <Steps />}
          </div>
          <div
            className={`${
              currentSection === 1 ? 'bg-white' : 'bg-transparent'
            } flex items-center rounded-t p-4`}
          >
            {currentSection > 1 && (
              <i className="fa-solid fa-circle-check fa-lg text-success mr-3"></i>
            )}
            {currentSection === 1 && (
              <i className="fa-solid fa-circle-2 fa-lg text-primary mr-3"></i>
            )}
            {currentSection < 1 && (
              <i className="fa-solid fa-circle-2 fa-lg mr-3 text-action-light"></i>
            )}
            <div
              className={`${
                currentSection === 1
                  ? 'font-medium text-content-black'
                  : 'font-normal text-content-light'
              }`}
            >
              {!isPledge ? 'Suitability' : 'Suitability & Submit'}
            </div>
            {currentSection === 1 && <Steps />}
          </div>
          {!isPledge && (
            <div
              className={`${
                currentSection === 2 ? 'bg-white' : 'bg-transparent'
              } flex items-center rounded-t p-4`}
            >
              <i
                className={`fa-solid fa-circle-3 fa-lg mr-3 ${
                  currentSection === 2 ? 'text-primary' : 'text-action-light'
                }`}
              ></i>
              <div
                className={`${
                  currentSection === 2
                    ? 'font-medium text-content-black'
                    : 'font-normal text-content-light'
                }`}
              >
                Sign & Fund
              </div>
              {currentSection === 2 && <Steps />}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Stepper
