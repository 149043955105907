import { useLocation, Link } from 'react-router-dom'
import { cn } from '@/utils/utils'

const SubItem = ({
  icon,
  text,
  location,
  isActive = false,
}: {
  icon: string
  text: string
  location: string
  isActive?: boolean
}) => (
  <Link
    to={location}
    className={cn(
      'px-4 py-1 items-center gap-2 flex cursor-pointer hover:bg-white',
      isActive && 'bg-white cursor-auto'
    )}
  >
    <i
      className={cn(
        'fa-regular fa-sm',
        `fa-${icon}`,
        isActive && 'fa-solid text-primary'
      )}
    />
    <div
      className={cn(
        'text-content-black font-normal',
        isActive && 'font-medium'
      )}
    >
      {text}
    </div>
  </Link>
)

export const ProfileSubNav = () => {
  const location = useLocation()

  return (
    <div className="inline-flex bg-bg-light border border-bg-light text-16 text-content-black rounded">
      <SubItem
        icon="info-circle"
        text="Personal Info"
        location="/dashboard/profile"
        isActive={location.pathname === '/dashboard/profile'}
      />
      <SubItem
        icon="envelope"
        text="Email & Security"
        location="/dashboard/profile/email"
        isActive={location.pathname === '/dashboard/profile/email'}
      />
      <SubItem
        icon="message"
        text="Communication Preferences"
        location="/dashboard/profile/comm-prefs"
        isActive={location.pathname === '/dashboard/profile/comm-prefs'}
      />
    </div>
  )
}
