import { useQuery } from '@tanstack/react-query'

import { getDrupalUrl, getEmailVerifiedStatus } from '../shared/_api'

export const useEmailVerifiedDataState = () => {
  return useQuery({
    queryKey: ['emailVerified'],
    queryFn: getEmailVerifiedStatus,
    onSuccess: (data) => {
      if (!data.emailVerified) {
        window.location.href =
          getDrupalUrl() + 'please-verify-your-email-address'
      }
      // TODO: switch to the verify email page when dashboard is up
      // if (
      //   !data.emailVerified &&
      //   window.location.pathname !== 'dashboard/profile/verify-email'
      // ) {
      //   window.location.href = '/dashboard/profile/verify-email'
      //   return
      // }
    },
    staleTime: Infinity,
  })
}
