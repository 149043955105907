// checks if any of the filters are set in the URL search params

export const hasSpecificFilters = (filterNames: string[]): boolean => {
  const searchParams = new URLSearchParams(window.location.search)
  for (const filterName of filterNames) {
    if (searchParams.get(filterName)) {
      return true
    }
  }
  return false
}
