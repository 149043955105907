export const failed = {
  stateHeader: `We're sorry, we can't continue with this investment request.`,
  stateCopy: `Thank you for indicating your interest to invest in {{ioName}}.
  <br /><br/>
  We apologize, but we are unable to process your investment in {{ioName}} at this time. We are unable to provide brokerage services to investors in {{state}} because our registration with the state is pending. We hope to complete this registration soon.
  <br /><br />
  We appreciate your understanding. If you have any questions, please reach out to `,
  accredHeader: `We're sorry, we can't continue with this investment request.`,
  accred1: `Securities regulations require that only accredited investors can invest in this opportunity.`,
  employmentHeader: `We're sorry, we can't continue with this investment request.`,
  employment1: `You are required to have approvals from your employer before you can continue with this investment. Once you have the approvals, please return to the Pending Investments section of your dashboard and continue with this investment. If you would like to reserve your space in this investment in the meantime, please email us at`,
  fundedHeader: `We're sorry, we can't move forward with your investment at this time.`,
  funded: `Investments are funded on a first to fund basis. As you did not fund your investment in time, this investment is now full and can no longer accommodate additional investment. We will notify you if new room or another subscription for this investment becomes available.`,
  fullyPledged: `This opportunity is fully pledged and can no longer accommodate additional pledge requests. We will notify you if new room or another subscription for this investment becomes available.`,
  seeOtherOpps: `See Other Opportunities`,
}
