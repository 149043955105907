import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getConcentration, updateConcentration } from '../shared/_api'
import { useInvestDataState } from './_investDataState'

export const useConcentrationDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['concentration'],
    queryFn: async () => {
      const data = await getConcentration(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData?.investment,
    staleTime: Infinity,
  })
}

export const useMutateConcentration = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['concentration'],
    mutationFn: async (data: any) => {
      const res = await updateConcentration(investData?.investment?.id, data)
      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
    },
  })
}
