import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'

import { getEmployment, updateEmployment } from '../shared/_api'

export const useEmploymentDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['employment'],
    queryFn: async () => {
      const data = await getEmployment(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData.investment,
    staleTime: Infinity,
  })
}

export const useMutateEmployment = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['employment'],
    mutationFn: async (data: any) => {
      const res = await updateEmployment(investData?.investment?.id, data)
      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['employment'],
      })
    },
  })
}
