import { track } from '../../utils/analytics'
import { useNavigate } from 'react-router-dom'
interface FormFooterPropTypes {
  backLocation?: string
  submitFunc: () => void
  disabled?: boolean
  buttonText?: string
  secondaryLink?: boolean
}

export const FormFooter = (props: FormFooterPropTypes) => {
  const nav = useNavigate()
  const {
    disabled = false,
    buttonText = 'Continue',
    submitFunc,
    backLocation,
    secondaryLink = false,
  } = props

  return (
    <div className="w-full bg-white">
      <div className="flex flex-col md:flex-row items-center gap-4 bg-transparent">
        {backLocation && (
          <button
            className="h-10 w-10 rounded border border-input bg-white md:hidden"
            data-cy="back-button-mobile"
            onClick={(e) => {
              e.preventDefault()
              track('Back Button Clicked')
              nav(backLocation)
            }}
          >
            <i className="fa-solid fa-chevron-left text-primary"></i>
          </button>
        )}
        {/*Todo: Make button into component */}
        <button
          className="h-10 w-full md:w-fit rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] disabled:opacity-50 hover:disabled:bg-primary md:min-w-[200px] transition duration-200 ease-in-out"
          data-cy="continue-button"
          onClick={(e) => {
            e.preventDefault()
            track('Continue Button Clicked')
            submitFunc()
          }}
          disabled={disabled}
        >
          {buttonText}
        </button>
        {secondaryLink && (
          <a
            href="https://www.realtymogul.com/investment-opportunities"
            className="text-primary"
          >
            View Additional Investment Opportunities
          </a>
        )}
        {backLocation && (
          <button
            className="hidden h-10 w-fit font-medium rounded border border-primary bg-white px-4 md:block text-primary hover:text-primary-hover hover:border-primary-hover"
            data-cy="back-button-desktop"
            onClick={(e) => {
              e.preventDefault()
              track('Back Button Clicked')
              nav(backLocation)
            }}
          >
            Back
          </button>
        )}
      </div>
    </div>
  )
}

export default FormFooter
