import { Controller, useForm } from 'react-hook-form'

import { usePatchProfile } from '../api/patch-profile'
import { AddressTypes } from '../../shared/address-schema'
import { toast } from '@/hooks/use-toast'

import { Button } from '@/components/ui/button'
import CountryInput from '@/components/CountryInput'
import ErrorAlert from '@/components/ErrorAlert'
import NumberInput from '@/components/NumberInput'
import StateDropdown from '@/components/StateDropdown'
import TextInput from '@/components/TextInput'

export const UpdateAddress = ({
  addressData,
  callback,
}: {
  addressData: AddressTypes | undefined
  callback: (screen: string) => void
}) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<AddressTypes>({
    defaultValues: {
      thoroughfare: addressData?.thoroughfare || '',
      premise: addressData?.premise || '',
      locality: addressData?.locality || '',
      country: addressData?.country || 'US',
      adminarea: addressData?.adminarea || 'AL',
      postcode: addressData?.postcode || '',
    },
  })

  const watchCountry = watch('country')

  const { mutateAsync: patchProfile, isLoading } = usePatchProfile()

  const onFormSubmit = handleSubmit((data: AddressTypes) => {
    if (!data) return
    console.log(errors, data)
    patchProfile(data).then(() => {
      toast({
        variant: 'success',
        description: 'Address updated.',
        position: 'top',
      })
      callback('')
    })
  })

  return (
    <div className="mt-10">
      <div className="bg-[#FFF3B9] text-base text-[#897C00] px-4 py-2 mb-6 w-fit">
        Updates to your contact information below will update your Individual
        Ownership Type and tax documents for the upcoming calendar year.
      </div>
      <div className="mb-6">
        Physical street addresses only. We cannot accept a P.O. Box.
      </div>
      <div className="max-w-[400px]">
        <form
          onSubmit={onFormSubmit}
          autoComplete="on"
          className="w-full mb-10"
        >
          {Object.keys(errors).length > 0 && (
            <ErrorAlert text="Please fix the error(s) below:" />
          )}
          <div className="mb-6">
            <CountryInput
              register={register('country')}
              errorMessage={errors?.country?.message}
              label="Country"
            />
          </div>
          <div className="mb-2">
            <Controller
              name="thoroughfare"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="Street"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="premise"
              control={control}
              render={({ field, fieldState }) => (
                <TextInput
                  placeholder="Address Line 2"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="locality"
              control={control}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <TextInput
                  label="City"
                  errorMessage={fieldState.error}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mb-10 flex wrap-row gap-3">
            <div className="w-[60%]">
              <Controller
                name="adminarea"
                control={control}
                rules={{
                  required: watchCountry === 'US' ? 'is required' : false,
                }}
                render={({ field, fieldState }) =>
                  watchCountry === 'US' ? (
                    <StateDropdown
                      errorMessage={fieldState?.error?.message}
                      label="State"
                      {...field}
                    />
                  ) : (
                    <TextInput
                      label="State/Province/Region"
                      errorMessage={fieldState?.error?.message}
                      {...field}
                    />
                  )
                }
              />
            </div>
            <div className="w-[40%]">
              <Controller
                name="postcode"
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <NumberInput
                    label={watchCountry === 'US' ? 'Zip Code' : 'Postal Code'}
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex gap-6">
            <Button
              variant="RM"
              size="RM"
              disabled={isLoading || !isDirty}
              onClick={onFormSubmit}
            >
              Update
            </Button>
            <Button
              variant="RMSecondary"
              size="RMSecondary"
              disabled={isLoading}
              onClick={(e) => {
                e.preventDefault()
                callback('')
              }}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
