import { useQuery } from '@tanstack/react-query'

import { api } from '@/routes/shared/_api'
import { messagesFiltersSchema, MessagesFilterTypes } from './schema'

export const getMessagesFilters = async () => {
  const response = await api.get('/dashboard/messages/filters')
  return messagesFiltersSchema.parse(response)
}

export const useMessagesFilters = () => {
  return useQuery<MessagesFilterTypes>({
    queryKey: ['messages-filters'],
    queryFn: getMessagesFilters,
    staleTime: Infinity,
  })
}
