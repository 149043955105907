import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  useEmploymentDataState,
  useMutateEmployment,
} from '../_employmentDataState'
import { findRoute } from '../../../rules/findRoute'

import FormFooter from '../../shared/FormFooter'
import TextInput from '../../../components/TextInput'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'

interface FormType {
  source_of_income: string
  source_of_wealth: string
}

const NotEmployed = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: employmentData } = useEmploymentDataState()
  const { isLoading, mutateAsync: mutateEmployment } = useMutateEmployment()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      source_of_income: employmentData?.source_of_income || '',
      source_of_wealth: employmentData?.source_of_wealth || '',
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    console.log(errors, data)
    mutateEmployment({
      ...data,
      employment_status: 'Not Employed',
    })
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            employment: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  // const backLocation = `/invest/${investData?.investment.id}/employment`

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={1} currentStep={9} totalStepCount={10} />
      ) : (
        <Stepper currentSection={1} currentStep={8} totalStepCount={9} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('employment:employment')}
          </h1>
          <p className="mb-6 text-base italic text-content-light">
            {t('employment:notEmployed')}
          </p>
          <p className="mb-6 text-16 text-content-black">
            {t('employment:provideInformationBelow')}
          </p>
          <form onSubmit={onSubmit} className="mb-14 xl:">
            <div className="mb-6">
              <Controller
                name="source_of_income"
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Source of Income"
                    placeholder="Source of Income"
                    helpText={t('employment:explainSource')}
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-6">
              <Controller
                name="source_of_wealth"
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <TextInput
                    label="Source of Wealth"
                    placeholder="Source of Wealth"
                    helpText={t('employment:largestContributor')}
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <FormFooter
              backLocation={`/invest/${investData?.investment.id}/employment`}
              submitFunc={onSubmit}
              disabled={isLoading}
            />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default NotEmployed
