import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'

import { api } from '@/routes/shared/_api'
import { transactionsSchema, TransactionsTypes } from './schema'

export const getTransactions = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/transactions', {
    params,
  })
  return transactionsSchema.parse(response)
}

export const useTransactions = () => {
  const [searchParams] = useSearchParams()
  return useQuery<TransactionsTypes>({
    queryKey: [
      'transactions',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('transaction_type'),
      searchParams.get('date_from'),
      searchParams.get('date_to'),
    ],
    queryFn: () => getTransactions(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!searchParams.get('page'),
  })
}
