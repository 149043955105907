import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getISQQuestions, updateISQ } from '../shared/_api'
import { useInvestDataState } from './_investDataState'

export const useIsqDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['isq'],
    queryFn: async () => {
      const data = await getISQQuestions(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData?.investment,
    staleTime: Infinity,
  })
}

export const useMutateISQ = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['isq'],
    mutationFn: async (data: any) => {
      const res = await updateISQ(investData?.investment?.id, data)
      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invest'] })
    },
  })
}
