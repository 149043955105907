export const completed = {
  received: `INVESTMENT PLACED`,
  congrats: `, congratulations on your investment. Please see below for information about the status of your investment.`,
  next: `What Happens Next`,
  reitAch1: `We are performing final due diligence on your investment. We will notify you within a few business days if we have any additional questions.`,
  reitAch2: `Once due diligence is complete, we will notify you via email prior to debiting your account and again when funds have been received. Your investment will then display as an active investment in your dashboard.`,
  reitAch3: `Please allow up to 5 business days for this ACH transaction to reflect in your bank account.`,
  reitWire1: `You have reserved your position to invest in this opportunity with your commitment to wire funds. If you need access to wire instructions please contact <a className="text-primary" href="mailto:{{helpEmail}}">{{helpEmail}}</a>.`,
  reitWire2: `We are in the process of performing a final diligence review of your investment. Upon approval of this investment, we will send you an email to {{userEmail}} with a link to access wire instructions to fund your investment. You will also need to confirm that you sent the wire transfer by clicking the link in the Wire Instructions email. Additionally, we kindly ask that you provide the wire reference number, also known as a Federal Number, to <a className="text-primary" href="mailto:{{operationsEmail}}">{{operationsEmail}}</a> so that we may accurately track and confirm the wire upon receipt.`,
  reitWire3: `PLEASE NOTE: RealtyMogul will never send wire instructions directly via email unless requested by you. You may validate wire instructions by phone by reaching your Investor Relations Representative at`,
  delayedFundingWire1: `After sending your wire transfer, we kindly ask that you provide the wire reference number, also known as a Federal Number, to <a className="text-primary" href="mailto:{{operationsEmail}}">{{operationsEmail}}</a> so that we may accurately track and confirm the wire upon receipt.`,
  questionsCopy: `If you have any questions, please don't hesitate to call
  Investor Relations at`,
  ach1: `You have submitted funds for your investment in {{ioName}}. Please allow up to 5 business days for this ACH transaction to reflect in your bank account.`,
  ach2: `We are in the process of performing a final diligence review of your investment. Upon final approval of your investment, you will be notified via email that your investment has been approved. The active investment and your countersigned investment document package will then display under the active investments tab of your investor dashboard.`,
  wire1: `You have reserved your position to invest in this opportunity with your commitment to wire funds. If you need access to wire instructions please contact `,
  wire2: `Once your wire has been received and the issuer has approved your investment, you will receive a confirmation email and your investment will display as an active investment in your dashboard.`,
  wire3: `PLEASE NOTE: RealtyMogul will never send wire instructions directly via email unless requested by you. You may validate wire instructions by phone by reaching your Investor Relations Representative at `,
}
