import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'

import {
  addManualBankAccount,
  getBankAccounts,
  getPlaidToken,
  getWireInstructions,
  updateBankAccount,
} from '../shared/_api'
import { InvestmentBankAccountTypes } from '../shared/_types'

export const useBankAccountsDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['bankAccounts'],
    queryFn: async () => {
      const data = await getBankAccounts(investData?.investment?.id)
      const wireInstructions = await getWireInstructions(
        investData?.investment?.id
      )
      return {
        ...data,
        ...wireInstructions,
      }
    },
    enabled: !!investData.investment && !location.pathname.includes('start'),
    staleTime: Infinity,
  })
}

export const useMutatePlaidToken = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['bankAccounts'],
    mutationFn: async (data: any) => {
      const res = await getPlaidToken(investData?.investment?.id, data)
      return res
    },
    onSuccess: (_data, _variables) => {
      queryClient.invalidateQueries(['bankAccounts'])
    },
  })
}

export const useMutateManualACH = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: any) => {
      const res = await addManualBankAccount(investData?.investment?.id, data)
      return res
    },
    onSuccess: (data, _variables) => {
      console.log(data)
      queryClient.setQueryData(['invest'], (oldData: any) => {
        return {
          ...oldData,
          investment: {
            ...oldData.investment,
            funding_status: data.funding_status,
          },
        }
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(['invest'])
      queryClient.invalidateQueries(['bankAccounts'])
    },
  })
}

export const useMutateUpdateWithBankAccount = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: InvestmentBankAccountTypes) =>
      updateBankAccount(investData?.investment?.id, data),
    onMutate: async (data: any) => {
      const previousInvestmentData = queryClient.getQueryData(['invest'])
      queryClient.setQueryData(['invest'], (oldData: any) => {
        return {
          ...oldData,
          investment: {
            ...oldData.investment,
            funding_status: data.funding_status,
          },
        }
      })
      return { previousInvestmentData }
    },
    onError: (_err, _newData, context) => {
      queryClient.setQueryData(['invest'], context?.previousInvestmentData)
    },
    onSettled: (_data, _variables) => {
      queryClient.invalidateQueries(['invest'])
    },
  })
}
