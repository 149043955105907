import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  usePersonalInfoDataState,
  usePersonalInfoMutation,
} from '../_personalInfoDataState'
import { IndividualAccountTypes } from '../../shared/_types'

import { IndividualForm } from '../../shared/Forms/IndividualForm'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import PropertyInfo from '../../../components/PropertyInfo'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Loader from '../../../components/Loader'

const PersonalInfoScreen = () => {
  const nav = useNavigate()
  // const location = useLocation()
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: personalInfoData } = usePersonalInfoDataState()
  const { mutateAsync: updatePersonalInfoMutation, isLoading } =
    usePersonalInfoMutation()

  // const backLocation = location?.state?.from ? location.state.from : undefined

  const onSubmit = async (data: IndividualAccountTypes) => {
    updatePersonalInfoMutation(data)
      .then(() => {
        nav(`/invest/${investData.opportunity.id}/account`)
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })

    // if (location?.state?.from) {
    //   nav(location.state.from)
    //   return
    // }
  }

  const { t } = useTranslation()

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity.io_type === 'reit' ? (
        <Stepper currentSection={0} currentStep={1} totalStepCount={3} />
      ) : (
        <Stepper
          currentSection={0}
          currentStep={1}
          totalStepCount={4}
          isPledge={
            investData.opportunity.status.toLowerCase() === 'open for pledging'
          }
        />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1
            data-cy="start-header"
            className="mb-6 text-2xl font-bold text-content-black"
          >
            {t('personalInfo:getStarted')}
          </h1>
          {personalInfoData ? (
            <>
              <div className="block xl:hidden">
                <PropertyInfo opportunity={investData?.opportunity} />
              </div>
              <div>
                <h1 className="mb-6 text-2xl font-bold text-content-black">
                  {t('personalInfo:contactInformation')}
                </h1>
                <p className="mb-6 text-16 text-content-black">
                  {t('personalInfo:confirmContactInfo')}
                </p>
                <div>
                  <IndividualForm
                    data={personalInfoData}
                    onSubmit={onSubmit}
                    disabled={isLoading}
                    hasApprovedInvestments={
                      personalInfoData.has_approved_investments
                    }
                  />
                  {serverError && (
                    <ServerError
                      serverError={serverError}
                      id={personalInfoData.uuid}
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <div className="w-full pt-16 flex justify-center items-center">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default PersonalInfoScreen
