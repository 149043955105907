import { ActiveInvestmentMenu } from './edit-menu-active'
import { amountRegexDecimal, moneyMask } from '@/utils/helpers'
import { Badge } from '@/components/ui/badge'
import { format } from 'date-fns/format'
import { InvestmentDataTypes } from '../api/schema'

export const ActiveInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div className="w-full flex gap-8 overflow-hidden h-[116px]">
      <div className="w-[15%]">
        <img className="w-full h-full" src={investment.image ?? ''} />
      </div>
      <div className="w-[85%] flex gap-6">
        <div className="flex w-[30%] flex-col h-full gap-1 justify-center">
          <div className="text-[17px] font-medium">{investment.name}</div>

          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="flex w-[20%] flex-col justify-center">
          {investment.is_reit && (
            <div>
              <Badge
                variant={
                  investment.auto_invest_details?.enabled
                    ? 'auto-invest-on'
                    : 'auto-invest-off'
                }
              >
                <i className="fa-regular fa-refresh mr-2" />
                Auto Invest is{' '}
                {investment.auto_invest_details?.enabled ? 'on' : 'off'}
              </Badge>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center">
          <Badge variant="active">Active</Badge>
        </div>
        <div className="flex w-[20%] text-base gap-2 flex-col justify-center text-right">
          <div className="font-medium text-16">
            <i className="fa-lg fa-regular fa-money-check-dollar-pen" />
            <div className="inline-block ml-4 min-w-[105px]">
              ${moneyMask(investment.total_invested ?? '', amountRegexDecimal)}
            </div>
          </div>
          <div className="text-base text-content-light">
            {investment.start_date ? (
              <span className="whitespace-pre">
                Start date{' '}
                {format(new Date(investment.start_date ?? ''), 'MM/dd/yyyy')}
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </div>
        <div className="flex w-[20%] text-base gap-2 flex-col justify-center text-right">
          <div className="font-medium text-16">
            <i className="fa-lg fa-regular fa-hand-holding-dollar" />
            <div className="inline-block ml-4 min-w-[95px]">
              $
              {moneyMask(
                investment.total_distributions ?? '',
                amountRegexDecimal
              )}
            </div>
          </div>
          <div className="text-base text-content-light whitespace-pre">
            Total Distributions
          </div>
        </div>
        <ActiveInvestmentMenu
          uuid={investment.io_uuid}
          is_reit={investment.is_reit}
        />
      </div>
    </div>
  )
}
