import { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'

import { useAccountDataState } from './_accountDataState'
import { useAmountDataState } from './_amountDataState'
import { useInvestDataState } from './_investDataState'
import { usePersonalInfoDataState } from './_personalInfoDataState'
import { useBankAccountsDataState } from './_bankAccountsDataState'
import { moneyMask, amountRegexDecimal } from '../../utils/helpers'

import PropertyInfo from '../../components/PropertyInfo'

const ACCOUNT_TYPES = {
  foe_trust: 'Trust',
  foe_entity: 'Entity',
  joint_ownership: 'Joint',
  individual: 'Individual',
  foe_sdira: 'Self-Directed IRA',
}

const getAccountName = (account: any) => {
  switch (account?.type) {
    case 'individual':
      return `${account?.primary_member?.first_name} ${account?.primary_member?.last_name}`
    case 'joint_ownership':
      return `${account?.joint_first_name} ${account?.joint_last_name}`
    case 'foe_trust':
      return account?.trust_name
    case 'foe_entity':
      return account?.name
    case 'foe_sdira':
      return account?.account_name
    default:
      return ''
  }
}

const Sidebar = () => {
  // const nav = useNavigate()
  const [getPaymentText, setPaymentText] = useState<ReactNode>(null)
  const location = useLocation()
  const { data: investData } = useInvestDataState()
  const { data: personalInfoData } = usePersonalInfoDataState()
  const { data: amountData } = useAmountDataState()
  const { data: accountData } = useAccountDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { t } = useTranslation()

  useEffect(() => {
    if (investData?.investment?.funding_account_uuid && bankAccountsData) {
      const account = bankAccountsData?.bank_account_list?.find(
        (account: any) =>
          account.account_uuid === investData?.investment?.funding_account_uuid
      )
      setPaymentText(
        <p>{account?.account_description || account?.account_name}</p>
      )
    }
    if (investData?.investment?.funding_status?.includes('wire')) {
      setPaymentText(t('common:wireTransfer'))
    }
  }, [investData, bankAccountsData, t])

  // const editAmount = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   investData.opportunity.io_type === 'reit'
  //     ? nav(`/invest/${investData.opportunity.id}/amount/reit`, {
  //         state: { from: location.pathname },
  //       })
  //     : nav(`/invest/${investData.opportunity.id}/amount/pp`, {
  //         state: { from: location.pathname },
  //       })
  // }

  // const editContactInfo = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   nav(`/invest/${investData.opportunity.id}/start`, {
  //     state: { from: location.pathname },
  //   })
  // }

  // const editAccount = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault()
  //   nav(`/invest/${investData.opportunity.id}/account`, {
  //     state: { from: location.pathname },
  //   })
  // }

  return (
    <div className="flex h-full flex-col w-[400px]">
      <PropertyInfo opportunity={investData?.opportunity} />
      {personalInfoData && !location.pathname.includes('start') && (
        <div className="mb-6">
          <p className="text-base font-normal text-content-black">
            {t('common:contactInformation')}
          </p>
          <div className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light">
            {/* <button
              onClick={editContactInfo}
              className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light"
            > */}
            <div className="px-4 py-3">
              <div>
                {personalInfoData?.first_name} {personalInfoData?.last_name}
              </div>
              <div>{formatPhoneNumber(personalInfoData.phone_number)}</div>
              <div>
                {personalInfoData.address.street}
                {personalInfoData.address.street2 &&
                  `, ${personalInfoData.address.street2}`}
                <br />
                {personalInfoData.address.city},{' '}
                {personalInfoData.address.state}{' '}
                {personalInfoData.address.zip_code}
              </div>
            </div>
            {/* <div className="self-start px-4 py-3 text-primary group-hover:text-white">
                <i className="fa-light fa-pen"></i>
              </div> */}
          </div>
          {/* </button> */}
        </div>
      )}
      {accountData &&
        accountData?.current_account &&
        !location.pathname.includes('start') &&
        !location.pathname.includes('account') && (
          <div className="mb-6">
            <p className="text-base font-normal text-content-black">
              {t('common:ownershipType')}
            </p>
            {/* <button
              onClick={editAccount}
              className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light"
            > */}
            <div className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light">
              <div className="px-4 py-3">
                <span className="capitalize">
                  {
                    ACCOUNT_TYPES[
                      accountData.current_account
                        ?.type as keyof typeof ACCOUNT_TYPES
                    ]
                  }
                </span>
                {' - '}
                {getAccountName(accountData.current_account)}
              </div>
              {/* <div className="self-start px-4 py-3 text-primary group-hover:text-white">
                <i className="fa-light fa-pen"></i>
              </div> */}
            </div>
            {/* </button> */}
          </div>
        )}
      {amountData &&
        amountData?.amount &&
        !location.pathname.includes('start') &&
        !location.pathname.includes('account') && (
          <div className="mb-6">
            <p className="text-base font-normal text-content-black">
              {investData.investment?.status === 'pledge'
                ? t('common:pledgeAmount')
                : t('common:investmentAmount')}
            </p>
            {/* <button
              onClick={editAmount}
              className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-base text-content-light hover:bg-primary-hover"
            > */}
            <div className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light">
              <div className="px-4 py-3">{`$${moneyMask(
                amountData.amount,
                amountRegexDecimal
              )}`}</div>
              {/* <div className="self-start px-4 py-3 text-primary group-hover:text-white">
                <i className="fa-light fa-pen"></i>
              </div> */}
            </div>
            {/* </button> */}
          </div>
        )}
      {getPaymentText &&
        (location.pathname.includes('verification') ||
          location.pathname.includes('review') ||
          location.pathname.includes('complete')) && (
          <div className="mb-6">
            <p className="text-base font-normal text-content-black">
              {t('common:payment')}
            </p>
            {/* <button
              onClick={editAmount}
              className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-base text-content-light hover:bg-primary-hover"
            > */}
            <div className="group relative mt-2 flex w-full justify-between bg-bg-lighter text-left text-base text-content-light">
              <div className="px-4 py-3">{getPaymentText}</div>
              {/* <div className="self-start px-4 py-3  group-hover:text-white">
                <i className="fa-light fa-pen"></i>
              </div> */}
            </div>
            {/* </button> */}
          </div>
        )}
    </div>
  )
}
export default Sidebar
