import { z } from 'zod'

export const transactionFiltersDataSchema = z.object({
  investment_account_filters: z.array(
    z.object({
      name: z.string(),
      uuid: z.string(),
    })
  ),
  io_filters: z.array(
    z.object({
      name: z.string(),
      uuid: z.string(),
    })
  ),
  transaction_types: z
    .array(
      z.enum([
        'purchase',
        'reinvested_distribution',
        'auto_investment',
        'sale',
        'unknown',
        'transfer_in',
        'transfer_out',
        'distribution_cash_pending',
        'distribution_cash_paid',
      ])
    )
    .optional(),
})

export type TransactionFiltersTypes = z.infer<
  typeof transactionFiltersDataSchema
>

export const transactionSchema = z.object({
  uuid: z.string().uuid(),
  transaction_date: z.string().nullish(),
  transaction_type: z.enum([
    'purchase',
    'reinvested_distribution',
    'auto_investment',
    'sale',
    'unknown',
    'transfer_in',
    'transfer_out',
    'distribution_cash_pending',
    'distribution_cash_paid',
  ]),
  io_uuid: z.string().uuid(),
  io_name: z.string().nullish(),
  issuer_name: z.string().nullish(),
  investment_account_type: z.string().nullish(),
  investment_account_name: z.string().nullish(),
  investment_account_uuid: z.string().uuid().nullish(),
  amount: z.string().nullish(),
  shares: z.string().nullish(),
  price_per_share: z.string().nullish(),
})

export type TransactionTypes = z.infer<typeof transactionSchema>

export const transactionsSchema = z.object({
  count: z.number().min(0),
  page: z.number().min(0),
  page_size: z.number().min(0),
  pages: z.number().min(0),
  transactions: z.array(transactionSchema),
})

export type TransactionsTypes = z.infer<typeof transactionsSchema>
