import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'

import { useInvestDataState } from '../_investDataState'
import { useTenThirtyOneDataState } from '../_tenThirtyOneDataState'
import { update1031 } from '../../shared/_api'

import FormFooter from '../../shared/FormFooter'
import InfoModal from '../../../components/InfoModal'
import Radio from '../../../components/Radio'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
interface AccountFormTypes {
  isTenThirtyOne: string
}

const TenThirtyOneScreen = () => {
  const nav = useNavigate()
  const { t } = useTranslation()
  const [serverError, setServerError] = useState('')
  const queryClient = useQueryClient()

  const { data: investData } = useInvestDataState()
  const { data: tenThirtyOneData } = useTenThirtyOneDataState()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountFormTypes>({
    defaultValues: {
      isTenThirtyOne: 'yes',
    },
  })

  // const backLocation = `/invest/${investData?.investment.id}/amount/pp`

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    if (data.isTenThirtyOne === 'yes') {
      nav(`/invest/${investData.opportunity.id}/1031/info`)
      return
    }
    update1031(investData?.investment?.id, {
      investment_uuid: investData?.investment?.id,
      funded_by_1031: false,
    })
      .then(() => {
        queryClient.invalidateQueries(['invest'])
        nav(`/invest/${investData.opportunity.id}/accred`)
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    tenThirtyOneData && (
      <div className="p-6 xl:p-0">
        <Stepper
          currentSection={0}
          currentStep={4}
          totalStepCount={4}
          isPledge
        />{' '}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-6 text-2xl font-bold text-content-black">
              {t('tenThirtyOne:header')}
            </h1>
            <div className="mb-6 text-16 text-content-black">
              {t('tenThirtyOne:existingExchange')}
              <span className="ml-1">
                <InfoModal
                  modalContent={
                    <div className="p-8">
                      <h2 className="mb-6 text-17 font-medium text-content-black">
                        {t('tenThirtyOne:irs1031')}
                      </h2>
                      <p className="font-sans text-16 font-normal text-content-black">
                        <Trans i18nKey="tenThirtyOne:irs1031Modal" />
                      </p>
                    </div>
                  }
                />
              </span>
            </div>
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <p className="mb-2 text-16 text-content-black">
                  {t('common:pleaseSelectOne')}
                </p>
                <Controller
                  name="isTenThirtyOne"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div
                      className={`flex flex-col gap-px ${
                        fieldState.error && 'border border-alert'
                      }`}
                    >
                      <Radio
                        {...field}
                        value="yes"
                        label="Yes"
                        checked={field.value === 'yes'}
                      />
                      <Radio
                        {...field}
                        value="no"
                        label="No"
                        checked={field.value === 'no'}
                      />
                    </div>
                  )}
                />
              </div>
              <FormFooter submitFunc={onSubmit} />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default TenThirtyOneScreen
