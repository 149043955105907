import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { useSearchParams } from 'react-router-dom'
import { taxesDataSchema } from './schema'

export const getTaxDocuments = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/tax-documents', {
    params,
  })
  return taxesDataSchema.parse(response)
}

export const useTaxDocuments = () => {
  const [searchParams, _] = useSearchParams()
  return useQuery({
    queryKey: [
      'tax-documents',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('tax_year'),
      searchParams.get('document_type'),
    ],
    queryFn: () => getTaxDocuments(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!searchParams.get('page'),
  })
}
