import { useState } from 'react'

import { useAuthDataState } from '@/routes/shared/_authDataState'

import { EmptyState } from '../components/empty-state'
import { Loader } from '@/components/Loader'
import { ProfileItem } from './components/profile-item'
import { ProfileSubNav } from './components/profile-subnav'
import { UpdateEmail } from './components/update-email'
import { UpdatePassword } from './components/update-password'
import { VerifiedBadge } from './components/verified-badge'
import { Link } from 'react-router-dom'

const EmailSecurity = () => {
  const [screen, setScreen] = useState('')
  const { data, isLoading, isError } = useAuthDataState()

  return data ? (
    <div className="w-full h-full">
      {screen && (
        <div className="inline-block text-[13px] mb-10">
          <button className="text-primary" onClick={() => setScreen('')}>
            Profile
          </button>
          <i className="fa-light fa-angle-right mx-2" />
          Update {screen}
        </div>
      )}
      <h1 className="text-[31px] font-bold self-start">
        {!screen ? 'Profile' : `Update ${screen}`}
      </h1>
      {!screen && (
        <div>
          <div className="my-10">
            <ProfileSubNav />
          </div>
          <div className="mb-10">
            <div className="border rounded-t -mb-px">
              <ProfileItem
                label="Email Address"
                value={
                  <>
                    <div className="flex items-center gap-3">
                      <div>{data.attributes.email}</div>
                      <VerifiedBadge
                        isVerified={data.attributes.email_verified}
                      />
                    </div>
                    {!data?.attributes?.email_verified && (
                      <div className="mt-2 font-normal">
                        Please{' '}
                        <Link
                          className="underline"
                          to="/dashboard/profile/email/verify"
                        >
                          verify your email now
                        </Link>{' '}
                        - required for investments.
                      </div>
                    )}
                  </>
                }
                callback={setScreen}
              />
            </div>
            <div className="border rounded-t -mb-px">
              <ProfileItem
                label="Password"
                value="*********"
                callback={setScreen}
              />
            </div>
          </div>
          <div className="text-base">
            To close your account, please contact{' '}
            <a
              className="underline"
              href="mailto:investor-help@realtymogul.com"
            >
              RealtyMogul Investor Relations
            </a>
            .
          </div>
        </div>
      )}
      {screen === 'Email Address' && (
        <UpdateEmail email={data.attributes.email} callback={setScreen} />
      )}
      {screen === 'Password' && <UpdatePassword callback={setScreen} />}
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your profile settings."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default EmailSecurity
