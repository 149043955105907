import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { profileDataSchema, ProfileDataTypes } from './schema'

export const getProfile = async () => {
  const response = await api.get('/dashboard/profile/personal-info')
  return profileDataSchema.parse(response)
}

export const useProfile = () => {
  return useQuery<ProfileDataTypes>({
    queryKey: ['profile'],
    queryFn: getProfile,
    staleTime: Infinity,
  })
}
