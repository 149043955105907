export const signature = {
  agreeAndSign: 'Agree and Sign',
  checkBelow: `Read the information below and check the boxes to confirm. After
  you complete your investment, copies of all documents will be
  accessible on your dashboard.`,
  signError: `There was an issue signing documents. Please Review & Sign your documents to continue. If you need help, please email us at`,
  investmentAgreement: `Investment Agreement`,
  docusignError: `Docusign envelope could not be created. Please refresh
  page and try again or contact`,
  reviewAndSignYourAgreement: `Review & Sign Your Agreement`,
  docusignText: `We use our trusted partner, DocuSign, the industry
  standard for secure electronic agreements.`,
  reviewAndSign: `Review & Sign`,
  docusignProblems: `<strong>If you have any problems</strong> viewing or signing the
  document, please email us at`,
}
