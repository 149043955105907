import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getIdentity, updateIdentity } from '../shared/_api'

import { useInvestDataState } from './_investDataState'

export const useIdentityDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['identity'],
    queryFn: async () => {
      const data = await getIdentity(investData?.investment?.id)
      return { ...data }
    },
    staleTime: Infinity,
  })
}

export const useMutateIdentity = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['identity'],
    mutationFn: async (data: any) => {
      const res = await updateIdentity(investData?.investment?.id, data)
      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invest'] })
      queryClient.invalidateQueries({ queryKey: ['identity'] })
    },
  })
}
