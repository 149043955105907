import { useQuery } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'

import { getAgreements } from '../shared/_api'

export const useAgreementsDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['agreements'],
    queryFn: async () => {
      const data = await getAgreements(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData?.investment,
    staleTime: Infinity,
  })
}
