export function mapParams(input: string) {
  //two-way function to map object key <==> param field name for filters
  switch (input) {
    case 'investments':
      return 'io_uuid'
    case 'io_uuid':
      return 'investments'
    case 'investment_accounts':
      return 'investment_account_uuid'
    case 'investment_account_uuid':
      return 'investment_accounts'
    case 'tax_year':
      return 'tax_years'
    case 'tax_years':
      return 'tax_year'
    case 'document_type':
      return 'document_types'
    case 'document_types':
      return 'document_type'
    case 'k1':
      return 'K1'
    case 'K1':
      return 'k1'
    case 'ten_99':
      return '1099-INT'
    case '1099-INT':
      return 'ten_99'
    case 'ten_99_div':
      return '1099-DIV'
    case '1099-DIV':
      return 'ten_99_div'
    case 'ten_99_misc':
      return '1099-MISC'
    case '1099-MISC':
      return 'ten_99_misc'
    case 'foe_trust':
      return 'Trust'
    case 'foe_entity':
      return 'Entity'
    case 'individual':
      return 'Individual'
    case 'foe_sdira':
      return 'Retirement'
    case 'joint_ownership':
      return 'Joint'
    case 'investment_account_filters':
      return 'investment_account_uuid'
    case 'io_filters':
      return 'io_uuid'
    case 'transaction_types':
      return 'transaction_type'
    case 'purchase':
      return 'Purchase'
    case 'Purchase':
      return 'purchase'
    case 'reinvested_distribution':
      return 'Reinvested Distribution'
    case 'Reinvested Distribution':
      return 'reinvested_distribution'
    case 'auto_investment':
      return 'Auto Investment'
    case 'Auto Investment':
      return 'auto_investment'
    case 'sale':
      return 'Sale'
    case 'Sale':
      return 'sale'
    case 'unknown':
      return 'Unknown'
    case 'Unknown':
      return 'unknown'
    case 'transfer_in':
      return 'Transfer In'
    case 'Transfer In':
      return 'transfer_in'
    case 'transfer_out':
      return 'Transfer Out'
    case 'Transfer Out':
      return 'transfer_out'
    case 'distribution_cash_pending':
      return 'Distribution Cash Pending'
    case 'Distribution Cash Pending':
      return 'distribution_cash_pending'
    case 'distribution_cash_paid':
      return 'Distribution Cash Paid'
    case 'Distribution Cash Paid':
      return 'distribution_cash_paid'
    default:
      return input
  }
}
