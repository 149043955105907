import { z } from 'zod'

export const investmentSchema = z.object({
  io_uuid: z.string().uuid(),
  io_drupal_id: z.number().int(),
  status: z.string(),
  name: z.string(),
  investment: z
    .object({
      uuid: z.string().uuid(),
      funding_status: z.string().nullable(),
      funding_account_uuid: z.string().uuid().nullable(),
      distribution_account_uuid: z.string().uuid().nullable(),
      invest_flow_step: z.string().nullable(),
      eav_status: z.string().nullable(),
    })
    .nullable(),
  investment_status: z.string().nullable(),
  location: z.string().nullable(),
  investment_type: z.string().nullable(),
  property_type: z.string().nullable(),
  image: z.string().nullable(),
  is_reit: z.boolean(),
  auto_invest_eligible: z.boolean(),
  drip_eligible: z.boolean().nullable(),
  redemptions_enabled: z.boolean().nullable(),
  distribution_bank_account_enabled: z.boolean().nullable(),
  start_date: z.string().nullable(),
  maturity_date: z.string().nullable(),
  return_calculated: z.string().nullable(),
  total_invested: z.string().nullable(),
  distributions_reinvested: z.string().nullable(),
  total_distributions: z.string().nullable(),
  cash_distributions: z.string().nullable(),
  shares: z.string().nullable(),
  nav: z.string().nullable(),
  investment_value: z.string().nullable(),
  unread_messages: z.number().int().nullable(),
  documents: z
    .array(
      z.object({
        uuid: z.string().uuid(),
        name: z.string(),
        url: z.string().url(),
      })
    )
    .nullable(),
  annualized_distribution_rate: z.string().nullable(),
  distribution_frequency: z.string().nullable(),
  property_class: z.string().nullable(),
  investment_entity: z.string().nullable(),
  distribution_details: z
    .object({
      bank_account_uuid: z.string().uuid(),
      account_name: z.string().nullable(),
      last_four: z.string().nullable(),
      last_amount: z.string().nullable(),
    })
    .nullable(),
  auto_invest_details: z
    .object({
      enabled: z.boolean(),
      amount: z.string().nullable(),
      next_investment_date: z.string().nullable(),
    })
    .nullable(),
  drip_details: z.object({ enabled: z.boolean() }).nullable(),
  subhead: z.string().nullable(),
  url: z.string().nullable(),
})

export type InvestmentDataTypes = z.infer<typeof investmentSchema>

export const investmentsSchema = z.object({
  count: z.number().int(),
  pages: z.number().int(),
  page: z.number().int(),
  page_size: z.number().int().nullable(),
  investments: z.array(investmentSchema),
})

export type InvestmentsDataTypes = z.infer<typeof investmentsSchema>
