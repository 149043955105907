import { forwardRef } from 'react'
import { PatternFormat } from 'react-number-format'
import { InputProps } from '../../routes/shared/_types'

export const TINInput = forwardRef<HTMLInputElement, InputProps>(
  (props, _ref) => {
    const {
      name,
      onBlur,
      onChange,
      placeholder,
      value,
      label,
      helpText,
      disabled = false,
      errorMessage,
    } = props
    return (
      <div className="relative">
        <span
          className={`mb-2 block text-base font-normal ${
            errorMessage ? 'text-alert' : 'text-content-black'
          }`}
        >
          {label}
        </span>
        <PatternFormat
          className={`mb-2 h-[42px] w-full rounded border focus:outline-none focus:ring-1 focus:border-primary ${
            errorMessage ? 'border-alert' : 'border-input'
          } px-3 py-1.5 text-16 disabled:bg-bg-lighter disabled:border-border-normal disabled:cursor-not-allowed`}
          format="##-#######"
          mask="_"
          placeholder={`${disabled ? '**_*******' : placeholder}`}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          disabled={disabled}
          data-cy={`tin-input-${name}`}
        />
        {disabled && (
          <div className="absolute top-1/2 right-[12px]">
            <i className="fa-solid fa-lock" style={{ color: '#D5D9DD' }}></i>
          </div>
        )}
        {errorMessage && (
          <p className="mb-1 text-xs text-alert">
            {typeof errorMessage === 'string'
              ? errorMessage
              : errorMessage.message}
          </p>
        )}
        {helpText && (
          <div className="text-xs text-content-light">{helpText}</div>
        )}
      </div>
    )
  }
)

export default TINInput
