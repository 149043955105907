export const employment = {
  employment: `Employment`,
  provideEmploymentInfo: `Please provide your current employment information.`,
  employmentStatus: `Employment Status`,
  employed: `Employed`,
  selfEmployed: `Self Employed`,
  retired: `Retired`,
  student: `Student`,
  notEmployed: `Not Employed`,
  provideInformationBelow: `Please provide the information below.`,
  finra: `FINRA Affiliation`,
  finraEmployee: `Are you an employee of FINRA or an associated person of a FINRA member broker-dealer?`,
  finraVerify: `We need to verify you are approved to invest.`,
  employerVerify: `Have you obtained written approval from your employer for this investment?`,
  largestContributor: `What is the largest contributor to your net worth? Common sources of wealth include savings, company sale, or inheritance.`,
  explainSource: `Provide your current source of income.`,
}
