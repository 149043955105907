import { cn } from '@/utils/utils'

type EmptyStateTypes = {
  iconClasses?: string
  copy: string
  linkCopy: string
  linkTo: string
}

function EmptyState({
  iconClasses = '',
  copy,
  linkCopy,
  linkTo,
}: EmptyStateTypes) {
  return (
    <div className="w-max-[200px] flex flex-col items-center gap-8">
      <i className={cn('', iconClasses)} />
      <div className="text-content-black font-medium">{copy}</div>
      <a
        className="flex h-9 px-4 py-[9px] justify-center font-medium text-base text-white items-center gap-2 flex-shrink-0 rounded bg-primary hover:bg-primary-hover"
        href={linkTo}
        target="_blank"
      >
        {linkCopy}
      </a>
    </div>
  )
}

export { EmptyState }
