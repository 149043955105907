import { Controller, useForm } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { useTranslation } from 'react-i18next'
import 'react-phone-number-input/style.css'

import { IndividualAccountTypes } from '../_types'
import CountryInput from '../../../components/CountryInput'
import ErrorAlert from '../../../components/ErrorAlert'
import FormFooter from '../FormFooter'
import StateDropdown from '../../../components/StateDropdown'
import TextInput from '../../../components/TextInput'
import NumberInput from '../../../components/NumberInput'
interface IndividualFormPropTypes {
  data: IndividualAccountTypes
  onSubmit: (data: IndividualAccountTypes) => void
  disabled?: boolean
  backLocation?: string
  hasApprovedInvestments?: boolean
}

export const IndividualForm = (props: IndividualFormPropTypes) => {
  const { data, backLocation, disabled, onSubmit, hasApprovedInvestments } =
    props
  const { t } = useTranslation()

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IndividualAccountTypes>({
    defaultValues: {
      first_name: data?.first_name || '',
      last_name: data?.last_name || '',
      phone_number: data?.phone_number || '',
      address: {
        country: data?.address?.country || 'US',
        street: data?.address?.street || '',
        street2: data?.address?.street2 || '',
        city: data?.address?.city || '',
        state:
          data?.address?.country === 'US'
            ? data?.address?.state || 'AL'
            : data?.address?.state || '',
        zip_code: data?.address?.zip_code || '',
      },
    },
  })

  const watchCountry = watch('address.country')

  const onFormSubmit = handleSubmit((data: IndividualAccountTypes) => {
    console.log(errors, data)
    onSubmit(data)
  })

  return (
    <form onSubmit={onFormSubmit} autoComplete="on" className="w-full mb-14">
      {Object.keys(errors).length > 0 && (
        <ErrorAlert text="Please fix the error(s) below:" />
      )}
      <div className="mb-4 flex gap-3">
        <Controller
          name="first_name"
          control={control}
          rules={{ required: 'is required' }}
          disabled={hasApprovedInvestments}
          render={({ field, fieldState }) => (
            <TextInput
              label={t('common:firstName')}
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          rules={{ required: 'is required' }}
          disabled={hasApprovedInvestments}
          render={({ field, fieldState }) => (
            <TextInput
              label={t('common:lastName')}
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <span
          className={`mb-2 block text-base font-normal ${
            errors?.phone_number?.message ? 'text-alert' : 'text-content-black'
          }`}
        >
          {t('common:phoneNumber')}
        </span>
        <PhoneInput
          name="phone_number"
          className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
            errors?.phone_number ? 'border-alert' : 'border-input'
          } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
          defaultCountry="US"
          countryOptionsOrder={['US']}
          control={control}
          initialValueFormat="national"
          limitMaxLength={true}
          rules={{
            required: 'is required',
            validate: {
              isValidPhoneNumber: (value: string) =>
                isValidPhoneNumber(value) ||
                'Please enter a valid phone number.',
            },
          }}
        />
        {errors?.phone_number?.message && (
          <p className="mb-1 text-xs text-alert">
            {errors?.phone_number?.message}
          </p>
        )}
      </div>
      <div className="mb-4">
        <CountryInput
          register={register('address.country')}
          errorMessage={errors?.address?.country?.message}
          label={t('common:country')}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="address.street"
          control={control}
          rules={{ required: 'is required' }}
          render={({ field, fieldState }) => (
            <TextInput
              label={t('common:address')}
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="address.street2"
          control={control}
          render={({ field, fieldState }) => (
            <TextInput
              placeholder={t('common:address2')}
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-4">
        <Controller
          name="address.city"
          control={control}
          rules={{ required: 'is required' }}
          render={({ field, fieldState }) => (
            <TextInput
              label={t('common:city')}
              errorMessage={fieldState.error}
              {...field}
            />
          )}
        />
      </div>
      <div className="mb-6 flex wrap-row gap-3">
        <div className="w-1/2">
          <Controller
            name="address.state"
            control={control}
            rules={{ required: watchCountry === 'US' ? 'is required' : false }}
            render={({ field, fieldState }) =>
              watchCountry === 'US' ? (
                <StateDropdown
                  errorMessage={fieldState?.error?.message}
                  label={t('common:state')}
                  {...field}
                />
              ) : (
                <TextInput
                  label="State/Province/Region"
                  errorMessage={fieldState?.error?.message}
                  {...field}
                />
              )
            }
          />
        </div>
        <div className="w-1/2">
          <Controller
            name="address.zip_code"
            control={control}
            rules={{ required: 'is required' }}
            render={({ field, fieldState }) => (
              <NumberInput
                label={
                  watchCountry === 'US'
                    ? t('zipCode', { 'ns': 'common' })
                    : 'Postal Code'
                }
                errorMessage={fieldState.error}
                {...field}
              />
            )}
          />
        </div>
      </div>
      <FormFooter
        backLocation={backLocation}
        submitFunc={onFormSubmit}
        disabled={disabled}
      />
    </form>
  )
}
