import { Auth } from 'aws-amplify'

export function changePassword(
  oldPassword: string,
  newPassword: string
): Promise<string | void> {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.changePassword(user, oldPassword, newPassword)
  })
}
