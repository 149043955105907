import { useTranslation } from 'react-i18next'

const FullyPledged = () => {
  const { t } = useTranslation()

  return (
    <div
      data-cy="accred-failed-screen"
      className="border-t-4 border-alert-light p-6"
    >
      <p className="mb-6 text-16 font-medium text-content-black">
        {t('failed:fundedHeader')}
      </p>
      <p className="mb-12 text-base text-content-black">
        {t('failed:fullyPledged')}
      </p>
      <a
        className="flex h-10 w-full md:w-fit px-4 items-center justify-center rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2] disabled:opacity-50"
        data-cy="continue-button"
        href="https://www.realtymogul.com/investment-opportunities"
      >
        {t('failed:seeOtherOpps')}
      </a>
    </div>
  )
}

export default FullyPledged
