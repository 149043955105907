import { getCountries } from 'react-phone-number-input'
import en from 'react-phone-number-input/locale/en'
interface CountryInputTypes {
  label?: string
  errorMessage?: string
  register: any
}

const ALL_COUNTRIES = getCountries()
  .map((country) => {
    return { value: String(country), label: en[country] }
  })
  .sort((a, b) => {
    if (a.value === 'US') return -1
    if (b.value === 'US') return 1
    return a.label.localeCompare(b.label)
  })

const CountryInput = ({
  label = 'Country',
  errorMessage = '',
  register,
}: CountryInputTypes) => (
  <>
    <span
      className={`mb-2 block text-base font-normal ${
        errorMessage ? 'text-alert' : 'text-content-black'
      }`}
    >
      {label}
    </span>
    <select
      className={`w-full appearance-none border rounded ${
        errorMessage ? 'border-alert' : 'border-input'
      } bg-white bg-down-chevron bg-[center_right_12px] bg-no-repeat px-3 py-[10px] text-[15px] text-content-black focus:outline-none focus:ring-1 focus:border-primary`}
      {...register}
    >
      {ALL_COUNTRIES.map((item) => (
        <option className="text-[12px]" key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  </>
)

export default CountryInput
