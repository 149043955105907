import { Controller, Control } from 'react-hook-form'

import ErrorAlert from '../../../components/ErrorAlert'
import Checkbox from '../../../components/Checkbox'

interface AccredFormProps {
  control: Control<any>
  errors: Record<string, any>
  onSubmit: () => void
  isNoneChecked: boolean
  accredData: any
}

const AccredForm = ({
  control,
  errors,
  onSubmit,
  isNoneChecked = false,
  accredData,
}: AccredFormProps) => (
  <form onSubmit={onSubmit} className="xl:">
    <input type="hidden" name="hiddenInput" />
    {Object.keys(errors)?.length > 0 && (
      <ErrorAlert text="Please select at least one:" />
    )}
    {accredData?.map(
      (section: { header: string; questions: any }, index: number) => (
        <div key={index} className="flex flex-col gap-1">
          <div>{section.header}</div>
          {section.questions.map((question: any) => (
            <div key={question.uuid}>
              <Controller
                name={question.uuid}
                control={control}
                defaultValue={false}
                render={({ field, fieldState }) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                    errorMessage={fieldState.error}
                    disabled={
                      isNoneChecked &&
                      question.question_type.toLowerCase() !== 'none'
                        ? true
                        : false
                    }
                    label={
                      <div>
                        <b>{question.question_title}</b>
                        {question.question_type.toLowerCase() === 'none' ? (
                          ' '
                        ) : (
                          <br />
                        )}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: question.question,
                          }}
                          className="[&>ul]:list-disc [&>ul]:ml-6 [&>ul]:flex [&>ul]:flex-col [&>ul]:gap-1"
                        />
                      </div>
                    }
                  />
                )}
              />
            </div>
          ))}
          {index < accredData.length - 1 && (
            <div className="my-3 w-full text-center font-medium">- or -</div>
          )}
        </div>
      )
    )}
  </form>
)

export default AccredForm
