import { useLocation, Link } from 'react-router-dom'
import { cn } from '@/utils/utils'

const SubItem = ({
  icon,
  text,
  location,
  isActive = false,
}: {
  icon?: string
  text: string
  location: string
  isActive?: boolean
}) => (
  <Link
    to={location}
    className={cn(
      'px-4 py-1 items-center gap-2 flex cursor-pointer hover:bg-white',
      isActive && 'bg-white cursor-auto'
    )}
  >
    {icon && (
      <i
        className={cn(
          'fa-regular fa-sm',
          `fa-${icon}`,
          isActive && 'fa-solid text-primary'
        )}
      />
    )}
    <div
      className={cn(
        'text-content-black font-normal',
        isActive && 'font-medium'
      )}
    >
      {text}
    </div>
  </Link>
)

export const InvestmentsSubNav = () => {
  const location = useLocation()

  return (
    <div className="inline-flex text-16 text-content-black">
      <div className="bg-bg-light inline-flex border border-bg-light rounded">
        <SubItem
          text="Active"
          location="/dashboard/investments/active"
          isActive={location.pathname === '/dashboard/investments/active'}
        />
        <SubItem
          text="Completed"
          location="/dashboard/investments/completed"
          isActive={location.pathname === '/dashboard/investments/completed'}
        />
      </div>
      <div className="w-7" />
      <div className="bg-bg-light inline-flex border border-bg-light rounded">
        <SubItem
          text="Pending"
          location="/dashboard/investments/pending"
          isActive={location.pathname === '/dashboard/investments/pending'}
        />
        <SubItem
          text="Waitlist"
          location="/dashboard/investments/waitlist"
          isActive={location.pathname === '/dashboard/investments/waitlist'}
        />
        <SubItem
          text="Pledged"
          location="/dashboard/investments/pledged"
          isActive={location.pathname === '/dashboard/investments/pledged'}
        />
      </div>
    </div>
  )
}
