import { Link } from 'react-router-dom'
import { z } from 'zod'

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cancelInvestment, getDrupalUrl } from '@/routes/shared/_api'
import { Button } from '@/components/ui/button'
import { useQueryClient } from '@tanstack/react-query'

const investmentMenuSchema = z.object({
  id: z.string(),
  investment_uuid: z.string().uuid(),
})

type MenuTypes = z.infer<typeof investmentMenuSchema>

const MenuButton = ({
  text,
  linkLocation = '/dashboard/profile',
}: {
  text: string
  linkLocation?: string
}) => {
  return (
    <Link
      to={linkLocation}
      className="w-full text-left px-4 py-3 hover:bg-bg-light"
    >
      {text}
    </Link>
  )
}

export const PendingInvestmentMenu = ({ id, investment_uuid }: MenuTypes) => {
  const queryClient = useQueryClient()
  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-6 py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent
        side="left"
        className="w-[187px] text-base px-0 py-2 rounded flex flex-col"
      >
        <MenuButton
          text="View Investment"
          linkLocation={`${getDrupalUrl()}investment-opportunity/${id}`}
        />
        <Dialog>
          <DialogTrigger>
            <div className="w-full text-left px-4 py-3 hover:bg-bg-light">
              Cancel Investment
            </div>
          </DialogTrigger>
          <DialogContent>
            <DialogTitle className="text-content-black text-left font-medium text-[22px]">
              Cancel this investment?
            </DialogTitle>
            <DialogDescription>
              <div className="text-[#202020] text-16">
                The investment will be removed from your list of pending
                investments.
              </div>
            </DialogDescription>
            <div className="mt-2 flex gap-4">
              <Button
                variant="RM"
                size="RM"
                onClick={() => {
                  cancelInvestment(investment_uuid).then(() => {
                    queryClient.invalidateQueries(['investments'])
                  })
                }}
              >
                Yes - cancel it.
              </Button>
              <DialogClose asChild>
                <Button variant="RMSecondary" size="RMSecondary">
                  No - keep it.
                </Button>
              </DialogClose>
            </div>
          </DialogContent>
        </Dialog>
      </PopoverContent>
    </Popover>
  )
}
