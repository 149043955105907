import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import { AccountDataTypes } from './api/schema'
import { formatAddress } from '../shared/format-address'
import { mapParams } from '../shared/map-params'
import { useOwnershipTypes } from './api/get-ownership-types'
import { getIcon } from '../shared/get-icon'

import { EmptyState } from '../components/empty-state'
import Loader from '@/components/Loader'
import { OwnershipTypeMenu } from './components/edit-menu'
import { Pagination } from './components/pagination'

const getPhoneNumber = (phoneNumber: string) => {
  if (isValidPhoneNumber(phoneNumber)) {
    return formatPhoneNumber(phoneNumber)
  }
  return phoneNumber
}

const getAddress = (account: AccountDataTypes) => {
  if (account.address) {
    return formatAddress(account.address)
  }
  if (account.type === 'individual' || account.use_primary_investor_contact) {
    return account.primary_member.address
      ? formatAddress(account.primary_member.address)
      : ''
  }
}

export const OwnershipTypes = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })
  const { data, isLoading, isError } = useOwnershipTypes()

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', (pagination.pageIndex + 1).toString())
    setSearchParams(newSearchParams)
  }, [pagination, searchParams, setSearchParams])

  return data ? (
    <div className="w-full h-full mb-10">
      <div className="w-full flex flex-col">
        <div className="w-full mb-10 flex justify-between items-center">
          <h1 className="text-[31px] font-bold self-start">Ownership Types</h1>
        </div>
        <div className="inline-block align-middle mb-6">
          <span className="mr-2">Manage ownership types for investing.</span>
          {/* <Button variant="RM" size="RM">
            Add New
          </Button> */}
        </div>
        <div className="w-full flex flex-col gap-4 mb-4">
          {data.accounts.length > 0 &&
            data.accounts.map((account) => (
              <div
                key={account.uuid}
                className="w-full rounded border border-[#E7E7E7] flex justify-between items-center text-base"
              >
                <div className="p-4 inline-flex gap-3 w-[25%]">
                  <div className="w-5 h-4 flex justify-center">
                    {getIcon(account.type)}
                  </div>
                  <div className="font-medium">
                    {account.investment_account_name}
                  </div>
                </div>
                <div className="p-4 w-[10%]">{mapParams(account.type)}</div>
                <div className="p-4 w-[15%]">
                  {(account?.total_investment_count ?? 0) > 0 ? (
                    `${account.total_investment_count} investment${
                      (account.total_investment_count || 0) > 1 ? 's' : ''
                    }`
                  ) : (
                    <span className="text-base text-content-light">
                      No investments
                    </span>
                  )}
                </div>
                <div className="p-4 w-[30%]">{getAddress(account)}</div>
                <div className="p-4 w-[15%]">
                  {getPhoneNumber(account.account_phone_number ?? '')}
                </div>
                <OwnershipTypeMenu uuid={account.uuid} />
              </div>
            ))}
        </div>
        <Pagination
          pagination={pagination}
          totalPages={data.pages}
          totalCount={data.count}
          changePage={(newPage) =>
            setPagination({ ...pagination, pageIndex: newPage })
          }
        />
      </div>
    </div>
  ) : (
    <div className="h-[75vh] flex justify-center items-center">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your ownership types."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default OwnershipTypes
