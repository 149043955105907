import { useQuery } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'

import { getAccounts, getCustodians } from '../shared/_api'
import { useLocation } from 'react-router-dom'

export const useAccountDataState = () => {
  const location = useLocation()
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['account'],
    queryFn: async () => {
      const data = await getAccounts(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !location.pathname.includes('start') || !!investData.investment,
    staleTime: Infinity,
  })
}

export const useCustodiansDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['custodians'],
    queryFn: async () => {
      const data = await getCustodians(investData?.investment?.id)
      return [...data.custodians]
    },
    staleTime: Infinity,
  })
}
