import { useCommPrefs } from './api/get-comm-prefs'

import { EmptyState } from '../components/empty-state'
import { Loader } from '@/components/Loader'
import { ProfileSubNav } from './components/profile-subnav'

const ProfileCommPrefs = () => {
  const { data, isLoading, isError } = useCommPrefs()

  return data ? (
    <div className="w-full h-full">
      <h1 className="text-[31px] font-bold self-start">Profile</h1>
      <div className="my-10">
        <ProfileSubNav />
      </div>
      <div className="text-16 mb-10">
        Manage the messages you would like to receive from us.
      </div>
      <div className="w-full px-6 py-4 mb-10 h-[78px] flex justify-between items-center border rounded">
        <div className="flex-col text-base">
          <div className="text-base mb-2">Text & Email</div>
          <div className="font-medium">
            Make changes to{' '}
            <a
              href="https://cloud.e.realtymogul.com/preferences?qs=2b2d2f953430cfb46f96bd63ed688a74156faccbd423b9a72507257986662bc0f6c90c278f473273cc4fab17052fe2016b2bda9549475eae19fb804d5cf8912521240fd250355f0ff85986adfbcc7ff6&sfmc_id=24282819"
              target="_blank"
              className="underline"
            >
              your email & text preferences
            </a>
            <sup>*</sup>
          </div>
        </div>
      </div>
      <div className="text-base text-content-light mb-10">
        * By enabling this option, you are consenting to participate in
        RealtyMogul's SMS Communications Program. By participating, you consent
        to receive text messages sent by an automatic telephone dialing system
        and acknowledge that data usage charges may apply by your cell phone
        company.
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your profile settings."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default ProfileCommPrefs
