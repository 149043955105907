import { ReactNode } from 'react'
import { cn } from '@/utils/utils'
import { Link } from 'react-router-dom'
import { buttonVariants } from '@/components/ui/button'

import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { getIcon } from '../shared/get-icon'

type Link = {
  title: string
  label?: string
  icon: ReactNode
  location: string
  active: boolean
}
interface NavProps {
  isCollapsed: boolean
  unreadCount?: number
  links: Link[]
  links2: Link[]
  toggleCollapse: () => void
}

const CollapsedLink = ({ link }: { link: Link }) => (
  <Tooltip delayDuration={0}>
    <TooltipTrigger asChild>
      <Link
        to={link.location}
        className={cn(
          buttonVariants({ size: 'icon' }),
          'h-9 w-9',
          'bg-transparent hover:bg-slate-200',
          link.active && 'bg-slate-200 dark:hover:text-white'
        )}
      >
        {link.icon}
        <span className="sr-only">{link.title}</span>
      </Link>
    </TooltipTrigger>
    <TooltipContent side="right" className="flex items-center gap-4">
      {link.title}
    </TooltipContent>
  </Tooltip>
)

const RegularLink = ({
  link,
  unreadCount = 0,
}: {
  link: Link
  unreadCount?: number
}) => (
  <Link
    to={link.location}
    className={cn(
      'w-full h-6 box-content justify-start bg-white flex items-center font-normal px-4 py-3 mr-4 rounded',
      link.active && 'bg-[#F9FAFB]'
    )}
  >
    <span
      className={cn(
        'w-6 h-6 mr-3 flex justify-center items-center',
        link.active && '[&>svg]:fill-primary'
      )}
    >
      {link.icon}
    </span>
    <span className="mt-[3px]">{link.title}</span>
    {unreadCount > 0 && link.title.toLowerCase() === 'messages' && (
      <span className="px-2  ml-2 leading-6 text-[11px] font-medium rounded-[43px] bg-primary whitespace-pre text-white">
        {unreadCount} new
      </span>
    )}
  </Link>
)

export function Nav({
  links,
  links2,
  isCollapsed,
  toggleCollapse,
  unreadCount = 0,
}: NavProps) {
  return (
    <div
      data-collapsed={isCollapsed}
      className={cn(
        'group flex flex-col gap-4 py-2 w-full h-full data-[collapsed=true]:py-2 relative'
      )}
    >
      <nav
        className={cn(
          'grid rounded group-[[data-collapsed=true]]:justify-start group-[[data-collapsed=true]]:px-2',
          isCollapsed && 'gap-2'
        )}
      >
        {!isCollapsed && (
          <div className="text-base text-[#8C8C8C] px-4 mb-2">Dashboard</div>
        )}
        {links.map((link, index) =>
          isCollapsed ? (
            <div key={index}>
              <CollapsedLink link={link} />
            </div>
          ) : (
            <div key={index}>
              <RegularLink link={link} unreadCount={unreadCount} />
            </div>
          )
        )}
        {!isCollapsed && (
          <div className="text-base text-[#8C8C8C] mt-4 px-4 mb-2">
            Settings
          </div>
        )}
        {links2.map((link, index) =>
          isCollapsed ? (
            <div key={index}>
              <CollapsedLink link={link} />
            </div>
          ) : (
            <div key={index}>
              <RegularLink link={link} />
            </div>
          )
        )}
      </nav>
      <button
        className={cn(
          'ml-5 fill-[#8C8C8C]',
          isCollapsed && 'ml-4 [&>svg]:rotate-180'
        )}
        onClick={() => toggleCollapse()}
      >
        {getIcon('back-line')}
      </button>
    </div>
  )
}

export default Nav
