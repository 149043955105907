import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'
import { getAmount, setAmount } from '../shared/_api'

import { AmountTypes } from '../shared/_types'

export const useAmountDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['amount'],
    queryFn: async () => {
      const data = await getAmount(investData.investment?.id)
      return {
        ...data,
      }
    },
    enabled:
      !!investData.investment?.id &&
      !location.pathname.includes('start') &&
      !location.pathname.includes('account'),
    staleTime: Infinity,
  })
}

export const useAmountMutation = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) =>
      setAmount(investData?.investment.id, {
        ...data,
      }),
    onMutate: async (newAmount: AmountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['amount'],
      })
      const previousAmount = queryClient.getQueryData<AmountTypes>([
        'amount',
        investData.opportunity.id,
      ])
      if (previousAmount) {
        queryClient.setQueryData<AmountTypes>(['amount'], {
          ...previousAmount,
          ...newAmount,
        })
      }
      return { previousAmount }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['amount'],
      })
    },
    onError: (_err, _variables, context) => {
      if (context?.previousAmount) {
        queryClient.setQueryData<AmountTypes>(
          ['amount'],
          context.previousAmount
        )
      }
    },
  })
}
