import { PendingInvestmentMenu } from './edit-menu-pending'
import { amountRegexDecimal, moneyMask } from '@/utils/helpers'
import { Badge } from '@/components/ui/badge'
import { InvestmentDataTypes } from '../api/schema'
import { Button } from '@/components/ui/button'
import { Link } from 'react-router-dom'

export const PendingInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div className="w-full flex gap-8 overflow-hidden h-[116px]">
      <div className="w-[15%]">
        <img className="w-full h-full" src={investment.image ?? ''} />
      </div>
      <div className="w-[85%] flex gap-6">
        <div className="flex w-[30%] flex-col h-full gap-1 justify-center">
          <div className="text-[17px] font-medium">{investment.name}</div>

          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="flex w-[20%] text-16 font-medium gap-2 flex-col justify-center text-right">
          ${moneyMask(investment.investment_value ?? '', amountRegexDecimal)}
        </div>
        <div className="flex p-4 flex-col min-w-[170px] justify-center items-center">
          {!investment.investment?.funding_status && (
            <Badge variant="incomplete">Incomplete</Badge>
          )}
          {investment.investment?.funding_status && (
            <Badge variant="active">Pending</Badge>
          )}
        </div>
        <div className="flex flex-grow text-base gap-2 flex-col justify-center text-right">
          {!investment.investment?.funding_status && (
            <Link to={`/invest/${investment.io_uuid}`}>
              <Button className="w-full" variant="RM" size="RM">
                Continue <i className="ml-2 fa-regular fa-angle-right" />
              </Button>
            </Link>
          )}
        </div>
        <PendingInvestmentMenu
          id={investment.io_drupal_id.toString()}
          investment_uuid={investment.investment?.uuid ?? ''}
        />
      </div>
    </div>
  )
}
