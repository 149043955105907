import { z } from 'zod'

export const addressSchema = z.object({
  thoroughfare: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  premise: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  locality: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  country: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  adminarea: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  postcode: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

export type AddressTypes = z.infer<typeof addressSchema>
