export const review = {
  header: `Review & Submit`,
  copy1: `Below is a summary of your investment. Please verify your payment account below and review before funding your investment. Your bank account used for this investment must match your ownership type selected earlier (ie. individual, trust, LLC, etc.)`,
  fundingSource: `Funding Source`,
  authorizations: `Authorizations`,
  vericheck: `The Issuer of this investment uses VeriCheck to process electronic payments. By placing your investment below, you authorize the Issuer of this investment opportunity to debit the bank account selected above for your purchase amount. In addition, you authorize any future distributions (as applicable) to be credited back into the bank account selected above.`,
  authorizeWire: `The Issuer of this investment uses VeriCheck to process electronic payments. I have elected to wire my investment and receive distributions to the account selected above. By placing my Investment Request below, I authorize the Issuer of this investment opportunity to credit any future distributions (as applicable) to the bank account selected above. Additionally, I authorize debits from the selected account for any deposits made in error. This authorization will remain in effect until I give written notice to cancel it.
  <br /><br />
  ACH Agreement: In the case of an ACH Transaction being rejected for Non Sufficient Funds (NSF) I understand that the Issuer of this investment opportunity may at its discretion attempt to process the charge again within 30 days. <i>I agree to be bound by NACHA Operating Rules as they pertain to this transaction</i>.  I acknowledge that the origination of ACH transactions from and to My Account must comply with the provisions of U.S. law. I certify that I am an authorized signatory of this bank account and will not dispute this transaction with my bank; so long as the transaction corresponds to the terms indicated in this transaction.`,
  authorizeAch: `I authorize the above purchase amount to be automatically debited from the bank account listed above (“My Account”) and credited directly to the relevant account established by the Issuer of this investment. Additionally, I authorize all distributions paid by the Issuer of this investment to be deposited directly into My Account. I understand that if I request to cancel my subscription to this offering after funds are debited from My Account, a refund may not be processed for up to 90 days after funds have been received in the Issuer's relevant account. Additionally, I authorize debits from My Account for any deposits made in error. This authorization will remain in effect until I give written notice to cancel it.
  <br /><br />
  ACH Agreement: In the case of an ACH Transaction being rejected for Non Sufficient Funds (NSF) I understand that the Issuer of this investment opportunity may at its discretion attempt to process the charge again within 30 days. <i>I agree to be bound by NACHA Operating Rules as they pertain to this transaction.</i> I acknowledge that the origination of ACH transactions from and to My Account must comply with the provisions of U.S. law. I certify that I am an authorized signatory of this bank account and will not dispute this transaction with my bank; so long as the transaction corresponds to the terms indicated in this transaction.`,
  submitInvestmentRequest: `Place Investment`,
}
