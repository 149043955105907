import { useQuery } from '@tanstack/react-query'

import { api } from '@/routes/shared/_api'
import { transactionFiltersDataSchema, TransactionFiltersTypes } from './schema'

export const getTransactionFilters = async () => {
  const response = await api.get('/dashboard/transactions/filters')
  return transactionFiltersDataSchema.parse(response)
}

export const useTransactionFilters = () => {
  return useQuery<TransactionFiltersTypes>({
    queryKey: ['transaction-filters'],
    queryFn: getTransactionFilters,
    staleTime: Infinity,
  })
}
