import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    analytics: any
  }
}

export const usePageViews = (category: string) => {
  const location = useLocation()
  useEffect(() => {
    window.analytics.page(category)
  }, [category, location])
}

export const identify = (id: string, traits: any) => {
  if (window.analytics) {
    window.analytics.identify(id, traits)
  }
}

export const track = (event: string, properties?: any) => {
  if (window.analytics) {
    window.analytics.track(event, properties)
  }
}
