import { QueryClient } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'

import { getTokens } from '../routes/shared/_api'

type Token = {
  id: string
  tokenValue: string
}

const getCurrentUserEmail = async (): Promise<string> => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser()
    return currentUser.attributes.email
  } catch {
    // Not logged in.
    return Promise.resolve('')
  }
}

const parseFeatureFlag = async (_key: string, value: string) => {
  const email = await getCurrentUserEmail()

  // If the value is equal to QA, make sure the email address has realtymogulqa
  // in it or ends in @realtymogul.com
  if (value === 'QA') {
    if (email.includes('realtymogulqa') || email.endsWith('@realtymogul.com')) {
      return true
    }
  }

  // If the value is set to true, return true.
  if (value === 'true') {
    return true
  }

  // If the value is set to false, return false.
  if (value === 'false') {
    return false
  }
  return false
}

const getFeatureFlags = async () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
      },
    },
  })
  try {
    const data = await queryClient.fetchQuery({
      queryKey: ['tokens'],
      queryFn: async () => {
        const data = await getTokens()

        if (data) {
          const sortedData = await data
            .filter((token: Token) => token.id.startsWith('ff_'))
            .sort((a: Token, b: Token) => a.id.localeCompare(b.id))
            .reduce(async (acc: any, item: any) => {
              acc[item.id] = await parseFeatureFlag(item.id, item.tokenValue)
              return acc
            }, {})

          return sortedData
        }
        return {}
      },
    })
    return data
  } catch (error) {
    console.error('Error fetching feature flags:', error)
    return {}
  }
}

export { getFeatureFlags }
