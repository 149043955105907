import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import {
  useMutateManualACH,
  useMutateUpdateWithBankAccount,
} from '../_bankAccountsDataState'
import {
  BankAccountTypes,
  InvestmentBankAccountTypes,
} from '../../shared/_types'
import { getSignedS3Url, uploadToSignedS3 } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'

import NumberInput from '../../../components/NumberInput'
import TextInput from '../../../components/TextInput'
import FileUpload from '../../../components/FileUpload'
import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Checkbox from '../../../components/Checkbox'

const ACHManual = () => {
  const [serverError, setServerError] = useState('')
  const [showUploadForm, setShowUploadForm] = useState<boolean>(true)
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const bankAccountMutation = useMutateUpdateWithBankAccount()

  const isWire = investData?.investment?.funding_status === 'wire_pending'
  const mutationManualACH = useMutateManualACH()
  const { t } = useTranslation()

  const { handleSubmit, control, setValue, watch } = useForm<BankAccountTypes>({
    defaultValues: {
      account_type: 'checking',
      account_number: '',
      routing_number: '',
      account_name: 'New Account Label',
      verification_file_uuid: '',
    },
  })

  const watchFile = watch('verification_file_uuid')

  const onSubmit = handleSubmit(async (data: BankAccountTypes) => {
    console.log(data)
    const newAccount = {
      account_type: data.account_type,
      account_number: data.account_number,
      routing_number: data.routing_number,
      account_description: `${
        data.account_name
      } - XXXXXXXX${data.account_number?.slice(-4)}`,
      account_name: data.account_name,
      verification_file_uuid: showUploadForm
        ? data.verification_file_uuid
        : null,
    }
    mutationManualACH.mutateAsync(newAccount).then((resp) => {
      const newData: InvestmentBankAccountTypes = isWire
        ? {
            distribution_bank_account_uuid: resp.account_uuid,
            funding_status: 'wire_pending',
          }
        : {
            funding_bank_account_uuid: resp.account_uuid,
            distribution_bank_account_uuid: resp.account_uuid,
            funding_status: 'ach_authorized',
          }
      bankAccountMutation
        .mutateAsync(newData)
        .then(() =>
          findRoute({
            ...investData,
            investment_state: {
              ...investData.investment_state,
              funding: 'submitted',
              review: 'required',
            },
          }).then((route) => {
            nav(`/invest/${investData.opportunity.id}/${route.type}`)
          })
        )
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
    })
  })

  return (
    <div className="p-6 xl:p-0">
      {investData.opportunity?.io_type === 'reit' ? (
        <Stepper currentSection={2} currentStep={1} totalStepCount={3} />
      ) : (
        <Stepper currentSection={2} currentStep={1} totalStepCount={5} />
      )}
      <div className="flex gap-[72px]">
        <div className="w-full">
          <div className="pb-12">
            <h1 className="mb-3 text-2xl font-bold text-content-black">
              <Trans i18nKey="funding:fundYourInvestment" />
            </h1>
            <p className="mb-6 italic">
              <Trans i18nKey="funding:manualBankAccount" />
            </p>
            <p className="mb-6 text-16 text-content-black">
              <Trans i18nKey="funding:manualBankAccountCopy" />
            </p>
            <form onSubmit={onSubmit} className="xl:">
              <div className="mb-5">
                <p className="mb-2 text-xl font-bold text-content-black">
                  Account Info
                </p>
              </div>
              <p className="mb-2 text-sm">Account Type</p>
              <Controller
                name="account_type"
                control={control}
                render={({ field }) => (
                  <div className="mb-6 flex flex-col gap-px first:bg-red-50">
                    <Radio
                      {...field}
                      value="checking"
                      label="Checking"
                      checked={field.value === 'checking'}
                    />
                    <Radio
                      {...field}
                      value="savings"
                      label="Savings"
                      checked={field.value === 'savings'}
                    />
                  </div>
                )}
              />
              <div className="mb-6">
                <Controller
                  name="account_number"
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      label="Account Number"
                      errorMessage={fieldState.error}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <Controller
                  name="routing_number"
                  control={control}
                  rules={{
                    required: 'is required',
                    pattern: {
                      value: /^[0-9]{9}$/,
                      message: 'Must be exactly 9 digits',
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <NumberInput
                      label="Routing Number"
                      errorMessage={fieldState.error}
                      maxLength={9}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <Controller
                  name="account_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextInput
                      label="Add a label for this account"
                      errorMessage={fieldState.error}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <Checkbox
                  name="upload-check-later"
                  label={<Trans i18nKey="funding:voidedCheckLater" />}
                  onChange={() => {
                    setShowUploadForm(!showUploadForm)
                  }}
                />
              </div>
              {showUploadForm && (
                <Controller
                  name="verification_file_uuid"
                  control={control}
                  rules={{ required: 'File is required' }}
                  render={({ field, fieldState }) => (
                    <div className="mb-6">
                      <FileUpload
                        {...field}
                        accept=".pdf"
                        label={t('common:uploadDocument')}
                        uploadFileFunction={async (file: File) => {
                          const signedS3Resp = await getSignedS3Url({
                            fileName: file.name,
                            type: 'check',
                          })
                          if (signedS3Resp.status === 200) {
                            const { fields, url, s3_obj_id } = signedS3Resp.data
                            const uploadResp = await uploadToSignedS3(
                              file,
                              url,
                              fields
                            )
                            console.log(uploadResp)
                            setValue('verification_file_uuid', s3_obj_id)
                          }
                        }}
                      />
                      {fieldState.error && (
                        <span className="mb-1 text-xs text-alert">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              )}
            </form>
            <FormFooter
              backLocation={
                !isWire
                  ? `/invest/${investData?.investment?.id}/funding`
                  : `/invest/${investData?.investment?.id}/funding/wire/distribution`
              }
              disabled={showUploadForm && !watchFile}
              submitFunc={onSubmit}
            />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </div>
        </div>
        <div className="hidden h-full flex-grow xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default ACHManual
