import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

type PaginationTypes = {
  pagination: {
    pageIndex: number
    pageSize: number
  }
  totalPages: number
  totalCount: number
  changePage: (a: number) => void
}

export function Pagination({
  pagination,
  totalPages,
  totalCount,
  changePage,
}: PaginationTypes) {
  const getStartRow = () => {
    return pagination.pageIndex * pagination.pageSize + 1
  }

  const getEndRow = () => {
    return (pagination.pageIndex + 1) * pagination.pageSize <= totalCount
      ? pagination.pageIndex * pagination.pageSize + 10
      : totalCount
  }

  return (
    <div className="flex items-center justify-between border-t px-4 py-2">
      <div className="flex-1 text-sm text-muted-foreground">
        {totalCount > 0
          ? `${getStartRow()} - ${getEndRow()} ownership types`
          : 'No ownership types'}
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => changePage(pagination.pageIndex - 1)}
            disabled={!(pagination.pageIndex > 0)}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <div className="flex items-center justify-center text-sm font-medium">
            Page{' '}
            <Select
              value={`${pagination.pageIndex + 1}`}
              onValueChange={(value) => {
                changePage(Number(value) - 1)
              }}
            >
              <SelectTrigger className="h-8 mx-2 w-[70px]">
                <SelectValue placeholder={pagination.pageIndex + 1} />
              </SelectTrigger>
              <SelectContent side="top">
                {Array.from(
                  { length: Math.max(totalPages, 1) },
                  (_, i) => i + 1
                ).map((pageNumber) => (
                  <SelectItem key={pageNumber} value={`${pageNumber}`}>
                    {pageNumber}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>{' '}
            of {Math.max(totalPages, 1)}
          </div>
          <Button
            variant="ghost"
            className="h-8 w-8 p-0"
            onClick={() => changePage(pagination.pageIndex + 1)}
            disabled={pagination.pageIndex + 2 > totalPages}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}
