import { z } from 'zod'
import { addressSchema } from '../../shared/address-schema'

const nameSchema = z.object({
  first_name: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
  last_name: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

export type NameTypes = z.infer<typeof nameSchema>

const phoneSchema = z.object({
  phone_number: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

export type PhoneTypes = z.infer<typeof phoneSchema>

const birthDateSchema = z.object({
  birthdate: z
    .string()
    .nullish()
    .transform((x) => x ?? undefined),
})

export type BirthDateTypes = z.infer<typeof birthDateSchema>

export const profileDataSchema = z
  .object({
    uuid: z.string().uuid(),
    has_approved_investments: z.boolean(),
    address: addressSchema.nullish().transform((x) => x ?? undefined),
  })
  .merge(nameSchema)
  .merge(phoneSchema)
  .merge(birthDateSchema)

export type ProfileDataTypes = z.infer<typeof profileDataSchema>

export const commPrefsSchema = z.object({
  sms_opt_in: z.boolean(),
})

export type CommPrefsTypes = z.infer<typeof commPrefsSchema>
