import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { datadogRum } from '@datadog/browser-rum'

import { getPersonalInfo, updatePersonalInfo } from '../shared/_api'
import { useInvestDataState } from './_investDataState'
import { IndividualAccountTypes } from '../shared/_types'

export const usePersonalInfoDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['personalInfo'],
    queryFn: async () => {
      const data = await getPersonalInfo(investData.opportunity.id)
      datadogRum.setUser({
        name: data.first_name + ' ' + data.last_name,
      })
      return {
        ...data,
      }
    },
    staleTime: Infinity,
  })
}

export const usePersonalInfoMutation = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data) => updatePersonalInfo(investData?.opportunity?.id, data),
    onMutate: async (newPersonalInfo: IndividualAccountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['personalInfo'],
      })
      const previousPersonalInfo =
        queryClient.getQueryData<IndividualAccountTypes>([
          'personalInfo',
          investData.opportunity.id,
        ])
      if (previousPersonalInfo) {
        queryClient.setQueryData<IndividualAccountTypes>(['personalInfo'], {
          ...previousPersonalInfo,
          ...newPersonalInfo,
        })
      }
      return { previousPersonalInfo }
    },
    onSuccess: (data: IndividualAccountTypes) =>
      Promise.all([
        queryClient.setQueryData<IndividualAccountTypes>(['personalInfo'], {
          ...data,
        }),
        queryClient.invalidateQueries({
          queryKey: ['personalInfo'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['invest'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['account'],
        }),
      ]),
    onError: (_err: any, _variables: any, context) => {
      if (context?.previousPersonalInfo) {
        queryClient.setQueryData<IndividualAccountTypes>(
          ['personalInfo'],
          context.previousPersonalInfo
        )
      }
    },
  })
}
