export const footer = {
  crs: `Form CRS`,
  regBestInterest: `Regulation Best Interest Disclosures`,
  tsAgreement: `By accessing this site and any pages thereof (the “Platform”), you agree to be bound by our`,
  brokerageServices: `Brokerage services are offered by RM Securities, LLC, an SEC-registered broker-dealer (member`,
  brokerageServices2: `). Research RM Securities, LLC, on`,
  finraBrokerCheck: `FINRA's BrokerCheck`,
  brokerageServices3: `Please note, brokerage services are currently not available in all states and are only offered in those states in which RM Securities, LLC is registered. Brokerage services are not intended for or directed to, nor will they be provided to, residents in any other state or otherwise where the provision of such services would violate any state law.`,
  notAvailableInAllStates: `Please note, brokerage services are currently not available in all states and are only offered in those states in which RM Securities, LLC is registered. This email is not intended for or directed to, nor will brokerage services be provided to, residents in any other state or otherwise where the provision of such services would violate any state law.`,
  rmAdvisorLLCTerms: `a wholly owned subsidiary of RealtyMogul, Co., is an SEC-registered investment adviser and an affiliate of RM Securities, LLC. RM Adviser, LLC provides investment management services exclusively to certain REITs and investment funds and does not provide investment advisory services to individuals.`,
  considerObjectivesDisclosure: `Please consider your objectives before investing. Past performance is not indicative of future results. Forward-looking statements, hypothetical information or calculations, financial estimates, assumptions, or estimates are inherently uncertain. Such information should not be used as a basis for an investor's decision to invest. Securities offered by RM Securities, LLC on the Platform, including investments in real estate, are speculative, highly illiquid, and involve substantial risk. You should not invest unless you can sustain the risk of loss of capital, including the risk of total loss of capital.`,
  allInformationDiscloser: `All information contained on the Platform, or in any advertisement linking to the Platform, concerning any prospective or actual investment product, including any forward-looking statements, hypothetical information or calculations, financial estimates, assumptions or estimates, is provided by the sponsor and/or issuer (or their affiliates) (“Sponsor”) of the relevant investment product and, unless stated otherwise in writing, not by Realty Mogul, Co., RM Securities, LLC, or any of its affiliates. All Information concerning investment products is subject to change and to the various disclosures on the Platform, and is qualified in its entirety by the more comprehensive information provided in the relevant Sponsor's offering materials, which contain important information about the relevant investment product's investment objectives, business plan, risks, charges, expenses, and other information which should be considered carefully before investing. Unless specifically stated in writing to the contrary, Realty Mogul, Co., RM Securities, LLC, and their affiliates make no representations or warranties as to the accuracy of a Sponsor's information, including in advertisements, and accepts no liability therefor.`,
  adviceFromSecuritiesProfessional: `Advice from a securities professional is strongly advised, and we recommend that you consult with a financial advisor, attorney, tax advisor, and any other professional that can help you to understand and assess the risks associated with investing in privately offered securities and real estate.`,
  bcp: `BCP Disclosure`,
}
