import { useState } from 'react'
import { formatPhoneNumber, isValidPhoneNumber } from 'react-phone-number-input'

import { useProfile } from '@/routes/dashboard/profile/api/get-profile'

import { EmptyState } from '../components/empty-state'
import { Loader } from '@/components/Loader'
import { ProfileItem } from './components/profile-item'
import { ProfileSubNav } from './components/profile-subnav'

import { formatAddress } from '../shared/format-address'
import { UpdateAddress } from './components/update-address'
import { UpdateName } from './components/update-name'
import { UpdateBirthdate } from './components/update-birthdate'
import { UpdatePhone } from './components/update-phone'
import { useCommPrefs } from './api/get-comm-prefs'

const getPhoneNumber = (phoneNumber: string) => {
  if (isValidPhoneNumber(phoneNumber)) {
    return formatPhoneNumber(phoneNumber)
  }
  return phoneNumber
}

const Profile = () => {
  const [screen, setScreen] = useState('')
  const { data, isLoading, isError } = useProfile()
  const { data: commPrefsData } = useCommPrefs()
  const isLocked = data?.has_approved_investments

  return data && commPrefsData ? (
    <div className="w-full h-full">
      {screen && (
        <div className="inline-block text-[13px] mb-10">
          <button className="text-primary" onClick={() => setScreen('')}>
            Profile
          </button>
          <i className="fa-light fa-angle-right mx-2" />
          Update {screen}
        </div>
      )}
      <h1 className="text-[31px] font-bold self-start">
        {!screen ? 'Profile' : `Update ${screen}`}
      </h1>
      {!screen && (
        <div>
          <div className="my-10">
            <ProfileSubNav />
          </div>
          <div className="border rounded-t -mb-px">
            <ProfileItem
              label="Name"
              value={`${data.first_name} ${data.last_name}`}
              isLocked={isLocked}
              callback={setScreen}
            />
          </div>
          <div className="border -mb-px">
            <ProfileItem
              label="Date of Birth"
              value={`${data.birthdate ? new Date(data.birthdate ?? '').toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' }) : ''}`}
              isLocked={isLocked}
              callback={setScreen}
            />
          </div>
          <div className="border -mb-px">
            <ProfileItem
              label="Phone"
              value={getPhoneNumber(data.phone_number ?? '')}
              callback={setScreen}
            />
          </div>
          <div className="border rounded-b">
            <ProfileItem
              label="Address"
              value={data.address ? formatAddress(data.address) : ''}
              callback={setScreen}
            />
          </div>
        </div>
      )}
      {screen === 'Name' && (
        <UpdateName
          nameData={{ first_name: data.first_name, last_name: data.last_name }}
          callback={setScreen}
        />
      )}
      {screen === 'Date of Birth' && (
        <UpdateBirthdate
          dobData={{ birthdate: data.birthdate }}
          callback={setScreen}
        />
      )}
      {screen === 'Phone' && (
        <UpdatePhone
          data={{ phone_number: data.phone_number }}
          callback={setScreen}
        />
      )}
      {screen === 'Address' && (
        <UpdateAddress
          addressData={data.address ?? undefined}
          callback={setScreen}
        />
      )}
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your profile settings."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Profile
