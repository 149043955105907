import { useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuthDataState } from '../../shared/_authDataState'
import { useInvestDataState } from '../_investDataState'
import {
  useBankAccountsDataState,
  useMutateUpdateWithBankAccount,
} from '../_bankAccountsDataState'
import { useAmountDataState } from '../_amountDataState'
import { moneyMask } from '../../../utils/helpers'

import Checkbox from '@/components/Checkbox'
import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'

interface FormType {
  wireDecision: 'committed' | 'haveWired'
  delayedFundingCheckbox: boolean
}

const WireScreen = () => {
  const nav = useNavigate()
  const [serverError, setServerError] = useState('')
  const { data: investData } = useInvestDataState()
  const { data: authData } = useAuthDataState()
  const { data: bankAccountsData } = useBankAccountsDataState()
  const { data: amountData } = useAmountDataState()
  const bankAccountMutation = useMutateUpdateWithBankAccount()
  const { t } = useTranslation()

  const { handleSubmit, control: wireControl } = useForm<FormType>({
    defaultValues: {
      wireDecision: 'committed',
      delayedFundingCheckbox: false,
    },
  })

  const onSubmit = handleSubmit(async (data: FormType) => {
    console.log(data)
    bankAccountMutation
      .mutateAsync({
        funding_status: 'wire_pending',
      })
      .then(() => {
        nav(`/invest/${investData.opportunity.id}/funding/wire/distribution`)
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    amountData &&
    bankAccountsData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={2} currentStep={1} totalStepCount={3} />
        ) : (
          <Stepper currentSection={2} currentStep={1} totalStepCount={5} />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <div className="pb-12">
              <h1 className="mb-3 text-2xl font-bold text-content-black">
                {t('funding:fundYourInvestment')}
              </h1>
              <p className="mb-6 italic">{t('funding:wireTransfer')}</p>
              <p className="mb-6 text-16 text-content-black">
                {!bankAccountsData.delayed_funding
                  ? t('funding:wireTransferCopy')
                  : `Once your investment has been reviewed, we will send you an email to ${authData?.attributes?.email} with a link to access wire instructions to fund your investment`}
              </p>
              <form onSubmit={onSubmit}>
                <p className="mb-6 text-xl font-medium text-content-black">
                  {t('funding:amountToWire')}
                </p>
                <p className="mb-2 text-base">{t('common:investmentAmount')}</p>
                <p className="mb-6 bg-bg-lighter p-3 font-medium">
                  {`$${moneyMask(amountData.amount)}`}
                </p>
                {!bankAccountsData.delayed_funding && (
                  <p className="mb-6 text-xl font-medium text-content-black">
                    {t('funding:wireInstructions')}
                  </p>
                )}
                <div className="mb-6 bg-[#FDF4D3] p-3 text-base">
                  <p className="font-medium">{t('funding:important')}</p>
                  <p>{t('funding:sameBankAccount')}</p>
                </div>
                {!bankAccountsData.delayed_funding && (
                  <div className="mb-6 bg-bg-lighter p-3 text-base whitespace-pre-line prose">
                    {bankAccountsData.wire_instructions}
                  </div>
                )}
                <p
                  className={`${!bankAccountsData.delayed_funding ? 'mb-2 text-base' : 'mb-6 text-xl font-medium text-content-black'}`}
                >
                  {t('funding:confirmation')}
                </p>
                {!bankAccountsData.delayed_funding ? (
                  <Controller
                    name="wireDecision"
                    control={wireControl}
                    rules={{ required: 'Please check the box to continue.' }}
                    render={({ field, fieldState }) => (
                      <div
                        className={`mb-6 flex flex-col gap-px first:bg-red-50 ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        <Radio
                          {...field}
                          value="committed"
                          label="I commit to wire funds later."
                          checked={field.value === 'committed'}
                        />
                        <Radio
                          {...field}
                          value="haveWired"
                          label="I have wired the funds."
                          checked={field.value === 'haveWired'}
                        />
                      </div>
                    )}
                  />
                ) : (
                  <Controller
                    name="delayedFundingCheckbox"
                    control={wireControl}
                    rules={{ required: 'Please check the box to continue.' }}
                    render={({ field, fieldState }) => (
                      <div
                        className={`mb-6 flex flex-col gap-px first:bg-red-50 ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        <Checkbox
                          {...field}
                          checked={field.value === true}
                          errorMessage={fieldState.error}
                          label="I commit to wire funds later."
                        />
                      </div>
                    )}
                  />
                )}
                <p className="mb-2 text-base">Tracking</p>
                <div className="mb-6 bg-bg-lighter p-3">
                  <p className="mb-6 text-base font-normal">
                    {t('funding:afterWireInstructions')}
                  </p>
                  <a
                    href="mailto:operations@realtymogul.com"
                    className="text-base text-primary"
                  >
                    operations@realtymogul.com
                  </a>
                </div>
                <FormFooter
                  backLocation={
                    investData?.investment?.amount > 100_000 ||
                    investData?.opportunity?.wire_only
                      ? ''
                      : `/invest/${investData?.investment.id}/funding`
                  }
                  submitFunc={onSubmit}
                />
                {serverError && (
                  <ServerError
                    serverError={serverError}
                    id={investData?.investment?.id}
                  />
                )}
              </form>
            </div>
          </div>
          <div className="hidden h-full flex-grow xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default WireScreen
