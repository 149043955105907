import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { findRoute } from '../../../rules/findRoute'
import { useInvestDataState } from '../_investDataState'
import {
  useEmploymentDataState,
  useMutateEmployment,
} from '../_employmentDataState'

import FormFooter from '../../shared/FormFooter'
import TextInput from '../../../components/TextInput'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ServerError from '../../../components/ErrorAlert/ServerError'

interface FormType {
  employer: string
  current_position: string
  source_of_wealth: string
  finra: string
}

interface ModalType {
  modalContinue?: string
}

const Employed = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: employmentData } = useEmploymentDataState()
  const { isLoading, mutateAsync: mutateEmployment } = useMutateEmployment()
  const { t } = useTranslation()

  const ref = useRef<HTMLDialogElement>(null)

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      employer: employmentData?.employer || '',
      current_position: employmentData?.current_position || '',
      source_of_wealth: employmentData?.source_of_wealth || '',
      finra: employmentData?.finra ? 'yes' : 'no',
    },
  })

  const { control: modalControl, handleSubmit: handleModalSubmit } =
    useForm<ModalType>({
      defaultValues: {
        modalContinue: '',
      },
    })

  const onSubmit = handleSubmit(async (data) => {
    console.log(errors, data)
    setServerError('')
    if (data.finra === 'yes') {
      ref?.current?.showModal()
      return
    }
    mutateEmployment({
      ...data,
      employment_status: 'Employed',
      finra: data.finra !== 'no',
    })
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            employment: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  const onModalSubmit = handleModalSubmit((data) => {
    if (data.modalContinue === 'no') {
      nav(`/invest/${investData.opportunity.id}/employment/failed`)
      return
    }
    const submitData = {
      ...data,
      ...getValues(),
      finra: true,
      employment_status: 'Employed',
    }
    delete submitData.modalContinue
    mutateEmployment(submitData)
      .then(() => {
        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            employment: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  // const backLocation = `/invest/${investData?.investment.id}/employment`

  return (
    employmentData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={1} currentStep={9} totalStepCount={10} />
        ) : (
          <Stepper currentSection={1} currentStep={8} totalStepCount={9} />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              {t('employment:employment')}
            </h1>
            <p className="mb-6 text-base italic text-content-light">
              {t('employment:employed')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {t('employment:provideInformationBelow')}
            </p>
            <form onSubmit={onSubmit}>
              <div className="mb-6">
                <Controller
                  name="employer"
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <TextInput
                      label="Employer"
                      placeholder="Company Name"
                      errorMessage={fieldState.error}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <Controller
                  name="current_position"
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <TextInput
                      label="Current Position Held"
                      placeholder="Current Position"
                      errorMessage={fieldState.error}
                      {...field}
                    />
                  )}
                />
              </div>
              <div className="mb-6">
                <Controller
                  name="source_of_wealth"
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <TextInput
                      label="Source of Wealth"
                      placeholder="Source of Wealth"
                      helpText={t('employment:largestContributor')}
                      errorMessage={fieldState.error}
                      {...field}
                    />
                  )}
                />
              </div>
              <p className="mb-6 text-16 text-content-black">
                {t('employment:finra')}
              </p>
              <p className="mb-6 text-16 italic text-content-black">
                {t('employment:finraEmployee')}
              </p>
              <Controller
                name="finra"
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <div
                    className={`flex flex-col gap-px ${
                      fieldState.error && 'border border-alert'
                    }`}
                  >
                    <Radio
                      {...field}
                      value={'yes'}
                      label="Yes"
                      checked={field.value === 'yes'}
                    />
                    <Radio
                      {...field}
                      value="no"
                      label="No"
                      checked={field.value === 'no'}
                    />
                    <p className="my-2 text-xs text-alert">
                      {fieldState.error?.message}
                    </p>
                  </div>
                )}
              />
              <FormFooter
                backLocation={`/invest/${investData?.investment.id}/employment`}
                submitFunc={onSubmit}
                disabled={isLoading}
              />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
        <dialog ref={ref} className="p-8 max-w-xl">
          <button
            className="absolute right-3 top-3 p-3.5"
            onClick={(e) => {
              e.preventDefault()
              ref?.current?.close()
            }}
          >
            <i className="fa-sharp fa-solid fa-xmark fa-xl"></i>
          </button>
          <p className="mb-6 pr-6 text-[19px] font-medium">
            {t('employment:finraVerify')}
          </p>
          <p className="mb-6">{t('employment:employerVerify')}</p>
          <p className="text- mb-2">{t('common:pleaseSelectOne')}</p>
          <form onSubmit={onModalSubmit}>
            <div className="mb-6">
              <Controller
                name="modalContinue"
                control={modalControl}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <div
                    className={`flex flex-col gap-px ${
                      fieldState.error && 'border border-alert'
                    }`}
                  >
                    <Radio {...field} value="yes" label="Yes" />
                    <Radio {...field} value="no" label="No" />
                  </div>
                )}
              />
            </div>
            <button
              className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary disabled:opacity-50 text-[#F2F2F2]"
              onClick={onModalSubmit}
              disabled={isLoading}
            >
              Continue
            </button>
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </form>
        </dialog>
      </div>
    )
  )
}

export default Employed
