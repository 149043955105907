import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { useSearchParams } from 'react-router-dom'
import { messagesDataSchema, MessagesDataTypes } from './schema'

export const getMessages = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/messages', {
    params,
  })
  return messagesDataSchema.parse(response)
}

export const useMessages = () => {
  const [searchParams, _] = useSearchParams()
  return useQuery<MessagesDataTypes>({
    queryKey: [
      'messages',
      searchParams.get('page'),
      searchParams.get('io_uuid'),
      searchParams.get('investment_account_uuid'),
      searchParams.get('date_from'),
      searchParams.get('date_to'),
      searchParams.get('unread'),
    ],
    queryFn: () => getMessages(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!searchParams.get('page'),
  })
}
