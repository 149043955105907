import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { addAccount } from '@/routes/shared/_api'
import { RetirementAccountTypes } from '@/routes/shared/_types'
import { useInvestDataState } from '../_investDataState'
import { useCustodiansDataState } from '../_accountDataState'

import ErrorAlert from '@/components/ErrorAlert'
import ServerError from '@/components/ErrorAlert/ServerError'
import FormFooter from '@/routes/shared/FormFooter'
import Radio from '@/components/Radio'
import Stepper from '@/components/Stepper'
import TextInput from '@/components/TextInput'
import Sidebar from '../Sidebar'
import NumberInput from '@/components/NumberInput'
import { findRoute } from '@/rules/findRoute'

interface AccountFormTypes {
  custodian: string
}

const RetirementSelectionScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const [screen, setScreen] = useState('select')
  const [other, setOther] = useState(false)
  const [selectedCustodian, setSelectedCustodian] = useState('')
  const [serverError, setServerError] = useState('')

  const { data: investData } = useInvestDataState()
  const { data: custodiansData } = useCustodiansDataState()

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<AccountFormTypes>({
    defaultValues: {
      custodian: investData?.account?.type,
    },
  })

  const { control: newControl, handleSubmit: newHandleSubmit } =
    useForm<RetirementAccountTypes>({
      defaultValues: {
        account_name: '',
        account_number: '',
        custodian_other: '',
      },
    })

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    if (data.custodian === 'other') {
      setOther(true)
    } else {
      setSelectedCustodian(data.custodian)
    }
    setScreen('new')
  })

  const updateAccountMutation = useMutation(
    (data: RetirementAccountTypes) => {
      return addAccount(investData?.investment?.id, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['invest'],
        })
        queryClient.invalidateQueries({
          queryKey: ['account'],
        })

        findRoute({
          ...investData,
          investment_state: {
            ...investData?.investment_state,
            investment_account: 'submitted',
            self_accreditation:
              investData.opportunity.io_type === 'reit'
                ? 'required'
                : investData.investment_state?.self_accreditation,
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      },
      onError: (error: any) => {
        console.error(error.message)
        setServerError(error.message)
      },
    }
  )

  const onNewSubmit = newHandleSubmit((data) => {
    const submissionData: RetirementAccountTypes = {
      type: 'foe_sdira',
      account_name: data.account_name,
      account_number: data.account_number,
    }
    if (other) {
      submissionData['custodian_other'] = data.custodian_other
    } else {
      submissionData['custodian_uuid'] = selectedCustodian
    }
    updateAccountMutation.mutateAsync(submissionData)
  })

  return (
    custodiansData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
        ) : (
          <Stepper
            currentSection={0}
            currentStep={3}
            totalStepCount={4}
            isPledge={
              investData.opportunity.status.toLowerCase() ===
              'open for pledging'
            }
          />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              Investor Account
            </h1>
            <p className="mb-6 text-base italic text-content-light">
              Retirement
            </p>
            {screen === 'select' && (
              <>
                <p className="mb-6 text-16 text-content-black">
                  Select your IRA custodian below.
                </p>
                <form onSubmit={onSubmit}>
                  <div className="mb-5">
                    <p className="mb-2 text-[14px] text-content-black">
                      Custodian Name
                    </p>
                    {Object.keys(errors).length > 0 && (
                      <ErrorAlert text="Please select a choice below:" />
                    )}
                    <Controller
                      name="custodian"
                      control={control}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <div
                          className={`flex flex-col gap-px ${
                            fieldState.error && 'border border-alert'
                          }`}
                        >
                          {custodiansData.map((custodian: any) => (
                            <Radio
                              {...field}
                              key={custodian.uuid}
                              value={custodian.uuid}
                              label={custodian.name}
                              checked={field.value === custodian.uuid}
                            />
                          ))}
                          <Radio
                            {...field}
                            value="other"
                            label="Other"
                            checked={field.value === 'other'}
                          />
                        </div>
                      )}
                    />
                  </div>
                </form>
              </>
            )}
            {screen === 'new' && (
              <>
                <p className="mb-6 text-16 text-content-black">
                  Enter your{' '}
                  {
                    custodiansData.find(
                      (item) => item.uuid === selectedCustodian
                    )?.name
                  }{' '}
                  account details.
                </p>
                <form onSubmit={onNewSubmit}>
                  {Object.keys(errors).length > 0 && (
                    <ErrorAlert text="Please correct the error(s) below:" />
                  )}
                  {other && (
                    <div className="mb-6">
                      <Controller
                        name="custodian_other"
                        control={newControl}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <div className={`flex flex-col gap-px}`}>
                            <TextInput
                              label="Custodian Name"
                              placeholder="Custodian Name"
                              errorMessage={fieldState.error}
                              {...field}
                            />
                          </div>
                        )}
                      />
                    </div>
                  )}
                  <div className="mb-6">
                    <Controller
                      name="account_name"
                      control={newControl}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <div className={`flex flex-col gap-px`}>
                          <TextInput
                            label="Account Holder Name"
                            placeholder="Account Holder Name"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className="mb-6">
                    <Controller
                      name="account_number"
                      control={newControl}
                      rules={{ required: 'is required' }}
                      render={({ field, fieldState }) => (
                        <div className={`flex flex-col gap-px`}>
                          <NumberInput
                            label="Account Number"
                            placeholder="Account Number"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        </div>
                      )}
                    />
                  </div>
                </form>
              </>
            )}
            <FormFooter
              backLocation={`/invest/${investData?.investment?.id}/account/retirement`}
              disabled={!isDirty || !isValid || isSubmitting}
              submitFunc={screen === 'select' ? onSubmit : onNewSubmit}
            />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default RetirementSelectionScreen
