import { moneyMask } from '../../utils/helpers'

import { useTranslation } from 'react-i18next'
const PropertyInfoRow = ({
  testName,
  label,
  value,
}: {
  testName?: string
  label?: string
  value?: string
}) => (
  <div data-cy={testName} className="flex justify-between text-base font-light">
    <div className="row-label">{label}</div>
    <div className="row-value">{value}</div>
  </div>
)

const PropertyInfo = ({ opportunity }: any) => {
  const { t } = useTranslation()
  return (
    <>
      <p className="mb-2 text-base font-normal text-content-black">
        {t('propertyInfo:selectedInvestment')}
      </p>
      <img
        className="w-full rounded-t max-h-[170px] object-cover"
        src={
          opportunity.image ||
          'https://realtymogul.brightspotgocdn.com/dims4/default/6509e4e/2147483647/strip/true/crop/778x437+0+164/resize/500x281!/quality/90/?url=https%3A%2F%2Fbrightspot-go-k1-realtymogul.s3.us-east-1.amazonaws.com%2Fbrightspot%2F97%2F85%2F84b836f949779c7330f9d5d7259c%2Frm-logo-on-cover-image1.jpg'
        }
        alt="image of buildings"
      />
      {opportunity.io_type !== 'reit' ? (
        <div className="mb-6 rounded-b bg-black p-4 text-white">
          <h2 className="mb-1.5 text-[17px]" data-cy="property-location">
            {opportunity.property_location}
          </h2>
          <p className="mb-1.5 text-sm text-[#9B9B9B]" data-cy="property-name">
            {opportunity.name}
          </p>
          {opportunity.minimum_investment && (
            <PropertyInfoRow
              testName="minimum-investment"
              label={t('common:minInvestment')}
              value={`$${moneyMask(opportunity.minimum_investment)}`}
            />
          )}
          {opportunity.estimated_hold_period && (
            <PropertyInfoRow
              testName="hold-period"
              label={t('common:holdPeriod')}
              value={`${Number(
                (opportunity.estimated_hold_period / 12).toFixed(1)
              )} years`}
            />
          )}
        </div>
      ) : (
        <div className="mb-6 rounded-b bg-black p-4 text-white">
          <h2 data-cy="property-name" className="mb-1.5 text-[17px]">
            {opportunity.name}
          </h2>
          {opportunity.total_asset_value && (
            <p
              data-cy="total-asset-value"
              className="mb-1.5 text-sm text-[#9B9B9B]"
            >
              {t('propertyInfo:totalAssetValue')}
              {moneyMask(opportunity.total_asset_value)}
            </p>
          )}
          {opportunity.reit_asset_value && (
            <PropertyInfoRow
              testName="purchase-price-per-share"
              label={t('common:purchasePrice')}
              value={`$${Number(opportunity.reit_asset_value).toFixed(2)}`}
            />
          )}
          {opportunity.reit_price_info && (
            <PropertyInfoRow
              testName="net-asset-value-per-share"
              label={t('common:netAssetValue')}
              value={`$${Number(opportunity.reit_price_info).toFixed(2)}`}
            />
          )}
        </div>
      )}
    </>
  )
}

export default PropertyInfo
