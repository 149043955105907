import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  NameTypes,
  BirthDateTypes,
  PhoneTypes,
  ProfileDataTypes,
} from './schema'
import { AddressTypes } from '../../shared/address-schema'

import { api } from '@/routes/shared/_api'

const patchProfile = (
  data: NameTypes | BirthDateTypes | PhoneTypes | AddressTypes
): Promise<ProfileDataTypes> => {
  const isAddressType = (
    data: NameTypes | BirthDateTypes | PhoneTypes | AddressTypes
  ): data is AddressTypes => 'thoroughfare' in data
  const payload = isAddressType(data) ? { address: data } : data
  return api.patch(`/dashboard/profile/personal-info`, payload)
}

export const usePatchProfile = () => {
  const queryClient = useQueryClient()

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile'],
      })
    },
    mutationFn: patchProfile,
  })
}
