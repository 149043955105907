import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { usePageViews } from '../../utils/analytics'
import { useInvestDataState } from './_investDataState'
import { useAuthDataState } from '../shared/_authDataState'
import { useEmailVerifiedDataState } from './_emailVerifiedDataState'
import { useStringsDataState } from './_stringsDataState'
import { findRoute } from '../../rules/findRoute'

import Footer from '../shared/Footer'
import Login from '../shared/Login'
import Loader from '../../components/Loader'
import Header from '../shared/Header'

const Invest = () => {
  const [redirected, setRedirected] = useState(false)
  const ioID = window.location.pathname.split('/')[2]

  const { isLoading, isFetchedAfterMount, data, error } = useInvestDataState()
  const { isLoading: userIsLoading, data: authData } = useAuthDataState()
  const { data: emailVerifiedData } = useEmailVerifiedDataState()
  const { data: _stringsData } = useStringsDataState()

  const nav = useNavigate()
  const location = useLocation()

  usePageViews('Investflow')

  useEffect(() => {
    window.scrollTo(0, 0)
    if (isLoading || !data || (isFetchedAfterMount && redirected)) return
    if (isFetchedAfterMount) {
      // Handle routing for user who has already started the investment flow and is returning
      // if coming back from docusign, don't redirect with step
      if (location?.state?.docusignReturn) {
        setRedirected(true)
        return
      }
      findRoute({
        ...data,
      }).then((step) => {
        nav(step.type, { replace: true, state: { params: step.params } })
        setRedirected(true)
        return
      })
    }
  }, [nav, ioID, location, isLoading, data, isFetchedAfterMount, redirected])

  return authData && emailVerifiedData ? (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full">
        <Header />
      </div>
      {!error && !isLoading && data && (
        <div className="w-full md:min-h-[90vh] box-border md:my-[30px] md:mx-[60px] xl:my-[60px] xl:max-w-[1200px]">
          <Outlet />
        </div>
      )}
      {error && !isLoading && !data && (
        <div className="p-6 xl:p-[60px] h-[60vh] w-full xl:max-w-[1000px] text-content-black">
          <div className="text-[24px] mb-6 font-bold">Investment not found</div>
          <p className="mb-6">
            Sorry, the investment you are looking for doesn't exist or was
            removed. If you have any questions, please don't hesitate to contact
            us.
          </p>
          <a
            href="https://www.realtymogul.com"
            className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
          >
            Back to Homepage
          </a>
        </div>
      )}
      {(isLoading || userIsLoading) && (
        <div className="flex h-[80vh] w-full items-center justify-center">
          <Loader />
        </div>
      )}
      <div className="w-full bg-black">
        <Footer />
      </div>
    </div>
  ) : (
    <>
      {!isLoading ? (
        <Login />
      ) : (
        <div className="flex h-[80vh] w-full items-center justify-center">
          <Loader />
        </div>
      )}
    </>
  )
}

export default Invest
