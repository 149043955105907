import { ReactNode, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import { STATES } from '../../utils/helpers'

interface InputProps {
  name: string
  label?: string | ReactNode | undefined
  placeholder?: string
  helpText?: string | ReactNode
  errorMessage?: string | FieldError | undefined
  onBlur: () => void
  onChange: () => void
  value: string | undefined
}

const StateDropdown = forwardRef<HTMLSelectElement, InputProps>(
  (props, ref) => {
    const { label, errorMessage, name, onBlur, onChange, value } = props
    return (
      <div>
        {label && (
          <label
            htmlFor={name}
            className={`mb-2 block text-base font-normal ${
              errorMessage ? 'text-alert' : 'text-content-black'
            }`}
          >
            {label}
          </label>
        )}
        <select
          className={`w-full appearance-none border ${
            errorMessage ? 'border-alert' : 'border-input'
          } bg-white bg-down-chevron rounded bg-[center_right_12px] bg-no-repeat px-3 h-[42px] text-[15px] text-content-black focus:outline-none focus:ring-1 focus:border-primary`}
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        >
          {STATES.map((state) => (
            <option key={state.value} value={state.value}>
              {state.label}
            </option>
          ))}
        </select>
        {errorMessage && (
          <p className="mt-2 text-sm text-red-600" id="email-error">
            {typeof errorMessage === 'string'
              ? errorMessage
              : errorMessage.message}
          </p>
        )}
      </div>
    )
  }
)

export default StateDropdown
