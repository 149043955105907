import { useEffect, useState } from 'react'
import { format, parseISO } from 'date-fns'
import { Calendar as CalendarIcon } from 'lucide-react'

import { cn } from '@/utils/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

type DatePickerTypes = {
  value?: string
  handleDateSelect: (value: string) => void
}

export function DatePicker({ value, handleDateSelect }: DatePickerTypes) {
  const [date, setDate] = useState<Date | undefined>(
    value ? parseISO(value) : undefined
  )
  const [calendarOpen, setCalendarOpen] = useState(false)

  useEffect(() => {
    setDate(value ? parseISO(value) : undefined)
  }, [value, setDate])

  return (
    <Popover open={calendarOpen} onOpenChange={setCalendarOpen}>
      <PopoverTrigger asChild>
        <Button
          className={cn(
            'px-3 h-[38px] justify-start text-left text-base bg-white font-normal border border-[#D4D8DD] hover:bg-transparent',
            !date && 'text-muted-foreground'
          )}
        >
          {date ? format(date, 'MM/dd/yyyy') : <span>mm/dd/yyyy</span>}
          <CalendarIcon className="ml-4 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(selectedDate) => {
            setCalendarOpen(false)
            if (selectedDate) {
              handleDateSelect(format(selectedDate, 'yyyy-MM-dd'))
            }
          }}
          disabled={{ after: new Date() }}
        />
      </PopoverContent>
    </Popover>
  )
}
