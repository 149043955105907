import { useQuery } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'
import { get1031 } from '../shared/_api'

export const useTenThirtyOneDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['1031'],
    queryFn: async () => {
      const data = await get1031(investData.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData.investment,
    staleTime: Infinity,
  })
}
