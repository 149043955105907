import { aboutYou } from './english/aboutYou'
import { account } from './english/account'
import { accred } from './english/accred'
import { amount } from './english/amount'
import { common } from './english/common'
import { completed } from './english/completed'
import { employment } from './english/employment'
import { failed } from './english/failed'
import { footer } from './english/footer'
import { funding } from './english/funding'
import { identity } from './english/identity'
import { personalInfo } from './english/personalInfo'
import { propertyInfo } from './english/propertyInfo'
import { review } from './english/review'
import { signature } from './english/signature'
import { tenThirtyOne } from './english/tenThirtyOne'
import { verification } from './english/verification'

export const english = {
  aboutYou,
  account,
  accred,
  amount,
  completed,
  common,
  employment,
  failed,
  footer,
  funding,
  identity,
  personalInfo,
  propertyInfo,
  review,
  signature,
  tenThirtyOne,
  verification,
}
