import { useInvestDataState } from '../_investDataState'
import { useNavigate } from 'react-router-dom'
import { usePersonalInfoDataState } from '../_personalInfoDataState'
import { getDrupalUrl } from '../../shared/_api'

const PledgeComplete = () => {
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { data: personalData } = usePersonalInfoDataState()

  return (
    <div className="h-full w-full border-t-4 border-success bg-white p-6 pb-20">
      <h1 className="mb-6 text-2xl font-bold text-content-black">
        <i
          className="fa-solid fa-circle-check mr-3"
          style={{ color: '#01B6A1' }}
        ></i>
        Thank you, your pledge has been received
      </h1>
      <p className="mb-6 text-16 text-content-black">
        <span className="font-medium">{personalData?.first_name}</span>, thank
        you for your pledge to invest into {investData?.opportunity?.name}. Your
        pledge is non-binding, meaning there is no commitment to the investment
        by creating a pledge. When {investData?.opportunity?.name} opens for
        investment, you will be notified via email to convert your pledge into
        an investment.
      </p>
      {investData?.investment_state?.eav === 'required' && (
        <p className="mb-6 text-16 text-content-black">
          As a pledged investor, we'd like to give you priority access to this
          investment once the offering is Open for Funding. Per SEC regulations,
          investors in this type of real estate offering must be an accredited
          investor. Our records indicate that you have not yet confirmed your
          accreditation status on the Platform or the accreditation verification
          documentation previously provided has expired.
        </p>
      )}
      <p className="mb-6 text-[19px] font-medium text-content-black">
        What Happens Next
      </p>
      <ul className="mb-6 ml-3 list-disc text-16">
        <li className="mb-3">
          When {investData?.opportunity?.name} opens for investment, you will be
          notified by email to convert your pledge into an investment.
        </li>
        {investData.pledgeEAV ? (
          <>
            <li className="mb-3">
              Per SEC regulations, investors in this type of real estate
              offering must be verified as accredited investors. Please continue
              below to verify your accreditation status.
            </li>
            <li className="mb-3">
              If you do not wish to verify accreditation status at this time,
              you can close this window and finish this step later.
            </li>
          </>
        ) : (
          <p></p>
        )}
      </ul>
      <div className="flex flex-col md:flex-row gap-4 items-center">
        {investData?.investment_state?.eav === 'required' && (
          <button
            className="h-10 w-full md:w-fit rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] disabled:opacity-50 hover:disabled:bg-primary md:min-w-[200px] transition duration-200 ease-in-out"
            data-cy="continue-button"
            onClick={(e) => {
              e.preventDefault()
              nav(`/invest/${investData.opportunity.id}/verification`)
            }}
          >
            Submit Accreditation
          </button>
        )}
        <button
          className="h-10 w-full md:w-fit rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] disabled:opacity-50 hover:disabled:bg-primary md:min-w-[200px] transition duration-200 ease-in-out"
          data-cy="continue-button"
          onClick={(e) => {
            e.preventDefault()
            window.location.href = `${getDrupalUrl()}investor-dashboard/active-investments`
          }}
        >
          Go to Dashboard
        </button>
        {investData?.investment_state?.eav !== 'required' && (
          <a
            href="https://www.realtymogul.com/investment-opportunities"
            className="text-primary"
          >
            View Additional Investment Opportunities
          </a>
        )}
      </div>
    </div>
  )
}

export default PledgeComplete
