export const common = {
  helpEmail: 'investor-help@realtymogul.com',
  copyRight: `© {{year}} RM Securities. All rights reserved.`,
  termsOfService: `Terms of Service`,
  privacyPolicy: `Privacy Policy`,
  disclosures: `Disclosures`,
  finra: `FINRA`,
  sipc: `SIPC`,
  contactInfo: `Contact Info`,
  contactInformation: `Contact Information`,
  minInvestment: `Minimum Investment`,
  purchasePrice: `Purchase Price Per Share`,
  netAssetValue: `Net Asset Value Per Share`,
  holdPeriod: `Hold Period`,
  firstName: `First Name`,
  lastName: `Last Name`,
  phoneNumber: `Phone Number`,
  country: `Country`,
  address: `Address`,
  address2: `Address Line 2`,
  city: `City`,
  state: `State`,
  stateProvinceRegion: `State/Province/Region`,
  zipCode: `Zip Code`,
  postalCode: `Postal Code`,
  investorAccount: `Investor Account`,
  individual: `Individual`,
  joint: `Joint`,
  trust: `Trust`,
  entity: `Entity`,
  sdira: 'Retirement',
  documents: `Documents`,
  uploadForm: `Upload Form`,
  uploadDocument: `Upload Document`,
  pdfOnly: `PDF only, please.`,
  pledgeAmount: `Pledge Amount`,
  investmentAmount: `Investment Amount`,
  payment: `Payment`,
  wireTransfer: `Wire Transfer`,
  serverError: `Sorry, something went wrong. Please refresh page and try again or contact `,
  pleaseSelectOne: `Please select one of the following:`,
  seeOtherOpps: `See Other Opportunities`,
  myDashboard: `My Dashboard`,
  ownershipType: `Ownership Type`,
}
