import parse from 'html-react-parser'
import { ScrollArea } from '@/components/ui/scroll-area'
import { MessageType } from '../api/schema'

type SelectedMessageAreaTypes = {
  selectedMessage: MessageType
}

export function SelectedMessageArea({
  selectedMessage,
}: SelectedMessageAreaTypes) {
  return (
    <>
      <div className="w-full flex justify-between border-b bg-bg-lighter text-base px-4 py-3 gap-3 font-medium">
        <span>{selectedMessage.subject}</span>
        <span className="whitespace-pre">{selectedMessage.message_date}</span>
      </div>
      <div className="p-4 flex flex-col gap-1 text-base border-b border-border-lighter">
        <div className="flex items-center">
          <div className="w-32 text-content-light text-sm">Investment</div>
          <div>{selectedMessage.io_name}</div>
        </div>
        <div className="flex items-center">
          <div className="w-32 text-content-light text-sm">Ownership Type</div>
          <div>
            <span
              style={{ textTransform: 'capitalize' }}
            >{`${selectedMessage.investment_account_type}`}</span>
            {` - ${selectedMessage.investment_account_name}`}
          </div>
        </div>
      </div>
      {selectedMessage.attachments.length > 0 && (
        <div className="p-4 flex flex-col gap-1 text-base border-b border-border-lighter">
          <div className="flex items-center">
            <div className="w-32 text-content-light text-sm">Attachments</div>
            <div className="flex-1 flex flex-col gap-1">
              {selectedMessage.attachments.map((attachment) => (
                <a
                  key={attachment.file_name}
                  href={attachment.document_url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-content-black cursor-pointer hover:text-primary-hover"
                >
                  <i className="fa-regular fa-paperclip text-primary mr-2" />
                  <span>{attachment.file_name}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
      )}
      <ScrollArea className="reset-parsed-text px-10 py-8 text-base h-screen">
        {parse(selectedMessage.body)}
      </ScrollArea>
    </>
  )
}
