import { ReactNode, useEffect, useRef } from 'react'
interface ErrorAlertProps {
  text?: ReactNode
}

const ErrorAlert = (props: ErrorAlertProps) => {
  const { text = 'Please fix the error(s) below to continue.' } = props
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  return (
    <div
      ref={ref}
      className="my-6 flex w-full rounded-[4px] bg-error p-3 text-base"
    >
      <div>
        <i className="animate-pulse fa-solid fa-lg fa-circle-exclamation mr-3 text-alert"></i>
      </div>
      <div>{text}</div>
    </div>
  )
}

export default ErrorAlert
