export const FallbackError = () => {
  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full">
        <div className="max-w-[1400px] w-full bg-white px-6 py-4 mx-auto">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-row items-center">
              <img className="mr-4" src="/rm.svg" />
              <span className="text-content-dark text-16 font-medium uppercase tracking-[0.15em]">
                RealtyMogul
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-6 xl:p-[60px] h-[60vh] w-full xl:max-w-[1400px] text-content-black">
        <h1 className="text-[24px] mb-3 font-bold">Something went wrong:</h1>
        <p className="mb-6">
          We're sorry, but something went wrong with our application. Please try
          refreshing the page or come back later.
        </p>
        <p className="mb-6">
          If the problem persists, please contact our support team at{' '}
          <a
            href={`mailto:investor-help@realtymogul.com&subject=Error%20on%20${window.location.href}`}
            className="text-primary"
          >
            investor-help@realtymogul.com
          </a>
          .
        </p>
        <a
          href="https://www.realtymogul.com"
          className="flex items-center h-10 w-full md:w-fit justify-center rounded px-4 bg-primary text-16 font-medium hover:bg-primary-hover border-bg-primary text-[#F2F2F2] md:min-w-[200px]"
        >
          Back to Homepage
        </a>
      </div>
    </div>
  )
}
