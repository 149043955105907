import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { getTransactions, useTransactions } from './api/get-transactions'
import { mapParams } from '../shared/map-params'
import { useTransactionFilters } from './api/get-transaction-filters'
import { hasSpecificFilters } from '../shared/has-specific-filters'

import { columns } from './components/columns'
import { EmptyState } from '../components/empty-state'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

import Loader from '@/components/Loader/index'
import { Filter } from './components/filter'
import { Pagination } from './components/pagination'
import { DateFilter } from '../components/date-filter'

const Transactions = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, isError } = useTransactions()
  const { data: filtersData } = useTransactionFilters()

  const [columnVisibility] = useState<VisibilityState>({
    shares: false,
    purchasePrice: false,
    investmentLink: false,
    investment_account_type: false,
    issuer_name: false,
  })

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'transaction_date', desc: true },
  ])

  const queryClient = useQueryClient()

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', (pagination.pageIndex + 1).toString())
    setSearchParams(newSearchParams)
  }, [queryClient, pagination, searchParams, setSearchParams])

  const table = useReactTable({
    data: data?.transactions ?? [],
    columns,
    state: {
      sorting,
      columnVisibility,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    const nextSearchParams = new URLSearchParams(searchParams)
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(
          paramName,
          paramName === 'transaction_types' ? mapParams(uuid) : uuid
        )
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  const prefetchNextPage = (newPage: number) => {
    queryClient.prefetchQuery({
      queryKey: [
        'tax-documents',
        (Number(searchParams.get('page') || 0) + 2).toString(),
        searchParams.get('io_uuid'),
        searchParams.get('investment_account_uuid'),
        searchParams.get('tax_year'),
        searchParams.get('document_type'),
      ],
      queryFn: () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('page', (newPage + 2).toString())
        return getTransactions(newSearchParams)
      },
    })
  }

  const handleDateFilterSelect = (date_from: string, date_to: string) => {
    const nextSearchParams = new URLSearchParams(searchParams)
    nextSearchParams.delete('unread')
    date_from
      ? nextSearchParams.set('date_from', date_from)
      : nextSearchParams.delete('date_from')
    date_to
      ? nextSearchParams.set('date_to', date_to)
      : nextSearchParams.delete('date_to')
    setSearchParams(nextSearchParams)
  }

  return data ? (
    <div className="w-full h-full">
      <div className="w-full mb-10 flex justify-between items-center">
        <h1 className="text-[31px] font-bold self-start">Transactions</h1>
        <div className="w-3/4 justify-end flex flex-wrap flex-row gap-2">
          {filtersData &&
            Object.entries(filtersData)
              .sort((item) => {
                return item[0] === 'transaction_types'
                  ? -1
                  : item[0] === 'io_filters'
                    ? -1
                    : 1
              })
              .map((entry) => {
                return (
                  <Filter
                    key={entry[0]}
                    name={entry[0]}
                    data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                    updateCallback={handleFilterUpdate}
                    defaultValue={searchParams.get(mapParams(entry[0]))}
                  />
                )
              })}
          <DateFilter
            handleDateFilterSelect={handleDateFilterSelect}
            defaultValues={[
              searchParams.get('date_from') ?? '',
              searchParams.get('date_to') ?? '',
            ]}
          />
        </div>
      </div>
      <div className="mb-6">{data.count} transactions</div>

      {data.transactions.length ||
      hasSpecificFilters([
        'transaction_types',
        'io_uuid',
        'investment_account_uuid',
        'date_from',
        'date_to',
      ]) ? (
        <div className="mb-10">
          <div className="rounded-t-md border overflow-hidden">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} colSpan={header.colSpan}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <Pagination
            pagination={pagination}
            totalPages={data.pages}
            totalCount={data.count}
            changePage={(newPage) => {
              setPagination({ ...pagination, pageIndex: newPage })
              if (newPage + 2 < data.pages && newPage > pagination.pageIndex) {
                prefetchNextPage(newPage)
              }
            }}
          />
        </div>
      ) : (
        <div className="h-[75vh] flex justify-center items-center">
          <EmptyState
            iconClasses="fa-regular fa-rectangle-list fa-3x text-primary opacity-20"
            copy="You have no transactions."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  ) : (
    <div className="flex justify-center items-center w-full h-[35vh]">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your transactions."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Transactions
