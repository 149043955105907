import { useState, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { useTranslation } from 'react-i18next'

import { addAccount, updateAccount } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'
import { useInvestDataState } from '../_investDataState'
import { useTokensDataState } from '@/routes/shared/_tokensDataState'
import { usePersonalInfoDataState } from '../_personalInfoDataState'
import { useAccountDataState } from '../_accountDataState'
import { AccountTypes, JointAccountTypes } from '../../shared/_types'

import Checkbox from '../../../components/Checkbox'
import CountryInput from '../../../components/CountryInput'
import ErrorAlert from '../../../components/ErrorAlert'
import FormFooter from '../../shared/FormFooter'
import NumberInput from '../../../components/NumberInput'
import Radio from '../../../components/Radio'
import ServerError from '../../../components/ErrorAlert/ServerError'
import Sidebar from '../Sidebar'
import StateDropdown from '../../../components/StateDropdown'
import Stepper from '../../../components/Stepper'
import TextInput from '../../../components/TextInput'

const OPTION_STATES = ['AZ', 'CA', 'ID', 'LA', 'NV', 'NM', 'TX', 'WI', 'WA']

const JointAccountScreen = () => {
  const [showAddNew, setShowAddNew] = useState(false)
  const [lockFields, setLockFields] = useState(false)
  const [jointAccount, setJointAccount] = useState('')
  const [use_primary_investor_contact, setUse_primary_investor_contact] =
    useState(false)
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { data: tokenData } = useTokensDataState()
  const { data: accountData } = useAccountDataState()
  const { data: personalInfoData } = usePersonalInfoDataState()

  const {
    control,
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<JointAccountTypes>({
    defaultValues: {
      type: 'joint_ownership',
      subtype: 'community',
      joint_first_name: '',
      joint_last_name: '',
      joint_phone_number: '',
      use_primary_investor_contact: false,
      address: {
        street: '',
        street2: '',
        city: '',
        zip_code: '',
        state: 'AL',
        country: 'US',
      },
    },
  })
  const { t } = useTranslation()
  const watchAddress = watch('address')

  useEffect(() => {
    if (jointAccount === 'addNew') {
      setLockFields(false)
    } else {
      setLockFields(
        !!accountData?.ia_options?.find((account: JointAccountTypes) => {
          return account.uuid === jointAccount
        })?.has_an_approved_investment
      )
    }
  }, [jointAccount, accountData])

  useEffect(() => {
    if (
      !accountData?.ia_options ||
      jointAccount === '' ||
      updateAccountMutation.isLoading
    )
      return
    if (jointAccount !== 'addNew') {
      const matchedJointAccount = accountData.ia_options?.find(
        (account: JointAccountTypes) => {
          return account.uuid === jointAccount
        }
      )

      if (matchedJointAccount) {
        const cleanedJointAccount = Object.fromEntries(
          Object.entries(matchedJointAccount).filter(
            ([_key, value]) => value !== null
          )
        )
        setUse_primary_investor_contact(
          !!cleanedJointAccount.use_primary_investor_contact
        )
        reset({
          ...cleanedJointAccount,
        })
      }
    } else {
      setUse_primary_investor_contact(false)
      reset({
        type: 'joint_ownership',
        subtype: 'community',
        joint_first_name: '',
        joint_last_name: '',
        joint_phone_number: '',
        use_primary_investor_contact: false,
        address: {
          street: '',
          street2: '',
          city: '',
          zip_code: '',
          state: 'AL',
          country: 'US',
        },
      })
    }
    setShowAddNew(true)
  }, [jointAccount, accountData, reset])

  const updateAccountMutation = useMutation({
    mutationFn: (data: JointAccountTypes) => {
      if (use_primary_investor_contact) {
        delete data.address
      }
      return jointAccount !== 'addNew'
        ? updateAccount(investData?.investment?.id, jointAccount, data)
        : addAccount(investData?.investment?.id, data)
    },
    onMutate: async (newJointAccount: JointAccountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['account', 'invest'],
      })
      const previousAccountData = queryClient.getQueryData(['account'])
      queryClient.setQueryData<AccountTypes>(['account'], (oldData) => {
        return { ...oldData, current_account: { ...newJointAccount } }
      })
      return { previousAccountData }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['account'],
      })
      if (
        tokenData?.state_allowlist &&
        !JSON.parse(tokenData?.state_allowlist).includes(
          personalInfoData?.address?.state
        )
      ) {
        nav(`/invest/${investData.opportunity.id}/account/failed`)
        return
      }
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          investment_account: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    },
    onError: (error: any, _variables: any, context) => {
      queryClient.setQueryData(['account'], context?.previousAccountData)
      console.error(error.message)
      setServerError(error.message)
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    setServerError('')
    updateAccountMutation.mutateAsync(data)
  })

  return (
    accountData &&
    personalInfoData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
        ) : (
          <Stepper
            currentSection={0}
            currentStep={3}
            totalStepCount={4}
            isPledge={
              investData.opportunity.status.toLowerCase() ===
              'open for pledging'
            }
          />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              {t('common:ownershipType')}
            </h1>
            <p className="mb-6 italic text-content-black">
              {t('common:joint')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {t('account:selectOrCreateJoint')}
            </p>
            {Object.keys(errors).length > 0 && <ErrorAlert />}
            <form onSubmit={onSubmit} className="xl:">
              <div className="mb-5">
                <p className="mb-2 text-16 text-content-black">
                  {t('account:selectJoint')}
                </p>
                <Controller
                  name="jointAccount"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="mb-6">
                      <div
                        className={`flex flex-col gap-px ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        {accountData.ia_options &&
                          accountData.ia_options.map(
                            (account: JointAccountTypes, index: number) =>
                              account.type === 'joint_ownership' && (
                                <Radio
                                  key={index}
                                  {...field}
                                  value={account.uuid}
                                  label={
                                    account.joint_first_name +
                                    ' ' +
                                    account.joint_last_name
                                  }
                                  onChange={() => {
                                    field.onChange(account.uuid)
                                    setJointAccount(account.uuid)
                                  }}
                                />
                              )
                          )}
                        <Radio
                          {...field}
                          value="addNew"
                          label={t('account:addNewJoint')}
                          onChange={() => {
                            field.onChange('addNew')
                            setJointAccount('addNew')
                          }}
                        />
                      </div>
                      {fieldState.error && (
                        <span className="text-xs text-alert">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                {showAddNew && (
                  <div>
                    <p className="mb-6 text-[19px] font-medium">
                      {t('common:joint')}
                    </p>
                    <div className="mb-6 flex gap-3">
                      <Controller
                        name="joint_first_name"
                        control={control}
                        rules={{ required: 'is required' }}
                        disabled={lockFields}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={t('common:firstName')}
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="joint_last_name"
                        control={control}
                        rules={{ required: 'is required' }}
                        disabled={lockFields}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={t('common:lastName')}
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-6">
                      <Controller
                        name="joint_is_spouse"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            {...field}
                            checked={field.value === true}
                            errorMessage={fieldState.error}
                            label={t('account:spouse')}
                          />
                        )}
                      />
                    </div>
                    {OPTION_STATES.includes(personalInfoData.address.state) && (
                      <div className="mb-6">
                        <p className="mb-6 text-16 text-content-black">
                          {t('account:jointType')}
                        </p>
                        <Controller
                          name="subtype"
                          control={control}
                          rules={{ required: 'is required' }}
                          render={({ field, fieldState }) => (
                            <>
                              <div
                                className={`flex flex-col gap-px ${
                                  fieldState.error && 'border border-alert'
                                }`}
                              >
                                <Radio
                                  {...field}
                                  value="community"
                                  label={t('account:communityProperty')}
                                  checked={field.value === 'community'}
                                />
                                <Radio
                                  {...field}
                                  value="survivorship"
                                  label={t('account:survivorship')}
                                  checked={field.value === 'survivorship'}
                                />
                              </div>
                              {fieldState.error && (
                                <span className="mb-1 text-xs text-alert">
                                  {fieldState.error.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </div>
                    )}
                    <h2 className="mb-6 text-2xl font-bold text-content-black">
                      {t('account:jointInfoContact')}
                    </h2>
                    <div className="mb-6">
                      <Controller
                        name="use_primary_investor_contact"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            {...field}
                            onChange={() => {
                              field.onChange(!field.value)
                              setUse_primary_investor_contact(
                                !use_primary_investor_contact
                              )
                            }}
                            checked={field.value === true}
                            label={t('account:sameAddress')}
                            errorMessage={fieldState.error}
                          />
                        )}
                      />
                    </div>
                    {!use_primary_investor_contact && (
                      <div>
                        <div className="mb-6">
                          <span
                            className={`mb-2 block text-base font-normal ${
                              errors?.joint_phone_number?.message
                                ? 'text-alert'
                                : 'text-content-black'
                            }`}
                          >
                            {t('common:phoneNumber')}
                          </span>
                          <PhoneInput
                            name="joint_phone_number"
                            className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                              errors?.joint_phone_number
                                ? 'border-alert'
                                : 'border-input'
                            } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                            defaultCountry="US"
                            countryOptionsOrder={['US']}
                            control={control}
                            initialValueFormat="national"
                            limitMaxLength={true}
                            rules={{
                              required: 'is required',
                              validate: {
                                isValidPhoneNumber: (value: string) =>
                                  isValidPhoneNumber(value) ||
                                  'Please enter a valid phone number.',
                              },
                            }}
                          />
                          {errors?.joint_phone_number?.message && (
                            <p className="mb-1 text-xs text-alert">
                              {errors?.joint_phone_number?.message}
                            </p>
                          )}
                        </div>
                        <div className="mb-6">
                          <CountryInput
                            register={register('address.country')}
                            errorMessage={errors?.address?.country?.message}
                          />
                        </div>
                        <div className="mb-2">
                          <Controller
                            name="address.street"
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field, fieldState }) => (
                              <TextInput
                                label={t('common:address')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="address.street2"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextInput
                                placeholder={t('common:address2')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="address.city"
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field, fieldState }) => (
                              <TextInput
                                label={t('common:city')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6 flex gap-3">
                          <div className="w-1/2">
                            <Controller
                              name="address.state"
                              control={control}
                              rules={{
                                required:
                                  watchAddress?.country === 'US'
                                    ? 'is required'
                                    : false,
                              }}
                              render={({ field, fieldState }) =>
                                watchAddress?.country === 'US' ? (
                                  <StateDropdown
                                    errorMessage={fieldState?.error?.message}
                                    label={t('common:state')}
                                    {...field}
                                  />
                                ) : (
                                  <TextInput
                                    label={
                                      watchAddress?.country === 'US'
                                        ? t('common:state')
                                        : t('common:stateProvinceRegion')
                                    }
                                    errorMessage={fieldState?.error?.message}
                                    {...field}
                                  />
                                )
                              }
                            />
                          </div>
                          <div className="w-1/2">
                            <Controller
                              name="address.zip_code"
                              control={control}
                              rules={{ required: 'is required' }}
                              render={({ field, fieldState }) => (
                                <NumberInput
                                  label={
                                    watchAddress?.country === 'US'
                                      ? t('common:zipCode')
                                      : t('common:postalCode')
                                  }
                                  errorMessage={fieldState.error}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <FormFooter
                backLocation={`/invest/${investData?.investment?.id}/account`}
                submitFunc={onSubmit}
                disabled={updateAccountMutation.isLoading || !showAddNew}
              />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default JointAccountScreen
