import { useEffect, useState } from 'react'
import { z } from 'zod'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select-filter'

import { mapParams } from '../../shared/map-params'

const filterSchema = z.object({
  name: z.string(),
  data: z.array(
    z.union([
      z.object({
        name: z.string(),
        uuid: z.string().uuid(),
      }),
      z.number(),
      z.enum(['k1', 'ten_99', 'ten_99_div', 'ten_99_misc']),
    ])
  ),
  updateCallback: z.function().args(z.string(), z.string()).returns(z.void()),
  defaultValue: z.string().nullable(),
})

type FilterTypes = z.infer<typeof filterSchema>

export function Filter({
  name,
  data,
  defaultValue,
  updateCallback,
}: FilterTypes) {
  const [value, setValue] = useState('all')

  const handleFilterSelect = (newValue: string) => {
    setValue(newValue)
    updateCallback(name, newValue)
  }

  useEffect(() => {
    setValue(defaultValue || 'all')
  }, [defaultValue])

  return (
    data && (
      <Select value={value} onValueChange={handleFilterSelect}>
        <SelectTrigger className="w-fit h-[38px]">
          <SelectValue placeholder={`All ${name}`} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="all">
            <span className="capitalize">{`All ${name.split('_').join(' ')}`}</span>
          </SelectItem>
          {data.map((item) => (
            <SelectItem
              value={
                typeof item === 'object'
                  ? item.uuid.toString()
                  : item.toString()
              }
              key={
                typeof item === 'object'
                  ? item.uuid.toString()
                  : item.toString()
              }
            >
              {typeof item === 'object'
                ? item.name.toString()
                : mapParams(item.toString())}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  )
}
