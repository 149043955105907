import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { getEAV, updateVerificationMethod } from '../shared/_api'
import { useInvestDataState } from './_investDataState'

export const useEavDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['eav'],
    queryFn: async () => {
      const data = await getEAV(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData?.investment,
    staleTime: Infinity,
  })
}

export const useMutateEAV = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['eav'],
    mutationFn: async (data: any) => {
      const res = await updateVerificationMethod(
        investData?.investment?.id,
        data
      )
      return res
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
    },
  })
}
