import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import FormFooter from '../../shared/FormFooter'
import MoneyInput from '../../../components/MoneyInput'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ErrorAlert from '../../../components/ErrorAlert'
import { useAmountDataState } from '../_amountDataState'
import {
  moneyMask,
  amountRegex,
  amountRegexDecimal,
  getDecimalPart,
  validateAmountStep,
} from '../../../utils/helpers'
import { setAmount } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'
import Loader from '@/components/Loader'

interface FormType {
  amount: string
}

const PledgeAmountScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { data: amountData } = useAmountDataState()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormType>()

  useEffect(() => {
    if (amountData) {
      reset({ amount: amountData.amount || amountData.amount_minimum })
    }
  }, [investData, nav, amountData, reset])

  const regex = getDecimalPart(amountData?.amount_step)
    ? amountRegexDecimal
    : amountRegex

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    setAmount(investData?.investment.id, {
      amount: Number(String(data.amount).replace(regex, '')),
    }).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['amount'],
      })
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          investment_account: 'submitted',
          amount: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    })
  })

  // const backLocation = `/invest/${investData?.investment?.id}/account`

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={0} currentStep={4} totalStepCount={4} isPledge />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('amount:pledgeAmount')}
          </h1>
          {amountData ? (
            <>
              <div className="mb-6 text-16 space-y-3">
                <Trans i18nKey="pledgeCopy" ns="amount" />
              </div>
              <form onSubmit={onSubmit} className="mb-14 xl:">
                <div className="mb-6">
                  {Object.keys(errors).length > 0 && <ErrorAlert />}
                  <Controller
                    name="amount"
                    control={control}
                    rules={{
                      required: 'is required',
                      validate: {
                        min: (value) => {
                          if (
                            amountData?.amount_minimum &&
                            Number(String(value).replace(regex, '')) <
                              Number(amountData?.amount_minimum)
                          )
                            return t('min', {
                              ns: 'amount',
                              min: `$${moneyMask(amountData?.amount_minimum)}`,
                            })
                        },
                        step: (value) => {
                          const amount = String(value).replace(regex, '')
                          const result = validateAmountStep(
                            amount,
                            amountData?.amount_step
                          )
                          if (result && !result.isValid) {
                            return t('step', {
                              ns: 'amount',
                              step: `$${moneyMask(amountData.amount_step, regex)}`,
                            })
                          }
                        },
                        max: (value) => {
                          if (amountData?.amount_maximum) {
                            if (
                              Number(String(value).replace(regex, '')) >
                              Number(amountData?.amount_maximum)
                            )
                              return t('max', {
                                ns: 'amount',
                                max: `$${moneyMask(amountData?.amount_maximum)}`,
                              })
                          }
                          return true
                        },
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <MoneyInput
                        label="Pledge Amount"
                        placeholder="$"
                        helpText={t('minMax', {
                          ns: 'amount',
                          min: `$${moneyMask(amountData?.amount_minimum)}`,
                          max: amountData?.amount_maximum
                            ? `$${moneyMask(amountData?.amount_maximum)}`
                            : `unlimited`,
                        })}
                        error={fieldState.error}
                        {...field}
                      />
                    )}
                  />
                </div>
                <FormFooter submitFunc={onSubmit} />
              </form>
            </>
          ) : (
            <div className="flex justify-center items-center w-full h-[500px]">
              <Loader />
            </div>
          )}
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default PledgeAmountScreen
