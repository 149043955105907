import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { InvestmentDataTypes, investmentsSchema } from './schema'

export const getInvestments = async () => {
  const response = await api.get('/dashboard/investments')
  const parsedData = investmentsSchema.parse(response)

  const groupedInvestments = parsedData.investments.reduce(
    (acc, investment) => {
      const status = investment.status.toLowerCase() ?? 'unknown'
      if (!acc[status]) {
        acc[status] = []
      }
      acc[status].push(investment)
      return acc
    },
    {} as { [key: string]: InvestmentDataTypes[] }
  )

  return groupedInvestments
}

export const useInvestments = () => {
  return useQuery({
    queryKey: ['investments'],
    queryFn: getInvestments,
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
