import { CompletedInvestmentMenu } from './edit-menu-completed'
import { amountRegexDecimal, moneyMask } from '@/utils/helpers'
import { Badge } from '@/components/ui/badge'
import { format } from 'date-fns/format'
import { InvestmentDataTypes } from '../api/schema'

export const CompletedInvestmentCard = ({
  investment,
}: {
  investment: InvestmentDataTypes
}) => {
  return (
    <div className="w-full flex gap-8 overflow-hidden h-[116px]">
      <div className="w-[15%]">
        <img className="w-full h-full" src={investment.image ?? ''} />
      </div>
      <div className="w-[85%] flex gap-6">
        <div className="flex w-[30%] flex-col h-full gap-1 justify-center">
          <div className="text-[17px] font-medium">{investment.name}</div>

          <div className="text-16">{investment.subhead}</div>
          <div className="text-base text-content-light">
            {investment.property_type}
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <Badge variant="completed">Completed</Badge>
        </div>
        <div className="flex w-[20%] text-base gap-2 flex-col justify-center text-right">
          <div className="font-medium text-16">
            {investment.return_calculated ? (
              <>
                <i className="fa-lg fa-regular fa-chart-line" />
                <div className="inline-block ml-4 min-w-[105px]">
                  {investment.return_calculated}
                </div>
              </>
            ) : (
              <div className="font-normal text-content-light underline">
                Not available
              </div>
            )}
          </div>
          <div className="text-base text-content-light">
            {investment.maturity_date ? (
              <span>
                Maturity date{' '}
                {format(new Date(investment.maturity_date ?? ''), 'MM/dd/yyyy')}
              </span>
            ) : (
              <span className="text-sm whitespace-pre">
                Maturity date not available
              </span>
            )}
          </div>
        </div>
        <div className="flex w-[20%] text-base gap-2 flex-col justify-center text-right">
          <div className="font-medium text-16">
            <i className="fa-lg fa-regular fa-money-check-dollar-pen" />
            <div className="inline-block ml-4 min-w-[105px]">
              ${moneyMask(investment.total_invested ?? '', amountRegexDecimal)}
            </div>
          </div>
          <div className="text-base text-content-light">
            {investment.start_date ? (
              <span>
                Start date{' '}
                {format(new Date(investment.start_date ?? ''), 'MM/dd/yyyy')}
              </span>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </div>
        <div className="flex w-[20%] text-base gap-2 flex-col justify-center text-right">
          <div className="font-medium text-16">
            <i className="fa-lg fa-regular fa-hand-holding-dollar" />
            <div className="inline-block ml-4 min-w-[95px]">
              $
              {moneyMask(
                investment.total_distributions ?? '',
                amountRegexDecimal
              )}
            </div>
          </div>
          <div className="text-base text-content-light whitespace-pre">
            Total Distributions
          </div>
        </div>
        <CompletedInvestmentMenu uuid={investment.io_uuid} />
      </div>
    </div>
  )
}
