import { z } from 'zod'

export const taxesFilterSchema = z.object({
  investments: z.array(
    z.object({
      name: z.string(),
      uuid: z.string().uuid(),
    })
  ),
  investment_accounts: z.array(
    z.object({
      name: z.string(),
      uuid: z.string().uuid(),
    })
  ),
  tax_years: z.array(z.number()),
  document_types: z.array(
    z.enum(['k1', 'ten_99', 'ten_99_div', 'ten_99_misc'])
  ),
})

export type TaxesFilterTypes = z.infer<typeof taxesFilterSchema>

export const taxesInvestmentsSchema = z.object({
  io_uuid: z.string().uuid(),
  io_name: z.string(),
  io_drupal_id: z.number().int(),
})

export const taxDocumentsSchema = z.object({
  uuid: z.string().uuid(),
  investment_account_name: z.string(),
  form_type: z.union([
    z.literal('ten_99'),
    z.literal('ten_99_div'),
    z.literal('k1'),
    z.literal('ten_99_misc'),
  ]),
  tax_year: z.number().int(),
  issuer: z.string(),
  file_name: z.string().nullish(),
  uploaded: z.boolean(),
  document_id: z.number().int().nullish(),
  document_url: z.string().url().nullish(),
  investments: z.array(taxesInvestmentsSchema),
})

export type TaxDocumentType = z.infer<typeof taxDocumentsSchema>

export const taxesDataSchema = z.object({
  count: z.number().int().min(0),
  page: z.number().int().min(0),
  pages: z.number().int().min(0),
  documents: z.array(taxDocumentsSchema),
})

export type TaxesDataTypes = z.infer<typeof taxesDataSchema>
