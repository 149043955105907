import { useRef, createRef } from 'react'
import { Controller, useForm } from 'react-hook-form'

import Radio from '../../../components/Radio'
import ErrorAlert from '../../../components/ErrorAlert'
import FormFooter from '../FormFooter'
import InfoModal from '../../../components/InfoModal'
import Likert from '../../../components/Likert'
import MoneyInput from '../../../components/MoneyInput'
import ISQFollowUp from './ISQFollowUp'

interface ISQFormPropTypes {
  data: any
  step: number | string
  backLocation?: string
  showStep: boolean
  disabled: boolean
  submitFunc: (data: any, shouldCancel: boolean) => Promise<void>
}

const applyOperator = (a: any, b: any, operator: string) => {
  switch (operator) {
    case '=':
      return a === b
    case '!=':
      return a !== b
    case 'in':
      return b.includes(a)
    case 'not in':
      return !b.includes(a)
    case '<':
      return Number(a.replace(/[^\d]/g, '')) < Number(b)
    case '<=':
      return Number(a.replace(/[^\d]/g, '')) <= Number(b)
    case '>':
      return Number(a.replace(/[^\d]/g, '')) > Number(b)
    case '>=':
      return Number(a.replace(/[^\d]/g, '')) >= Number(b)
    default:
      throw new Error(`Unknown operator: ${operator}`)
  }
}

export const ISQForm = (props: ISQFormPropTypes) => {
  const { data, step, submitFunc, showStep = false, disabled = false } = props
  const modalRefs = useRef(
    data?.follow_ups?.map(() => createRef<HTMLDialogElement>())
  )

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<any>()

  const onFormSubmit = handleSubmit((formData: any) => {
    console.log(errors, formData)
    if (Object.values(formData)?.includes('cancel')) {
      submitFunc(formData, true)
      return
    }
    let modalNotOpened = true
    if (data.follow_ups.length > 0) {
      data.follow_ups.forEach((followUp: any, index: number) => {
        if (
          followUp.applies_to.operator === 'and' ||
          followUp.applies_to.operator === 'or'
        ) {
          const foundMatch: boolean[] = []
          followUp.applies_to.items.forEach((appliesTo: any) => {
            const questionAppliesTo = data.questions.find(
              (itemToFind: any) => itemToFind.name === appliesTo.key
            )
            for (const key in formData) {
              if (key === questionAppliesTo.uuid) {
                foundMatch.push(
                  applyOperator(
                    formData[key],
                    appliesTo.value,
                    appliesTo.operator
                  )
                )
              }
            }
          })
          if (
            (followUp.applies_to.operator === 'and' &&
              foundMatch.every((item) => item)) ||
            (followUp.applies_to.operator === 'or' &&
              foundMatch.some((item) => item))
          ) {
            modalNotOpened = false
            modalRefs.current[index].current.showModal()
            return
          }
        } else {
          const questionAppliesTo = data.questions.find(
            (itemToFind: any) => itemToFind.name === followUp.applies_to.key
          )
          for (const key in formData) {
            if (key === questionAppliesTo.uuid) {
              if (
                applyOperator(
                  formData[key],
                  followUp.applies_to.value,
                  followUp.applies_to.operator
                )
              ) {
                modalNotOpened = false
                modalRefs.current[index].current.showModal()
                return
              }
            }
          }
        }
      })
    }
    if (modalNotOpened) {
      submitFunc(formData, false)
      return
    }
  })

  const onFollowUpSubmit = async (data: any, shouldCancel: boolean) =>
    submitFunc({ ...getValues(), ...data }, shouldCancel)

  return (
    showStep && (
      <form onSubmit={onFormSubmit} autoComplete="on" className="mb-14 xl:">
        <p className="mb-6 text-16 text-content-black">{data.header}</p>
        {data.questions.map((question: any, index: number) => (
          <div key={index} className="mb-6">
            <div className="mb-2 text-base">{question.header}</div>
            {question.type === 'radio' && Number(step) !== 1 && (
              <>
                <p className="mb-2 text-16 text-content-black">
                  {question.label}
                  {question.tooltip && (
                    <InfoModal modalContent={question.tooltip} />
                  )}
                </p>
                <Controller
                  name={question.uuid}
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <div
                      className={`flex flex-col gap-px ${
                        fieldState.error && 'border border-alert'
                      }`}
                    >
                      {fieldState.error && (
                        <ErrorAlert text="Please select an answer below:" />
                      )}
                      {question.options.map((option: any) => (
                        <Radio
                          {...field}
                          key={option.key}
                          value={option.key}
                          label={option.value}
                          checked={field.value === option.key}
                        />
                      ))}
                    </div>
                  )}
                />
              </>
            )}
            {question.type === 'radio' && Number(step) === 1 && (
              <>
                <p className="mb-2 text-16 text-content-black">
                  {question.label}
                  {question.tooltip && (
                    <InfoModal modalContent={question.tooltip} />
                  )}
                </p>
                <Controller
                  name={question.uuid}
                  control={control}
                  rules={{ required: 'is required' }}
                  render={({ field, fieldState }) => (
                    <>
                      {fieldState.error && (
                        <ErrorAlert text="Please select an answer below:" />
                      )}
                      <div className="flex flex-row [&>div:first-child>label]:rounded-l [&>div:last-child>label]:rounded-r [&>div:last-child>label]:border-r [&>div>label]:border-b [&>div>label]:border-l [&>div>label]:border-t [&>div>label]:border-input">
                        {question.options.map((option: any) => (
                          <Likert
                            {...field}
                            key={option.name}
                            value={option.key}
                            label={option.value}
                            checked={field.value === option.key}
                          />
                        ))}
                      </div>
                    </>
                  )}
                />
              </>
            )}
            {question.type === 'int' && (
              <Controller
                name={question.uuid}
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <MoneyInput
                    label={
                      <span>
                        {question.label}{' '}
                        {question.tooltip && (
                          <InfoModal modalContent={question.tooltip} />
                        )}
                      </span>
                    }
                    placeholder="$"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />
            )}
          </div>
        ))}
        {data.follow_ups?.length > 0 &&
          data.follow_ups.map((followUp: any, index: number) => (
            <ISQFollowUp
              key={index}
              followUp={followUp}
              ref={modalRefs.current[index]}
              submitCallback={onFollowUpSubmit}
              closeModal={() => modalRefs.current[index].current.close()}
              disabled={disabled}
            />
          ))}
        <FormFooter submitFunc={onFormSubmit} disabled={disabled} />
      </form>
    )
  )
}

export default ISQForm
