import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { z } from 'zod'

const unreadMessagesSchema = z.object({
  unread_count: z.number(),
})

const getUnreadMessages = async () => {
  const response = await api.get('/dashboard/messages/unread')
  return unreadMessagesSchema.parse(response)
}

export const useUnreadDataQuery = () => {
  const { data } = useAuthDataState()

  return useQuery<number>({
    queryKey: ['unreadMessages'],
    queryFn: async () => {
      const data = await getUnreadMessages()
      return data.unread_count
    },
    staleTime: Infinity,
    enabled: !!data,
  })
}
