import ErrorAlert from './index'
import { useTranslation } from 'react-i18next'

interface ServerErrorProps {
  serverError: string
  id: string
}

const ServerError = (props: ServerErrorProps) => {
  const { serverError, id } = props
  const { t } = useTranslation()
  return (
    <ErrorAlert
      text={
        <span>
          {t('common:serverError')}
          <a
            href={`mailto:investor-help@realtymogul.com&subject=Error: ${serverError} - Investment ${id}`}
            className="text-primary"
          >
            investor-help@realtymogul.com
          </a>
        </span>
      }
    />
  )
}

export default ServerError
