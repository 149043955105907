import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from '@tanstack/react-query'
import { isBefore, isEqual, isValid, parse } from 'date-fns'

import { useInvestDataState } from '../_investDataState'
import { update1031 } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'

import DateInput from '../../../components/DateInput'
import FormFooter from '../../shared/FormFooter'
import Radio from '../../../components/Radio'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'

interface AccountFormTypes {
  has_accomodator: string
  sale_date?: string
  exchange_details?: string
}

const TenThirtyOneInfoScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const [serverError, setServerError] = useState('')

  const { data: investData } = useInvestDataState()

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountFormTypes>({
    defaultValues: {
      has_accomodator: 'yes',
    },
  })

  // const backLocation = `/invest/${investData?.investment.id}/amount/pp`

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    if (data.has_accomodator === 'yes') {
      nav(`/invest/${investData.opportunity.id}/1031/accommodator`, {
        state: {
          exchange_details: data.exchange_details,
          sale_date:
            data.sale_date &&
            new Date(data.sale_date).toISOString().split('T')[0],
        },
      })
      return
    }
    update1031(investData?.investment?.id, {
      investment_uuid: investData?.investment?.id,
      exchange_details: data.exchange_details,
      sale_date:
        data.sale_date && new Date(data.sale_date).toISOString().split('T')[0],
      has_accomodator: false,
      funded_by_1031: true,
    })
      .then(() => {
        queryClient.invalidateQueries(['1031'])
        queryClient.invalidateQueries(['invest'])
        findRoute({
          ...investData,
          investment_state: {
            ...investData.investment_state,
            step_1031: 'submitted',
          },
        }).then((route) => {
          nav(`/invest/${investData.opportunity.id}/${route.type}`)
        })
      })
      .catch((error) => {
        console.error(error.message)
        setServerError(error.message)
      })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={0} currentStep={4} totalStepCount={4} isPledge />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-6 text-2xl font-bold text-content-black">
            {t('tenThirtyOne:header')}
          </h1>
          <p className="mb-6 text-16 text-content-black">
            {t('tenThirtyOne:exchangeDetails')}
          </p>
          <form onSubmit={onSubmit}>
            <p className="mb-6 text-[19px] font-medium">
              {t('tenThirtyOne:propertyDetails')}
            </p>
            <div className="mb-6">
              <Controller
                name="sale_date"
                control={control}
                rules={{
                  validate: {
                    isValidDate: (value) => {
                      if (!value) {
                        return true
                      }
                      return (
                        isValid(parse(value || '', 'MM/dd/yyyy', new Date())) ||
                        'is not a valid date'
                      )
                    },
                    isTodayOrEarlier: (value) => {
                      if (!value) {
                        return true
                      }
                      const inputDate = parse(value, 'MM/dd/yyyy', new Date())
                      const today = new Date()
                      return (
                        isBefore(inputDate, today) ||
                        isEqual(inputDate, today) ||
                        'Date must not be in the future.'
                      )
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <DateInput
                    label="Sale Date for the Original Property"
                    placeholder="mm/dd/yyyy"
                    errorMessage={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-6">
              <span className="mb-2 block text-base font-normal text-content-black">
                {t('tenThirtyOne:additionalDetails')}
              </span>
              <textarea
                className="h-[100px] w-full rounded border border-input p-3"
                placeholder="Tell us a little more about your exchange."
                {...register('exchange_details')}
              />
            </div>
            <p className="mb-6 text-[19px] font-medium">
              {t('tenThirtyOne:accommodatorInfo')}
            </p>
            <div className="mb-6">
              <p className="mb-6 text-16 text-content-black">
                {t('tenThirtyOne:accommodatorInfoCopy')}
              </p>
              <Controller
                name="has_accomodator"
                control={control}
                render={({ field, fieldState }) => (
                  <div
                    className={`flex flex-col gap-px ${
                      fieldState.error && 'border border-alert'
                    }`}
                  >
                    <Radio
                      {...field}
                      value="yes"
                      label="Yes"
                      checked={field.value === 'yes'}
                    />
                    <Radio
                      {...field}
                      value="no"
                      label="No"
                      checked={field.value === 'no'}
                    />
                  </div>
                )}
              />
            </div>
            <FormFooter submitFunc={onSubmit} />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData.investment?.id}
              />
            )}
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default TenThirtyOneInfoScreen
