import { useState, useEffect } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/react-hook-form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useTokensDataState } from '@/routes/shared/_tokensDataState'
import { useAccountDataState } from '../_accountDataState'
import { AccountTypes, EntityAccountTypes } from '../../shared/_types'
import {
  addAccount,
  getSignedS3Url,
  updateAccount,
  uploadToSignedS3,
} from '../../shared/_api'

import Checkbox from '../../../components/Checkbox'
import CountryInput from '../../../components/CountryInput'
import FileUpload from '../../../components/FileUpload'
import FormFooter from '../../shared/FormFooter'
import InfoModal from '../../../components/InfoModal'
import NumberInput from '../../../components/NumberInput'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import TextInput from '../../../components/TextInput'
import TINInput from '../../../components/TINInput'
import StateDropdown from '../../../components/StateDropdown'
import ServerError from '../../../components/ErrorAlert/ServerError'
import ErrorAlert from '../../../components/ErrorAlert'
import { findRoute } from '../../../rules/findRoute'

const EntityAccountScreen = () => {
  const [showAddNew, setShowAddNew] = useState(false)
  const [entityAccount, setEntityAccount] = useState('')
  const [lockFields, setLockFields] = useState(false)
  const [use_primary_investor_contact, setUse_primary_investor_contact] =
    useState(false)
  const [documentsLater, setDocumentsLater] = useState(false)
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { data: tokenData } = useTokensDataState()
  const { data: accountData } = useAccountDataState()

  const { t } = useTranslation()
  const {
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<EntityAccountTypes>({
    defaultValues: {
      type: 'foe_entity',
      subtype: 'single_llc',
      is_disregarded_entity: false,
      jurisdiction_of_registration: 'AL',
      address: {
        country: 'US',
        state: 'AL',
      },
      use_primary_investor_contact: false,
    },
  })

  const watchSubEntityType = useWatch({ control, name: 'subtype' })
  const watchAddress = useWatch({ control, name: 'address' })

  useEffect(() => {
    if (
      watchSubEntityType !== 'single_llc' &&
      watchSubEntityType !== 's_corp'
    ) {
      setValue('is_disregarded_entity', null)
    }
    if (watchSubEntityType !== 'multi_llc') {
      setValue('number_of_members', '')
    }
  }, [watchSubEntityType, setValue])

  useEffect(() => {
    if (!accountData) return
    if (entityAccount === 'addNew') {
      setLockFields(false)
    } else {
      setLockFields(
        !!accountData?.ia_options?.find((account: EntityAccountTypes) => {
          return account.uuid === entityAccount
        })?.has_an_approved_investment
      )
    }
  }, [entityAccount, accountData])

  useEffect(() => {
    if (
      !accountData?.ia_options ||
      entityAccount === '' ||
      updateAccountMutation.isLoading
    )
      return
    if (entityAccount !== 'addNew') {
      const matchedEntityAccount = accountData?.ia_options?.find(
        (account: EntityAccountTypes) => {
          return account.uuid === entityAccount
        }
      )

      if (matchedEntityAccount) {
        const cleanedEntityAccount = Object.fromEntries(
          Object.entries(matchedEntityAccount).filter(
            ([_key, value]) => value !== null
          )
        )
        setUse_primary_investor_contact(
          !!cleanedEntityAccount.use_primary_investor_contact
        )
        reset({
          ...cleanedEntityAccount,
        })
      }
    } else {
      reset({
        address: {
          city: '',
          country: 'US',
          state: 'AL',
          street: '',
          street2: '',
          zip_code: '',
        },
        certificate_of_formation_uuid: '',
        is_disregarded_entity: false,
        jurisdiction_of_registration: 'AL',
        name: '',
        number_of_members: '',
        operating_agreement_uuid: '',
        phone_number: '',
        subtype: 'single_llc',
        tin: '',
        title: '',
        use_primary_investor_contact: false,
      })
      setUse_primary_investor_contact(false)
    }
    setShowAddNew(true)
  }, [entityAccount, accountData, reset])

  // const backLocation = `/invest/${investData?.investment?.id}/account`
  const updateAccountMutation = useMutation({
    mutationFn: (data: EntityAccountTypes) => {
      if (use_primary_investor_contact) {
        delete data.address
      }
      const newData = {
        ...Object.fromEntries(
          Object.entries(data).filter(([_key, value]) => value !== '')
        ),
        type: 'foe_entity',
        number_of_members: parseInt(data.number_of_members) || null,
      }

      return entityAccount !== 'addNew'
        ? updateAccount(investData?.investment?.id, entityAccount, newData)
        : addAccount(investData?.investment?.id, newData)
    },
    onMutate: async (newEntityAccount: EntityAccountTypes) => {
      await queryClient.cancelQueries({
        queryKey: ['account', 'invest'],
      })
      const previousAccountData = queryClient.getQueryData(['account'])
      queryClient.setQueryData<AccountTypes>(['account'], (oldData) => {
        return { ...oldData, current_account: { ...newEntityAccount } }
      })
      return { previousAccountData }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
      queryClient.invalidateQueries({
        queryKey: ['account'],
      })
      if (
        tokenData?.state_allowlist &&
        !JSON.parse(tokenData?.state_allowlist).includes(
          data.jurisdiction_of_registration
        )
      ) {
        nav(`/invest/${investData.opportunity.id}/account/failed`)
        return
      }
      findRoute({
        ...investData,
        investment_state: {
          ...investData?.investment_state,
          investment_account: 'submitted',
        },
      }).then((route) => {
        nav(`/invest/${investData.opportunity.id}/${route.type}`)
      })
    },
    onError: (error: any, _variables: any, context) => {
      queryClient.setQueryData(['account'], context?.previousAccountData)
      console.error(error.message)
      setServerError(error.message)
    },
  })

  const onSubmit = handleSubmit((data) => {
    updateAccountMutation.mutateAsync(data)
  })

  return (
    accountData && (
      <div className="p-6 xl:p-0">
        {investData.opportunity.io_type === 'reit' ? (
          <Stepper currentSection={0} currentStep={3} totalStepCount={3} />
        ) : (
          <Stepper
            currentSection={0}
            currentStep={3}
            totalStepCount={4}
            isPledge={
              investData.opportunity.status.toLowerCase() ===
              'open for pledging'
            }
          />
        )}
        <div className="flex gap-[72px]">
          <div className="w-full">
            <h1 className="mb-2 text-2xl font-bold text-content-black">
              {t('common:ownershipType')}
            </h1>
            <p className="mb-6 italic text-content-black">
              {t('common:entity')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {t('account:enterEntity')}
            </p>
            {Object.keys(errors).length > 0 && <ErrorAlert />}
            <form onSubmit={onSubmit}>
              <div className="mb-5">
                <p className="mb-2 text-16 text-content-black">
                  {t('account:selectEntity')}
                </p>
                <Controller
                  name="entityAccount"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="mb-6">
                      <div
                        className={`flex flex-col gap-px ${
                          fieldState.error && 'border border-alert'
                        }`}
                      >
                        {accountData?.ia_options &&
                          accountData?.ia_options.map(
                            (account: EntityAccountTypes, index: number) =>
                              account.type === 'foe_entity' && (
                                <Radio
                                  key={index}
                                  {...field}
                                  value={account.uuid}
                                  label={account.name + ' - ' + account.title}
                                  onChange={() => {
                                    field.onChange(account.uuid)
                                    setEntityAccount(account.uuid)
                                  }}
                                />
                              )
                          )}
                        <Radio
                          {...field}
                          value="addNew"
                          label={t('account:addNewEntity')}
                          onChange={() => {
                            field.onChange('addNew')
                            setEntityAccount('addNew')
                          }}
                        />
                      </div>
                      {fieldState.error && (
                        <span className="text-xs text-alert">
                          {fieldState.error.message}
                        </span>
                      )}
                    </div>
                  )}
                />
                {showAddNew && (
                  <div>
                    <p className="mb-6 text-[19px] font-medium">
                      {t('common:entity')}
                    </p>
                    <div className="mb-6 flex flex-col gap-3">
                      <Controller
                        name="name"
                        control={control}
                        disabled={lockFields}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={t('account:nameEntity')}
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="title"
                        control={control}
                        disabled={lockFields}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <TextInput
                            label={t('account:titleEntity')}
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-6">
                      <span
                        className={`mb-2 block text-base font-normal ${
                          errors?.type?.message
                            ? 'text-alert'
                            : 'text-content-black'
                        }`}
                      >
                        {t('account:entityType')}
                      </span>
                      <select
                        className={`w-full appearance-none border ${
                          errors?.type?.message
                            ? 'border-alert'
                            : 'border-input'
                        } bg-white bg-down-chevron bg-[center_right_12px] bg-no-repeat px-3 py-[10px] text-[15px] text-content-black disabled:opacity-50`}
                        {...register('subtype')}
                        disabled={lockFields}
                      >
                        <option value="single_llc">Single Member LLC</option>
                        <option value="multi_llc">Multi Member LLC</option>
                        <option value="s_corp">S Corp</option>
                        <option value="c_corp">C Corp</option>
                        <option value="partnership">Limited Partnership</option>
                      </select>
                    </div>
                    {watchSubEntityType === 'multi_llc' && (
                      <div className="mb-6">
                        <Controller
                          name="number_of_members"
                          control={control}
                          rules={{ required: 'is required' }}
                          render={({ field, fieldState }) => (
                            <NumberInput
                              label={t('account:numberOfMembers')}
                              errorMessage={fieldState.error}
                              {...field}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div className="mb-6">
                      <Controller
                        name="tin"
                        control={control}
                        disabled={lockFields}
                        rules={{
                          required: 'is required',
                          validate: {
                            isLongEnough: (value) => {
                              const tin = value.replace(/-/g, '')
                              return tin.length === 9 || 'is not a valid TIN'
                            },
                          },
                        }}
                        render={({ field, fieldState }) => (
                          <TINInput
                            label={
                              <span>
                                {t('account:tinLabel')}{' '}
                                <InfoModal
                                  modalContent={
                                    <div className="md:px-8 md:pt-4 px-6">
                                      <h2 className="mb-6 text-xl font-medium text-content-black">
                                        {t('account:tinLabel')}
                                      </h2>
                                      <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                        {t('account:tinModalEntity')}
                                      </p>
                                    </div>
                                  }
                                />
                              </span>
                            }
                            disabled={lockFields}
                            placeholder="__-_______"
                            errorMessage={fieldState.error}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    {(watchSubEntityType === 'single_llc' ||
                      watchSubEntityType === 's_corp') && (
                      <div className="mb-6">
                        <Controller
                          name="is_disregarded_entity"
                          control={control}
                          disabled={lockFields}
                          render={({ field, fieldState }) => (
                            <div
                              className={
                                field.disabled
                                  ? 'pointer-events-none opacity-50'
                                  : ''
                              }
                            >
                              <Checkbox
                                label={
                                  <span>
                                    {t('account:isDisregardedEntity')}{' '}
                                    <InfoModal
                                      modalContent={
                                        <div className="md:px-8 md:pt-4 px-6">
                                          <h2 className="mb-6 text-xl font-medium text-content-black">
                                            {t('account:disregardedEntity')}
                                          </h2>
                                          <p className="font-sans text-16 font-normal text-content-black">
                                            <Trans i18nKey="account:deModal" />
                                            <br />
                                            <br />
                                            <Trans i18nKey="account:realEstateAdviceDisclaimer" />
                                          </p>
                                        </div>
                                      }
                                    />
                                  </span>
                                }
                                checked={field.value === true}
                                {...field}
                                errorMessage={fieldState.error}
                              />
                            </div>
                          )}
                        />
                      </div>
                    )}
                    <div className="mb-6">
                      <Controller
                        name="jurisdiction_of_registration"
                        control={control}
                        rules={{ required: 'is required' }}
                        render={({ field, fieldState }) => (
                          <StateDropdown
                            errorMessage={fieldState.error}
                            label={t('account:jurisdictionOfRegistration')}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div className="mb-6">
                      <Controller
                        name="use_primary_investor_contact"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            {...field}
                            onChange={() => {
                              field.onChange(!field.value)
                              setUse_primary_investor_contact(
                                !use_primary_investor_contact
                              )
                            }}
                            checked={field.value === true}
                            errorMessage={fieldState.error}
                            label={t('account:sameAddressEntity')}
                          />
                        )}
                      />
                    </div>
                    {!use_primary_investor_contact && (
                      <div>
                        <div className="mb-6">
                          <span
                            className={`mb-2 block text-base font-normal ${
                              errors?.phone_number?.message
                                ? 'text-alert'
                                : 'text-content-black'
                            }`}
                          >
                            {t('common:phoneNumber')}
                          </span>
                          <PhoneInput
                            name="phone_number"
                            className={`phone-input-wrapper mb-2 h-[42px] w-full rounded border ${
                              errors?.phone_number
                                ? 'border-alert'
                                : 'border-input'
                            } px-3 py-1.5 text-16 focus-within:outline-none focus-within:ring-1 focus-within:border-primary`}
                            defaultCountry="US"
                            countryOptionsOrder={['US']}
                            control={control}
                            initialValueFormat="national"
                            limitMaxLength={true}
                            rules={{
                              required: 'is required',
                              validate: {
                                isValidPhoneNumber: (value: string) =>
                                  isValidPhoneNumber(value) ||
                                  'Please enter a valid phone number.',
                              },
                            }}
                          />
                          {errors?.phone_number?.message && (
                            <p className="mb-1 mt-2 text-xs text-alert">
                              {errors?.phone_number?.message}
                            </p>
                          )}
                        </div>
                        <div className="mb-6">
                          <CountryInput
                            register={register('address.country')}
                            errorMessage={errors?.address?.country?.message}
                          />
                        </div>
                        <div className="mb-2">
                          <Controller
                            name="address.street"
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field, fieldState }) => (
                              <TextInput
                                label={t('common:address')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="address.street2"
                            control={control}
                            render={({ field, fieldState }) => (
                              <TextInput
                                placeholder={t('common:address2')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="address.city"
                            control={control}
                            rules={{ required: 'is required' }}
                            render={({ field, fieldState }) => (
                              <TextInput
                                label={t('common:city')}
                                errorMessage={fieldState.error}
                                {...field}
                              />
                            )}
                          />
                        </div>
                        <div className="mb-6 flex gap-3">
                          <div className="w-1/2">
                            <Controller
                              name="address.state"
                              control={control}
                              rules={{
                                required:
                                  watchAddress?.country === 'US'
                                    ? 'is required'
                                    : false,
                              }}
                              render={({ field, fieldState }) =>
                                watchAddress?.country === 'US' ? (
                                  <StateDropdown
                                    errorMessage={fieldState?.error?.message}
                                    label={t('common:state')}
                                    {...field}
                                  />
                                ) : (
                                  <TextInput
                                    label={
                                      watchAddress?.country === 'US'
                                        ? t('common:state')
                                        : t('common:stateProvinceRegion')
                                    }
                                    errorMessage={fieldState?.error?.message}
                                    {...field}
                                  />
                                )
                              }
                            />
                          </div>
                          <div className="w-1/2">
                            <Controller
                              name="address.zip_code"
                              control={control}
                              rules={{ required: 'is required' }}
                              render={({ field, fieldState }) => (
                                <TextInput
                                  label={
                                    watchAddress?.country === 'US'
                                      ? t('common:zipCode')
                                      : t('common:postalCode')
                                  }
                                  errorMessage={fieldState.error}
                                  {...field}
                                />
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mb-6 text-[19px] font-medium">
                      {t('common:documents')}
                    </div>
                    {!documentsLater && (
                      <>
                        <div className="mb-6 text-16 font-medium">
                          {t('account:certificateOfFormation')}
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="certificate_of_formation_uuid"
                            control={control}
                            rules={{ required: 'File is required' }}
                            render={({ field }) => (
                              <FileUpload
                                {...field}
                                accept=".pdf"
                                label={t('common:uploadDocument')}
                                uploadFileFunction={async (file: File) => {
                                  const signedS3Resp = await getSignedS3Url({
                                    fileName: file.name,
                                    type: 'certificate_of_formation',
                                  })
                                  if (signedS3Resp.status === 200) {
                                    const { fields, url, s3_obj_id } =
                                      signedS3Resp.data
                                    const uploadResp = await uploadToSignedS3(
                                      file,
                                      url,
                                      fields
                                    )
                                    console.log(uploadResp)
                                    setValue(
                                      'certificate_of_formation_uuid',
                                      s3_obj_id
                                    )
                                  }
                                }}
                              />
                            )}
                          />
                          {errors?.certificate_of_formation_uuid?.message && (
                            <span className="mb-1 text-xs text-alert">
                              {errors.certificate_of_formation_uuid.message}
                            </span>
                          )}
                          <p className="mt-4 text-center text-[13px]">
                            {t('common:pdfOnly')}
                          </p>
                        </div>
                        <div className="mb-6 text-16 font-medium">
                          {t('account:operatingAgreement')}
                        </div>
                        <div className="mb-6">
                          <Controller
                            name="operating_agreement_uuid"
                            control={control}
                            rules={{ required: 'File is required' }}
                            render={({ field }) => (
                              <FileUpload
                                {...field}
                                accept=".pdf"
                                label={t('common:uploadDocument')}
                                uploadFileFunction={async (file: File) => {
                                  const signedS3Resp = await getSignedS3Url({
                                    fileName: file.name,
                                    type: 'operating_agreement',
                                  })
                                  if (signedS3Resp.status === 200) {
                                    const { fields, url, s3_obj_id } =
                                      signedS3Resp.data
                                    const uploadResp = await uploadToSignedS3(
                                      file,
                                      url,
                                      fields
                                    )
                                    console.log(uploadResp)
                                    setValue(
                                      'operating_agreement_uuid',
                                      s3_obj_id
                                    )
                                  }
                                }}
                              />
                            )}
                          />
                          {errors?.operating_agreement_uuid?.message && (
                            <span className="mb-1 text-xs text-alert">
                              {errors.operating_agreement_uuid.message}
                            </span>
                          )}
                          <p className="mt-4 text-center text-[13px]">
                            {t('common:pdfOnly')}
                          </p>
                        </div>
                      </>
                    )}
                    <div className="mb-6">
                      <Checkbox
                        name="documentsLater"
                        onChange={() => {
                          setDocumentsLater(!documentsLater)
                          setValue('operating_agreement_uuid', '')
                          setValue('certificate_of_formation_uuid', '')
                        }}
                        label={t('account:entityFilesLater')}
                      />
                    </div>
                  </div>
                )}
              </div>
              <FormFooter
                backLocation={`/invest/${investData?.investment?.id}/account`}
                submitFunc={onSubmit}
                disabled={updateAccountMutation.isLoading || !showAddNew}
              />
              {serverError && (
                <ServerError
                  serverError={serverError}
                  id={investData?.investment?.id}
                />
              )}
            </form>
          </div>
          <div className="hidden xl:block">
            <Sidebar />
          </div>
        </div>
      </div>
    )
  )
}

export default EntityAccountScreen
