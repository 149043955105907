import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'

import { useInvestDataState } from '../_investDataState'
import { useMutateEAV } from '../_eavDataState'
import FormFooter from '../../shared/FormFooter'
import Stepper from '../../../components/Stepper'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import { useTranslation } from 'react-i18next'

interface VerificationFormTypes {
  verificationMethod: string
}

const VerificationScreen = () => {
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { isLoading, mutateAsync: updateEAV } = useMutateEAV()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<VerificationFormTypes>({
    defaultValues: {
      verificationMethod:
        investData?.verification?.verificationMethod || 'thirdParty',
    },
  })

  // const backLocation = investData.investment.status === 'pledge'
  //   ? `/invest/${investData?.investment.id}/pledgecomplete`
  //   : `/invest/${investData?.investment.id}/funding`

  const onSubmit = handleSubmit((data: any) => {
    console.log(errors, data)
    switch (data.verificationMethod) {
      case 'thirdParty':
        updateEAV({ verification_type: 'third_party' }).then(() =>
          nav(`/invest/${investData.opportunity.id}/verification/thirdparty`)
        )
        break
      case 'income':
        updateEAV({ verification_type: 'income' }).then(() =>
          nav(`/invest/${investData.opportunity.id}/verification/income`)
        )
        break
      case 'netWorth':
        updateEAV({ verification_type: 'net_worth' }).then(() =>
          nav(`/invest/${investData.opportunity.id}/verification/networth`)
        )
    }
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={2} currentStep={3} totalStepCount={5} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('verification:eav')}
          </h1>
          <p className="mb-6 text-base italic text-content-light"></p>
          <p className="mb-6 text-16 text-content-black">
            {t('verification:eavCopy')}
          </p>
          <p className="mb-6 text-xl font-medium">
            {t('verification:verificationMethod')}
          </p>
          <p className="mb-6 text-16 text-content-black">
            {t('verification:verificationMethodCopy')}
          </p>
          <form onSubmit={onSubmit} className="xl:">
            <div className="mb-5">
              <p className="mb-2 text-16 text-content-black">
                {t('verification:preferredMethod')}
              </p>
              <Controller
                name="verificationMethod"
                control={control}
                render={({ field, fieldState }) => (
                  <div
                    className={`flex flex-col gap-px ${
                      fieldState.error ? 'border-alert' : ''
                    }`}
                  >
                    <Radio
                      {...field}
                      value="thirdParty"
                      label="Authorized Third Party"
                      checked={field.value === 'thirdParty'}
                    />
                    <Radio
                      {...field}
                      value="income"
                      label="Income"
                      checked={field.value === 'income'}
                    />
                    <Radio
                      {...field}
                      value="netWorth"
                      label="Net Worth"
                      checked={field.value === 'netWorth'}
                    />
                  </div>
                )}
              />
            </div>
            <FormFooter submitFunc={onSubmit} disabled={isLoading} />
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default VerificationScreen
