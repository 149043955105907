import { useQuery } from '@tanstack/react-query'

import { api } from '@/routes/shared/_api'
import { taxesFilterSchema, TaxesFilterTypes } from './schema'

export const getTaxesFilters = async () => {
  const response = await api.get('/dashboard/tax-documents/filters')
  return taxesFilterSchema.parse(response)
}

export const useTaxesFilters = () => {
  return useQuery<TaxesFilterTypes>({
    queryKey: ['taxes-filters'],
    queryFn: getTaxesFilters,
    staleTime: Infinity,
  })
}
