export const getIcon = (iconType: string) => {
  switch (iconType) {
    case 'individual':
      return (
        <svg
          width="16"
          height="19"
          viewBox="0 0 16 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 9.82129C5.50391 9.82129 3.5 7.81738 3.5 5.32129C3.5 2.86035 5.50391 0.821289 8 0.821289C10.4609 0.821289 12.5 2.86035 12.5 5.32129C12.5 7.81738 10.4609 9.82129 8 9.82129ZM8 1.94629C6.13672 1.94629 4.625 3.49316 4.625 5.32129C4.625 7.18457 6.13672 8.69629 8 8.69629C9.82812 8.69629 11.375 7.18457 11.375 5.32129C11.375 3.49316 9.82812 1.94629 8 1.94629ZM9.75781 11.5088C13.1328 11.5088 15.875 14.251 15.875 17.626C15.875 18.2939 15.3125 18.8213 14.6445 18.8213H1.32031C0.652344 18.8213 0.125 18.2939 0.125 17.626C0.125 14.251 2.83203 11.5088 6.20703 11.5088H9.75781ZM14.6445 17.6963C14.6797 17.6963 14.75 17.6611 14.75 17.626C14.75 14.8838 12.5 12.6338 9.75781 12.6338H6.20703C3.46484 12.6338 1.25 14.8838 1.25 17.626C1.25 17.6611 1.28516 17.6963 1.32031 17.6963H14.6445Z" />
        </svg>
      )

    case 'joint_ownership':
      return (
        <svg
          width="20"
          height="17"
          viewBox="0 0 20 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.5625 10.0713C11.5625 10.0713 14 12.5088 14 15.5088C14 16.1025 13.5 16.5713 12.9062 16.5713H1.0625C0.46875 16.5713 0 16.1025 0 15.5088C0 12.5088 2.40625 10.0713 5.40625 10.0713H8.5625ZM12.9062 15.5713C12.9375 15.5713 13 15.54 13 15.5088C13 13.0713 11 11.0713 8.5625 11.0713H5.40625C2.96875 11.0713 1 13.0713 1 15.5088C1 15.54 1.03125 15.5713 1.0625 15.5713H12.9062ZM7 8.57129C4.78125 8.57129 3 6.79004 3 4.57129C3 2.38379 4.78125 0.571289 7 0.571289C9.1875 0.571289 11 2.38379 11 4.57129C11 6.79004 9.1875 8.57129 7 8.57129ZM7 1.57129C5.34375 1.57129 4 2.94629 4 4.57129C4 6.22754 5.34375 7.57129 7 7.57129C8.625 7.57129 10 6.22754 10 4.57129C10 2.94629 8.625 1.57129 7 1.57129ZM11.7188 8.10254C11.5 7.97754 11.4062 7.66504 11.5625 7.44629C11.6875 7.19629 12 7.10254 12.25 7.25879C12.625 7.47754 13.0312 7.57129 13.5 7.57129C14.875 7.57129 16 6.47754 16 5.07129C16 3.69629 14.875 2.57129 13.5 2.57129C13.1875 2.57129 12.9062 2.63379 12.6562 2.72754C12.4062 2.82129 12.0938 2.69629 12 2.41504C11.9062 2.16504 12.0625 1.88379 12.3125 1.79004C12.6875 1.66504 13.0938 1.57129 13.5 1.57129C15.4062 1.57129 17 3.16504 17 5.07129C17 7.00879 15.4062 8.57129 13.5 8.57129C12.875 8.57129 12.25 8.41504 11.7188 8.10254ZM15.3125 10.5713C17.9062 10.5713 20 12.665 20 15.2588C20 15.9775 19.375 16.5713 18.6562 16.5713H15.5C15.2188 16.5713 15 16.3525 15 16.0713C15 15.8213 15.2188 15.5713 15.5 15.5713H18.6562C18.8438 15.5713 19 15.4463 19 15.2588C19 13.2275 17.3438 11.5713 15.3125 11.5713H14C13.7188 11.5713 13.5 11.3525 13.5 11.0713C13.5 10.8213 13.7188 10.5713 14 10.5713H15.3125Z" />
        </svg>
      )

    case 'foe_entity':
      return (
        <svg
          width="20"
          height="17"
          viewBox="0 0 20 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 3.57129C17.0938 3.57129 18 4.47754 18 5.57129V13.5713C18 14.6963 17.0938 15.5713 16 15.5713H4C2.875 15.5713 2 14.6963 2 13.5713V5.57129C2 4.47754 2.875 3.57129 4 3.57129H6V2.07129C6 1.25879 6.65625 0.571289 7.5 0.571289H12.5C13.3125 0.571289 14 1.25879 14 2.07129V3.57129H16ZM7 2.07129V3.57129H13V2.07129C13 1.82129 12.75 1.57129 12.5 1.57129H7.5C7.21875 1.57129 7 1.82129 7 2.07129ZM17 13.5713V9.57129H12.5V11.5713C12.5 11.8525 12.25 12.0713 12 12.0713H8C7.71875 12.0713 7.5 11.8525 7.5 11.5713V9.57129H3V13.5713C3 14.1338 3.4375 14.5713 4 14.5713H16C16.5312 14.5713 17 14.1338 17 13.5713ZM8.5 11.0713H11.5V9.57129H8.5V11.0713ZM17 8.57129V5.57129C17 5.04004 16.5312 4.57129 16 4.57129H4C3.4375 4.57129 3 5.04004 3 5.57129V8.57129H17Z" />
        </svg>
      )

    case 'chart-line':
      return (
        <svg
          width="18"
          height="17"
          viewBox="0 0 18 17"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.125 13.8838C1.125 14.833 1.86328 15.5713 2.8125 15.5713H17.4375C17.7188 15.5713 18 15.8525 18 16.1338C18 16.4502 17.7188 16.6963 17.4375 16.6963H2.8125C1.23047 16.6963 0 15.4658 0 13.8838V1.50879C0 1.22754 0.246094 0.946289 0.5625 0.946289C0.84375 0.946289 1.125 1.22754 1.125 1.50879V13.8838ZM11.6367 10.3682C11.4258 10.5791 11.0391 10.5791 10.8281 10.3682L7.83984 7.37988L4.32422 10.9307C4.11328 11.1416 3.72656 11.1416 3.51562 10.9307C3.30469 10.7197 3.30469 10.333 3.51562 10.1221L7.45312 6.18457C7.66406 5.97363 8.05078 5.97363 8.26172 6.18457L11.25 9.17285L15.8906 4.49707C16.1016 4.28613 16.4883 4.28613 16.6992 4.49707C16.9102 4.70801 16.9102 5.09473 16.6992 5.30566L11.6367 10.3682Z" />
        </svg>
      )

    case 'envelope':
      return (
        <svg
          width="18"
          height="14"
          viewBox="0 0 18 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2.25 1.19629C1.61719 1.19629 1.125 1.72363 1.125 2.32129V3.72754L7.98047 8.79004C8.57812 9.21191 9.38672 9.21191 9.98438 8.79004L16.875 3.72754V2.32129C16.875 1.72363 16.3477 1.19629 15.75 1.19629H2.25ZM1.125 5.13379V11.3213C1.125 11.9541 1.61719 12.4463 2.25 12.4463H15.75C16.3477 12.4463 16.875 11.9541 16.875 11.3213V5.13379L10.6523 9.7041C9.66797 10.4072 8.29688 10.4072 7.3125 9.7041L1.125 5.13379ZM0 2.32129C0 1.09082 0.984375 0.0712891 2.25 0.0712891H15.75C16.9805 0.0712891 18 1.09082 18 2.32129V11.3213C18 12.5869 16.9805 13.5713 15.75 13.5713H2.25C0.984375 13.5713 0 12.5869 0 11.3213V2.32129Z" />
        </svg>
      )

    case 'files':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.9375 20.6963C16.2188 20.6963 16.5 20.9775 16.5 21.2588C16.5 21.5752 16.2188 21.8213 15.9375 21.8213H7.5C5.60156 21.8213 4.125 20.3447 4.125 18.4463V7.75879C4.125 7.47754 4.37109 7.19629 4.6875 7.19629C4.96875 7.19629 5.25 7.47754 5.25 7.75879V18.4463C5.25 19.7119 6.23438 20.6963 7.5 20.6963H15.9375ZM19.207 7.8291C19.6289 8.25098 19.875 8.81348 19.875 9.41113V16.1963C19.875 17.4619 18.8555 18.4463 17.625 18.4463H9.75C8.48438 18.4463 7.5 17.4619 7.5 16.1963V6.07129C7.5 4.84082 8.48438 3.82129 9.75 3.82129H14.2852C14.8828 3.82129 15.4453 4.06738 15.8672 4.48926L19.207 7.8291ZM15.375 5.5791V7.75879C15.375 8.0752 15.6211 8.32129 15.9375 8.32129H18.1172L15.375 5.5791ZM18.75 16.1963V9.44629H15.9375C14.9883 9.44629 14.25 8.70801 14.25 7.75879V4.94629H9.75C9.11719 4.94629 8.625 5.47363 8.625 6.07129V16.1963C8.625 16.8291 9.11719 17.3213 9.75 17.3213H17.625C18.2227 17.3213 18.75 16.8291 18.75 16.1963Z" />
        </svg>
      )

    case 'rectangle-list':
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.375 10.29C5.88281 10.29 5.53125 9.93848 5.53125 9.44629C5.53125 8.98926 5.88281 8.60254 6.375 8.60254C6.83203 8.60254 7.21875 8.98926 7.21875 9.44629C7.21875 9.93848 6.83203 10.29 6.375 10.29ZM8.625 9.44629C8.625 9.16504 8.87109 8.88379 9.1875 8.88379H18.1875C18.4688 8.88379 18.75 9.16504 18.75 9.44629C18.75 9.7627 18.4688 10.0088 18.1875 10.0088H9.1875C8.87109 10.0088 8.625 9.7627 8.625 9.44629ZM8.625 12.8213C8.625 12.54 8.87109 12.2588 9.1875 12.2588H18.1875C18.4688 12.2588 18.75 12.54 18.75 12.8213C18.75 13.1377 18.4688 13.3838 18.1875 13.3838H9.1875C8.87109 13.3838 8.625 13.1377 8.625 12.8213ZM8.625 16.1963C8.625 15.915 8.87109 15.6338 9.1875 15.6338H18.1875C18.4688 15.6338 18.75 15.915 18.75 16.1963C18.75 16.5127 18.4688 16.7588 18.1875 16.7588H9.1875C8.87109 16.7588 8.625 16.5127 8.625 16.1963ZM6.375 11.9775C6.83203 11.9775 7.21875 12.3643 7.21875 12.8213C7.21875 13.3135 6.83203 13.665 6.375 13.665C5.88281 13.665 5.53125 13.3135 5.53125 12.8213C5.53125 12.3643 5.88281 11.9775 6.375 11.9775ZM6.375 17.04C5.88281 17.04 5.53125 16.6885 5.53125 16.1963C5.53125 15.7393 5.88281 15.3525 6.375 15.3525C6.83203 15.3525 7.21875 15.7393 7.21875 16.1963C7.21875 16.6885 6.83203 17.04 6.375 17.04ZM1.875 7.19629C1.875 5.96582 2.85938 4.94629 4.125 4.94629H19.875C21.1055 4.94629 22.125 5.96582 22.125 7.19629V18.4463C22.125 19.7119 21.1055 20.6963 19.875 20.6963H4.125C2.85938 20.6963 1.875 19.7119 1.875 18.4463V7.19629ZM3 7.19629V18.4463C3 19.0791 3.49219 19.5713 4.125 19.5713H19.875C20.4727 19.5713 21 19.0791 21 18.4463V7.19629C21 6.59863 20.4727 6.07129 19.875 6.07129H4.125C3.49219 6.07129 3 6.59863 3 7.19629Z" />
        </svg>
      )

    case 'bank':
      return (
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M17.4375 5.53223C17.7539 5.74316 18 6.09473 18 6.48145C18 7.0791 17.5078 7.57129 16.875 7.57129H1.08984C0.457031 7.57129 0 7.0791 0 6.48145C0 6.09473 0.210938 5.74316 0.527344 5.53223L8.71875 0.926758C8.89453 0.821289 9.07031 0.821289 9.24609 0.926758L17.4375 5.53223ZM16.7695 6.44629L9 2.05176L1.19531 6.44629H7.20703C7.06641 6.2002 7.03125 5.91895 7.03125 5.60254C7.03125 4.54785 7.91016 3.63379 9 3.63379C10.0547 3.63379 10.9688 4.54785 10.9688 5.60254C10.9688 5.91895 10.8984 6.2002 10.7578 6.44629H16.7695ZM9 6.44629C9.45703 6.44629 9.84375 6.09473 9.84375 5.60254C9.84375 5.14551 9.45703 4.75879 9 4.75879C8.50781 4.75879 8.15625 5.14551 8.15625 5.60254C8.15625 6.09473 8.50781 6.44629 9 6.44629ZM2.25 8.69629H3.375V13.1963H6.1875V8.69629H7.3125V13.1963H10.6875V8.69629H11.8125V13.1963H14.625V8.69629H15.75V13.1963C16.0312 13.1963 16.3125 13.4775 16.3125 13.7588C16.3125 14.0752 16.0312 14.3213 15.75 14.3213H2.25C1.93359 14.3213 1.6875 14.0752 1.6875 13.7588C1.6875 13.4775 1.93359 13.1963 2.25 13.1963V8.69629ZM16.5938 15.4463C16.875 15.4463 17.1562 15.7275 17.1562 16.0088C17.1562 16.3252 16.875 16.5713 16.5938 16.5713H1.40625C1.08984 16.5713 0.84375 16.3252 0.84375 16.0088C0.84375 15.7275 1.08984 15.4463 1.40625 15.4463H16.5938ZM17.4375 17.6963C17.7188 17.6963 18 17.9775 18 18.2588C18 18.5752 17.7188 18.8213 17.4375 18.8213H0.5625C0.246094 18.8213 0 18.5752 0 18.2588C0 17.9775 0.246094 17.6963 0.5625 17.6963H17.4375Z" />
        </svg>
      )

    case 'back-line':
      return (
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M15.3125 6.6875C15.5938 6.6875 15.875 6.96875 15.875 7.25C15.875 7.56641 15.5938 7.8125 15.3125 7.8125H4.83594L8.94922 11.9258C9.16016 12.1367 9.16016 12.5234 8.94922 12.7344C8.73828 12.9453 8.35156 12.9453 8.14062 12.7344L3.07812 7.67188C2.97266 7.56641 2.9375 7.42578 2.9375 7.25C2.9375 7.10938 2.97266 6.96875 3.07812 6.86328L8.14062 1.80078C8.35156 1.58984 8.73828 1.58984 8.94922 1.80078C9.16016 2.01172 9.16016 2.39844 8.94922 2.60938L4.83594 6.6875H15.3125ZM0.6875 0.5C0.96875 0.5 1.25 0.78125 1.25 1.0625V13.4375C1.25 13.7539 0.96875 14 0.6875 14C0.371094 14 0.125 13.7539 0.125 13.4375V1.0625C0.125 0.78125 0.371094 0.5 0.6875 0.5Z" />
        </svg>
      )

    default:
      return (
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 2.57129C15.0938 2.57129 16 3.47754 16 4.57129V12.5713C16 13.6963 15.0938 14.5713 14 14.5713H2C0.875 14.5713 0 13.6963 0 12.5713V2.57129C0 1.47754 0.875 0.571289 2 0.571289H5.65625C6.1875 0.571289 6.6875 0.790039 7.0625 1.16504L8.5 2.57129H14ZM15 12.5713V4.57129C15 4.04004 14.5312 3.57129 14 3.57129H8.0625L6.375 1.88379C6.1875 1.69629 5.9375 1.57129 5.65625 1.57129H2C1.4375 1.57129 1 2.04004 1 2.57129V12.5713C1 13.1338 1.4375 13.5713 2 13.5713H14C14.5312 13.5713 15 13.1338 15 12.5713ZM13 5.57129C13.25 5.57129 13.5 5.82129 13.5 6.07129C13.5 6.35254 13.25 6.57129 13 6.57129H3C2.71875 6.57129 2.5 6.35254 2.5 6.07129C2.5 5.82129 2.71875 5.57129 3 5.57129H13Z" />
        </svg>
      )
  }
}
