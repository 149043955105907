import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { cn } from '@/utils/utils'
import { moneyMask, amountRegexDecimal } from '@/utils/helpers'
import { useInvestments } from './api/get-investments'
import { useNotificationsStore } from './api/use-notifications-store'

import { EmptyState } from '../components/empty-state'
import { InvestmentsSubNav } from './components/investments-subnav'
import Loader from '@/components/Loader'
import { Pagination } from './components/pagination'
import { ActiveInvestmentCard } from './components/active-investment-card'
import { CompletedInvestmentCard } from './components/completed-investment-card'
import { PendingInvestmentCard } from './components/pending-investment-card'
import { WaitlistInvestmentCard } from './components/waitlist-investment-card'
import { PledgedInvestmentCard } from './components/pledged-investment-card'
import { useUnreadDataQuery } from '../api/get-unread-messages'

export const Investments = () => {
  const { type } = useParams() // pathname: dashboard/investments/:type
  const [searchParams, setSearchParams] = useSearchParams()
  const nav = useNavigate()
  const { data: unreadData } = useUnreadDataQuery()
  const hideNewMessages = useNotificationsStore(
    (state) => state.hideNewMessages
  )
  const hideTodos = useNotificationsStore((state) => state.hideTodos)
  const removeNewMessagesNotification = useNotificationsStore(
    (state) => state.removeNewMessagesNotification
  )
  const removeTodosNotification = useNotificationsStore(
    (state) => state.removeTodosNotification
  )

  const { data, isLoading, isError } = useInvestments()

  useEffect(() => {
    if (!data) return
    setPagination({ pageIndex: 0, pageSize: 5 })
    if (
      !type ||
      (type !== 'active' &&
        type !== 'completed' &&
        type !== 'pending' &&
        type !== 'waitlist' &&
        type !== 'pledged')
    ) {
      nav('/dashboard/investments/active')
    }
  }, [data, type, nav])

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 5,
  })

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', (pagination.pageIndex + 1).toString())
    setSearchParams(newSearchParams)
  }, [pagination, searchParams, setSearchParams])

  const totalInvested = useMemo(() => {
    if (type !== 'active' && type !== 'completed') return 0
    return (
      data?.[type]?.reduce((total: number, investment) => {
        return total + Number(investment.total_invested)
      }, 0) ?? 0
    )
  }, [data, type])

  const totalDistributed = useMemo(() => {
    if (type !== 'active' && type !== 'completed') return 0
    return (
      data?.[type]?.reduce((total: number, investment) => {
        return total + Number(investment.total_distributions)
      }, 0) ?? 0
    )
  }, [data, type])

  return data ? (
    <div className="w-full h-full mb-10">
      <div className="w-full flex flex-col">
        <div className="w-full mb-10 flex justify-between items-center">
          <h1 className="text-[31px] font-bold self-start">Investments</h1>
        </div>
        {(unreadData ?? 0) > 0 && !hideNewMessages && (
          <div
            className={cn(
              data.pending?.length > 0 || data.pledged?.length > 0
                ? 'mb-4'
                : 'mb-10',
              hideTodos && 'mb-10'
            )}
          >
            <div className="h-10 pl-2 pr-3 bg-[#1D89FF0D] rounded-[40px] justify-start items-center gap-3 inline-flex">
              <div className="px-3 py-0.5 bg-[#1d89ff] rounded-[43px] justify-start items-center gap-1 flex">
                <div className="text-white text-base font-medium">
                  {unreadData} new
                </div>
              </div>
              <div className="justify-start items-center gap-1.5 flex">
                <div className="text-content-black text-base font-normal">
                  You have
                </div>
                <div className="justify-start items-center gap-2 flex">
                  <Link
                    to="/dashboard/messages?unread=true&page=1"
                    className="text-content-black text-sm font-medium underline"
                  >
                    {unreadData} new message{unreadData ?? 0 > 1 ? 's' : ''}
                  </Link>
                </div>
              </div>
              <button
                className="text-center p-1 text-[#1d89ff] text-base font-normal"
                onClick={() => removeNewMessagesNotification()}
              >
                <i className="fa-solid fa-lg fa-xmark" />
              </button>
            </div>
          </div>
        )}
        {(data.pending?.length > 0 || data.pledged?.length > 0) &&
          !hideTodos && (
            <div className="mb-10">
              <div className="h-10 pl-2 pr-3 bg-[#fffcef] rounded-[40px] justify-start items-center gap-3 inline-flex">
                <div className="px-3 py-0.5 bg-[#fff3b9] rounded-[43px] justify-start items-center gap-1 flex">
                  <div className="text-[#826600] text-base font-medium">
                    To-do
                  </div>
                </div>
                <div className="justify-start items-center text-base font-normal gap-1.5 flex">
                  <div className="text-content-black">You have</div>
                  {data.pending?.length && (
                    <div className="justify-start items-center gap-2 flex">
                      <Link
                        to="/dashboard/investments/pending"
                        className="text-content-black text-sm font-medium underline"
                      >
                        {data.pending?.length} pending investment
                        {data.pending?.length > 1 ? 's' : ''}
                      </Link>
                    </div>
                  )}
                  {data.pending?.length && data.pledged?.length && (
                    <span> and </span>
                  )}
                  {data.pledged?.length && (
                    <div className="justify-start items-center gap-2 flex">
                      <Link
                        to="/dashboard/investments/pledged"
                        className="text-content-black text-sm font-medium underline"
                      >
                        {data.pledged?.length} pledged investment
                        {data.pledged?.length > 1 ? 's' : ''}
                      </Link>
                    </div>
                  )}
                  <span> that require more information to process.</span>
                </div>
                <button
                  className="text-center p-1 text-[#826600] text-base font-normal"
                  onClick={() => removeTodosNotification()}
                >
                  <i className="fa-solid fa-lg fa-xmark" />
                </button>
              </div>
            </div>
          )}
        <div
          className={cn(
            type === 'active' || type === 'completed' ? 'mb-6' : 'mb-10'
          )}
        >
          <InvestmentsSubNav />
        </div>
        <div className="flex justify-between items-center mb-4">
          <div className="text-16">
            <span className="font-medium">
              {data[type ?? '']?.length || 0} {type}
            </span>
            <span> investments</span>
          </div>
          {(type === 'active' || type === 'completed') && (
            <div className="flex gap-10">
              {type === 'completed' && (
                <div className="flex min-w-[170px] flex-col items-end gap-2">
                  <div className="text-base">Actual IRR/Return</div>
                  <div className="text-[24px] font-medium">-.-%</div>
                </div>
              )}
              <div className="flex min-w-[170px] flex-col items-end gap-2">
                <div className="text-base">Total Invested</div>
                <div className="text-[24px] font-medium">
                  ${moneyMask(totalInvested, amountRegexDecimal)}
                </div>
              </div>
              <div className="flex min-w-[170px] flex-col items-end gap-2 mr-[76px]">
                <div className="text-base">Total Distributed</div>
                <div className="text-[24px] font-medium">
                  ${moneyMask(totalDistributed, amountRegexDecimal)}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex flex-col gap-6 mb-6">
          {data[type ?? '']?.length > 0 &&
            data[type ?? '']
              ?.slice(pagination.pageIndex * 5, pagination.pageIndex * 5 + 5)
              .map((investment) => {
                return (
                  <div
                    key={investment.name}
                    className="w-full border border-bg-light"
                  >
                    {type === 'active' && (
                      <ActiveInvestmentCard investment={investment} />
                    )}
                    {type === 'completed' && (
                      <CompletedInvestmentCard investment={investment} />
                    )}
                    {type === 'pending' && (
                      <PendingInvestmentCard investment={investment} />
                    )}
                    {type === 'waitlist' && (
                      <WaitlistInvestmentCard investment={investment} />
                    )}
                    {type === 'pledged' && (
                      <PledgedInvestmentCard investment={investment} />
                    )}
                  </div>
                )
              })}
        </div>
        <Pagination
          pagination={pagination}
          totalPages={Math.ceil(data[type ?? '']?.length / 5) || 0}
          totalCount={data[type ?? '']?.length || 0}
          changePage={(newPage) =>
            setPagination({ ...pagination, pageIndex: newPage })
          }
        />
      </div>
    </div>
  ) : (
    <div className="h-[75vh] flex justify-center items-center">
      {isLoading && <Loader />}
      {isError && (
        <div>
          <EmptyState
            iconClasses="fa-regular fa-bomb fa-3x text-content-black"
            copy="There was an error while retrieving your investments."
            linkCopy="Browse Current Investments"
            linkTo="https://www.realtymogul.com/investment-opportunities"
          />
        </div>
      )}
    </div>
  )
}

export default Investments
