import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { Auth } from 'aws-amplify'
import { ErrorBoundary } from 'react-error-boundary'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import { ImperativePanelHandle } from 'react-resizable-panels'

import { cn } from '@/utils/utils'
import { getIcon } from './shared/get-icon'
import { useAuthDataState } from '@/routes/shared/_authDataState'
import { useTokensDataState } from '@/routes/shared/_tokensDataState'
import { useUnreadDataQuery } from './api/get-unread-messages'

import ContactUs from './components/contact-us'
import { FallbackError } from './error'
import Footer from '../shared/Footer'
import Loader from '@/components/Loader'
import Login from '../shared/Login'
import Nav from './components/nav'
import { ResizablePanel, ResizablePanelGroup } from '@/components/ui/resizable'
import { TooltipProvider } from '@/components/ui/tooltip'

interface DashboardProps {
  defaultLayout?: number[] | undefined
  defaultCollapsed?: boolean
}

const Dashboard = ({ defaultCollapsed = false }: DashboardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed)
  const { isLoading: userIsLoading, data: authData } = useAuthDataState()

  const navRef = useRef<ImperativePanelHandle | null>(null)
  const location = useLocation()

  const { data: tokenData, isFetched: isTokenDataFetched } =
    useTokensDataState()

  const { data: unreadData } = useUnreadDataQuery()

  useEffect(() => {
    if (userIsLoading || !isTokenDataFetched) return
    // feature flag check
    if (tokenData?.ff_dashboard === 'no' && isTokenDataFetched) {
      window.location.href = 'https://www.realtymogul.com'
    }
  }, [userIsLoading, tokenData, isTokenDataFetched])

  const toggleCollapse = () => {
    isCollapsed ? navRef?.current?.expand() : navRef?.current?.collapse()
    setIsCollapsed(!isCollapsed)
  }

  return (
    <ErrorBoundary FallbackComponent={FallbackError}>
      {authData ? (
        <TooltipProvider delayDuration={0}>
          <div className="flex h-full w-full flex-col items-center overflow-hidden">
            <div className="w-full h-[69px] max-w-[1800px] text-2xl border-b border-b-slate-100 flex justify-between p-4 items-center">
              <span>RealtyMogul</span>
              <div className="flex-row gap-1 md:gap-4 hidden md:flex mr-8">
                <span
                  className="cursor-pointer text-primary text-[12px] md:text-16 hover:text-primary-hover"
                  onClick={(e) => {
                    e.preventDefault()
                    Auth.signOut()
                    window.location.reload()
                  }}
                >
                  Log out
                </span>
              </div>
            </div>
            <div className="w-full box-border pt-6 pb-10 px-8 overflow-hidden xl:max-w-[1800px]">
              <ResizablePanelGroup
                direction="horizontal"
                onLayout={(sizes: number[]) => {
                  document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                    sizes
                  )}`
                }}
                className="h-full items-stretch"
              >
                <ResizablePanel
                  ref={navRef}
                  collapsedSize={3}
                  collapsible={true}
                  minSize={15}
                  maxSize={15}
                  onCollapse={() => {
                    setIsCollapsed(true)
                    document.cookie = `react-resizable-panels:nav:collapsed=true`
                  }}
                  onExpand={() => {
                    setIsCollapsed(false)
                    document.cookie = `react-resizable-panels:nav:collapsed=false`
                  }}
                  className={cn(
                    'h-[80vh] pr-2',
                    isCollapsed &&
                      'min-w-[75px] items-start justify-start transition-all duration-300 ease-in-out pr-4'
                  )}
                >
                  <div>
                    <Nav
                      unreadCount={unreadData}
                      isCollapsed={isCollapsed}
                      toggleCollapse={toggleCollapse}
                      links={[
                        {
                          title: 'Investments',
                          icon: getIcon('chart-line'),
                          location: '/dashboard/investments',
                          active: location.pathname.includes('investments'),
                        },
                        {
                          title: 'Messages',
                          icon: getIcon('envelope'),
                          location: '/dashboard/messages?page=1',
                          active: location.pathname === '/dashboard/messages',
                        },
                        {
                          title: 'Tax Center',
                          icon: getIcon('files'),
                          location: '/dashboard/taxes',
                          active: location.pathname.includes('taxes'),
                        },
                        {
                          title: 'Transactions',
                          icon: getIcon('rectangle-list'),
                          location: '/dashboard/transactions',
                          active: location.pathname.includes('transactions'),
                        },
                      ]}
                      links2={[
                        {
                          title: 'Bank Accounts',
                          icon: getIcon('bank'),
                          location: '/dashboard/bank-accounts',
                          active: location.pathname.includes('bank-accounts'),
                        },
                        {
                          title: 'Ownership Types',
                          icon: getIcon('joint_ownership'),
                          location: '/dashboard/ownership-types',
                          active: location.pathname.includes('ownership-types'),
                        },
                        {
                          title: 'Profile',
                          icon: getIcon('individual'),
                          location: '/dashboard/profile',
                          active: location.pathname.includes('profile'),
                        },
                      ]}
                    />
                  </div>
                </ResizablePanel>

                <ResizablePanel minSize={30}>
                  <div className="flex flex-col min-h-[75vh] px-[40px]">
                    <div className="flex-grow">
                      <Outlet />
                    </div>
                    <ContactUs
                      links={[
                        {
                          label: 'Questions? Send a message',
                          to: () => ZendeskAPI('webWidget', 'open'),
                        },
                        {
                          label: 'Speak with Investor Relations',
                          to: 'https://calendly.com/realtymogul-investor-relations/realty-mogul-representative-call',
                        },
                      ]}
                    />
                  </div>
                </ResizablePanel>
              </ResizablePanelGroup>
            </div>
            <div className="w-full bg-black">
              <Footer />
            </div>
          </div>
          <Zendesk defer zendeskKey="f0fb7231-d555-4b6c-b382-06ff3c2efb4e" />
        </TooltipProvider>
      ) : (
        <>
          {!userIsLoading ? (
            <Login />
          ) : (
            <div className="flex h-[80vh] w-full items-center justify-center">
              <Loader />
            </div>
          )}
        </>
      )}
    </ErrorBoundary>
  )
}

export default Dashboard
