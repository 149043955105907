import { ColumnDef } from '@tanstack/react-table'

import { TransactionTypes } from '../api/schema'
import { ColumnHeader } from './column-header'
import { mapParams } from '../../shared/map-params'

export const columns: ColumnDef<TransactionTypes>[] = [
  {
    accessorKey: 'transaction_date',
    header: ({ column }) => <ColumnHeader column={column} title="Date" />,
    cell: ({ row }) => {
      const date = row.original.transaction_date || ''
      return (
        <div className="font-medium text-base">
          {new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </div>
      )
    },
    enableSorting: true,
    enableHiding: false,
    meta: {
      filterLabel: 'All Dates',
    },
  },
  {
    accessorKey: 'transaction_type',
    header: ({ column }) => (
      <ColumnHeader column={column} title="Transaction" />
    ),
    cell: ({ row }) => {
      return (
        <div className="font-medium text-base">
          {mapParams(row.getValue('transaction_type'))}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Transactions',
    },
  },
  {
    accessorKey: 'io_name',
    header: ({ column }) => <ColumnHeader column={column} title="Investment" />,
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-[4px]">
          <a
            className="underline cursor-pointer text-base"
            href={row.getValue('investmentLink')}
            target="_blank"
          >
            {row.getValue('io_name')}
          </a>
          <div className="text-[#8C8C8C] text-base">
            {row.getValue('issuer_name')}
          </div>
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Investments',
    },
  },
  {
    accessorKey: 'investment_account_name',
    header: ({ column }) => (
      <ColumnHeader column={column} title="Ownership Type" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex flex-col gap-[4px]">
          <div className="text-base">
            {row.getValue('investment_account_name')}
          </div>
          <div className="text-[#8C8C8C]">
            {row.getValue('investment_account_type')}
          </div>
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
    meta: {
      filterLabel: 'All Ownership Types',
    },
  },
  {
    accessorKey: 'amount',
    header: ({ column }) => (
      <ColumnHeader column={column} title="Amount" className="text-end" />
    ),
    cell: ({ row }) => {
      const amount = row.getValue('amount') as TransactionTypes['amount']
      const formattedAmount = Number(amount)?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })

      const shares = row.getValue('shares') as TransactionTypes['shares']
      const purchasePrice = row.original.price_per_share
      const formattedPurchasePrice = Number(purchasePrice)?.toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )
      return (
        <div className="flex flex-col items-end text-right">
          <div className="text-base">{formattedAmount}</div>
          {shares && purchasePrice && (
            <div className="text-[#8C8C8C] text-base">
              {shares} shares @ {formattedPurchasePrice} per share
            </div>
          )}
        </div>
      )
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'investment_account_type',
  },
  {
    accessorKey: 'investmentLink',
  },
  {
    accessorKey: 'issuer_name',
  },
  {
    accessorKey: 'shares',
  },
  {
    accessorKey: 'purchasePrice',
  },
]
