import { ReactNode, forwardRef } from 'react'
interface RadioProps {
  name: string
  value: string
  label: string | ReactNode
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  return (
    <label
      className="relative flex w-full items-center bg-bg-lighter p-3 hover:cursor-pointer active:text-primary"
      htmlFor={`${props.name}-${props.value}`}
    >
      <input
        className="appearance-none before:content[''] peer h-5 w-5 min-w-[20px] rounded-full border border-[#202020] bg-white text-primary before:absolute before:left-4 before:top-[calc(50%-6px)] before:block before:h-3 before:w-3 before:rounded-full checked:before:bg-primary hover:cursor-pointer checked:border-primary hover:border-primary-hover hover:before:bg-primary-hover disabled:opacity-50"
        type="radio"
        id={`${props.name}-${props.value}`}
        ref={ref}
        {...props}
      />
      <div
        data-cy={`${props.name}-${props.value}-label`}
        className="ml-2 text-base peer-checked:font-medium"
      >
        {props.label}
      </div>
    </label>
  )
})

export default Radio
