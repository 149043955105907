import { ReactNode, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
interface CheckboxProps {
  name: string
  label: string | ReactNode
  errorMessage?: string | FieldError
  disabled?: boolean
  checked?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: () => void
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (props, ref) => {
    return (
      <>
        <label
          className={`${
            props.errorMessage ? 'border border-alert' : ''
          } relative flex w-full bg-bg-lighter p-3 hover:cursor-pointer active:text-primary`}
          htmlFor={props.name}
        >
          <input
            className="appearance-none before:content[''] before:peer h-5 w-5 rounded border border-[#202020] bg-white text-base before:absolute before:left-4 before:top-[16px] before:block before:h-3 before:w-3 checked:border-primary checked:before:bg-primary hover:before:bg-primary-hover hover:border-primary-hover disabled:opacity-50"
            type="checkbox"
            id={props.name}
            ref={ref}
            name={props.name}
            checked={props.checked}
            onChange={props.onChange}
            onBlur={props.onBlur}
            disabled={props.disabled}
          />
          <div
            data-cy={props.name}
            className="ml-2 max-w-[90%] select-none text-base peer-disabled:opacity-50"
          >
            {props.label}
          </div>
        </label>
        {props.errorMessage && (
          <p className="mb-1 text-xs text-alert">
            {typeof props.errorMessage === 'string'
              ? props.errorMessage
              : props.errorMessage.message}
          </p>
        )}
      </>
    )
  }
)

export default Checkbox
