import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'

import {
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { useTaxesFilters } from './api/get-tax-filters'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

import { Pagination } from './components/pagination'
import { Filter } from './components/filter'
import { columns } from './components/columns'
import { EmptyState } from '../components/empty-state'
import { getTaxDocuments, useTaxDocuments } from './api/get-tax-documents'
import Loader from '@/components/Loader'
import { mapParams } from '../shared/map-params'

const TaxCenter = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, isFetching } = useTaxDocuments()
  const { data: filtersData } = useTaxesFilters()

  const [pagination, setPagination] = useState({
    pageIndex: Math.max(Number(searchParams.get('page') || 0) - 1, 0),
    pageSize: 10,
  })
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'tax_year', desc: true },
  ])

  const queryClient = useQueryClient()

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams)
    newSearchParams.set('page', (pagination.pageIndex + 1).toString())
    setSearchParams(newSearchParams)
  }, [queryClient, pagination, searchParams, setSearchParams])

  const table = useReactTable({
    data: data?.documents ?? [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  const handleFilterUpdate = (name: string, uuid: string) => {
    const paramName = mapParams(name)
    const nextSearchParams = new URLSearchParams(searchParams)
    uuid === 'all'
      ? nextSearchParams.delete(paramName)
      : nextSearchParams.set(
          paramName,
          paramName === 'document_type' ? uuid : mapParams(uuid)
        )
    setSearchParams(nextSearchParams)
    setPagination({ ...pagination, pageIndex: 0 })
  }

  const prefetchNextPage = (newPage: number) => {
    queryClient.prefetchQuery({
      queryKey: [
        'tax-documents',
        (Number(searchParams.get('page') || 0) + 2).toString(),
        searchParams.get('io_uuid'),
        searchParams.get('investment_account_uuid'),
        searchParams.get('tax_year'),
        searchParams.get('document_type'),
      ],
      queryFn: () => {
        const newSearchParams = new URLSearchParams(searchParams)
        newSearchParams.set('page', (newPage + 2).toString())
        return getTaxDocuments(newSearchParams)
      },
    })
  }

  return (
    data && (
      <div className="w-full h-full">
        <div className="w-full mb-8 flex justify-between items-center">
          <h1 className="text-[31px] font-bold self-start">Tax Center</h1>
          <div className="w-3/4 justify-end flex flex-wrap flex-row gap-2">
            {filtersData &&
              Object.entries(filtersData)
                .sort((a, b) => {
                  const order = ['tax_years', 'document_types', 'investments']
                  const indexA = order.indexOf(a[0])
                  const indexB = order.indexOf(b[0])

                  if (indexA === -1 && indexB === -1) return 0
                  if (indexA === -1) return 1
                  if (indexB === -1) return -1
                  return indexA - indexB
                })
                .map((entry) => {
                  return (
                    <Filter
                      key={entry[0]}
                      name={entry[0]}
                      data={Array.isArray(entry[1]) ? entry[1] : [entry[1]]}
                      updateCallback={handleFilterUpdate}
                      defaultValue={searchParams.get(mapParams(entry[0]))}
                    />
                  )
                })}
          </div>
        </div>

        <div className="mb-6">{data.count} documents</div>
        {data && data.count > 0 ? (
          <div className="mb-10">
            <div className="rounded-md border overflow-hidden">
              <Table>
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <TableHead key={header.id} colSpan={header.colSpan}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </TableHead>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && 'selected'}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell
                        colSpan={columns.length}
                        className="h-24 text-center"
                      >
                        No results.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Pagination
                pagination={pagination}
                totalPages={data.pages}
                totalCount={data.count}
                changePage={(newPage) => {
                  setPagination({ ...pagination, pageIndex: newPage })
                  if (
                    newPage + 2 < data.pages &&
                    newPage > pagination.pageIndex
                  ) {
                    prefetchNextPage(newPage)
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="h-[75vh] flex justify-center items-center">
            {!isLoading && !isFetching && data ? (
              <EmptyState
                iconClasses="fa-regular fa-files fa-3x text-primary opacity-20"
                copy="You have no documents."
                linkCopy="Browse Current Investments"
                linkTo="https://www.realtymogul.com/investment-opportunities"
              />
            ) : (
              <Loader />
            )}
          </div>
        )}
      </div>
    )
  )
}

export default TaxCenter
