import { Link } from 'react-router-dom'
import { z } from 'zod'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

const ownershipTypeMenuSchema = z.object({
  uuid: z.string().uuid(),
})

type OwnershipMenuTypes = z.infer<typeof ownershipTypeMenuSchema>

const MenuButton = ({
  text,
  linkLocation = '/dashboard/profile',
}: {
  text: string
  linkLocation?: string
}) => {
  return (
    <Link
      to={linkLocation}
      className="w-full text-left px-4 py-3 hover:bg-bg-light"
    >
      {text}
    </Link>
  )
}

export const OwnershipTypeMenu = ({ uuid }: OwnershipMenuTypes) => {
  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-6 py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent className="w-[187px] text-base px-0 py-2 rounded flex flex-col">
        <MenuButton
          text="Edit"
          linkLocation={`/dashboard/ownership-types/edit/${uuid}`}
        />
        <MenuButton
          text="Investments"
          linkLocation={`/dashboard/investments?page=1&investment_account_uuid=${uuid}`}
        />
        <MenuButton
          text="Transactions"
          linkLocation={`/dashboard/transactions?page=1&investment_account_uuid=${uuid}`}
        />
        <MenuButton
          text="Messages"
          linkLocation={`/dashboard/messages?page=1&investment_account_uuid=${uuid}`}
        />
        <MenuButton
          text="Tax Documents"
          linkLocation={`/dashboard/taxes?page=1&investment_account_uuid=${uuid}`}
        />
      </PopoverContent>
    </Popover>
  )
}
