import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import FormFooter from '../../shared/FormFooter'
import InfoModal from '../../../components/InfoModal'
import MoneyInput from '../../../components/MoneyInput'
import Radio from '../../../components/Radio'
import Sidebar from '../Sidebar'
import Stepper from '../../../components/Stepper'
import ErrorAlert from '../../../components/ErrorAlert'

interface FormType {
  currentIncome: string
  currentNetWorth: string
}

interface ModalType {
  modalContinue: string
}

interface InvestData {
  id: string
  aboutYou: FormType | undefined
}

const ReitIncomeScreen = () => {
  const [redFlagState, setRedFlagState] = useState('')
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()
  const { t } = useTranslation()

  const ref = useRef<HTMLDialogElement>(null)

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      currentIncome:
        investData?.aboutYou?.oldIncome ||
        investData?.aboutYou?.currentIncome ||
        '',
      currentNetWorth:
        investData?.aboutYou?.oldNetWorth ||
        investData?.aboutYou?.currentNetWorth ||
        '',
    },
  })

  const {
    control: modalControl,
    formState: { errors: modalErrors },
    handleSubmit: handleModalSubmit,
  } = useForm<ModalType>({
    defaultValues: {
      modalContinue: '',
    },
  })

  const onSubmit = handleSubmit((data) => {
    const oldNetWorth = Number(
      String(investData?.aboutYou?.oldNetWorth)?.replace(/\D/g, '')
    )
    const oldIncome = Number(
      String(investData?.aboutYou?.oldIncome)?.replace(/\D/g, '')
    )
    const newNetWorth = Number(String(data.currentNetWorth).replace(/\D/g, ''))
    const newIncome = Number(String(data.currentIncome).replace(/\D/g, ''))

    const incomeRedFlag =
      oldIncome > 0 &&
      oldIncome < 200_000 &&
      newIncome >= 200_000 &&
      oldNetWorth > 0 &&
      oldNetWorth < 1_000_000 &&
      newNetWorth <= 1_000_000
    const netWorthRedFlag =
      oldIncome > 0 &&
      oldNetWorth < 1_000_000 &&
      newNetWorth >= 1_000_000 &&
      oldNetWorth > 0 &&
      oldIncome < 200_000 &&
      newIncome <= 200_000
    const bothRedFlag =
      oldIncome > 0 &&
      oldIncome < 200_000 &&
      newIncome >= 200_000 &&
      oldNetWorth > 0 &&
      oldNetWorth < 1_000_000 &&
      newNetWorth >= 1_000_000

    queryClient.setQueryData<InvestData | undefined>(
      ['invest'],
      (oldData) =>
        oldData && {
          ...oldData,
          aboutYou: { ...oldData.aboutYou, ...data },
        }
    )
    if (incomeRedFlag || netWorthRedFlag || bothRedFlag) {
      if (bothRedFlag) {
        setRedFlagState('both')
      } else {
        setRedFlagState(incomeRedFlag ? 'income' : 'networth')
      }
      ref?.current?.showModal()
      return
    } else {
      nav(`/invest/${investData.opportunity.id}/amount/reit`)
    }
  })

  const onModalSubmit = handleModalSubmit((data) => {
    if (data.modalContinue === 'no') {
      reset({
        currentIncome:
          investData?.aboutYou?.oldIncome ||
          investData?.aboutYou?.currentIncome ||
          '',
        currentNetWorth:
          investData?.aboutYou?.oldNetWorth ||
          investData?.aboutYou?.currentNetWorth ||
          '',
      })
      setRedFlagState('')
      ref?.current?.close()
      return
    }
    nav(`/invest/${investData.opportunity.id}/amount/reit`)
  })

  // const backLocation = `/invest/${investData?.investment?.id}/account`

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={1} currentStep={2} totalStepCount={10} />
      <div className="flex gap-[72px]">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            Income and Net Worth
          </h1>
          <p className="mb-6 text-16 text-content-black">
            Please add your current financial information below.
          </p>
          <form onSubmit={onSubmit} className="mb-14 xl:">
            <div className="mb-6">
              {(errors?.currentIncome || errors.currentNetWorth) && (
                <ErrorAlert />
              )}
              <Controller
                name="currentIncome"
                control={control}
                rules={{ required: 'is required' }}
                render={({ field, fieldState }) => (
                  <MoneyInput
                    label={
                      <span>
                        Current Income{' '}
                        <InfoModal
                          modalContent={
                            <div className="md:px-8 md:pt-4 px-6">
                              <h2 className="mb-6 text-xl font-medium text-content-black">
                                Current Annual Income
                              </h2>
                              <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                "Annual income" must be calculated as set forth
                                in Rule 501(a) under the Securities Act of 1933,
                                as amended, which requires natural persons to
                                consider their income in the two most recent
                                years and a reasonable expectation of income for
                                the current year.
                              </p>
                            </div>
                          }
                        />
                      </span>
                    }
                    placeholder="$"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="mb-6">
              {modalErrors?.modalContinue && (
                <ErrorAlert text="Please select one of the answers below:" />
              )}
              <Controller
                name="currentNetWorth"
                rules={{ required: 'is required' }}
                control={control}
                render={({ field, fieldState }) => (
                  <MoneyInput
                    label={
                      <span>
                        Current Net Worth{' '}
                        <InfoModal
                          modalContent={
                            <div className="md:px-8 md:pt-4 px-6">
                              <h2 className="mb-6 text-xl font-medium text-content-black">
                                Current Net Worth
                              </h2>
                              <p className="mb-6 font-sans text-16 font-normal text-content-black">
                                The meaning of "net worth" means the excess of
                                total assets at fair market value (excluding
                                your primary residence) minus total liabilities.
                              </p>
                            </div>
                          }
                        />
                      </span>
                    }
                    placeholder="$"
                    error={fieldState.error}
                    {...field}
                  />
                )}
              />
            </div>
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
      <FormFooter submitFunc={onSubmit} />
      <dialog ref={ref} className="p-8 max-w-xl">
        <button
          className="absolute right-3 top-3 p-3.5"
          onClick={(e) => {
            e.preventDefault()
            ref?.current?.close()
          }}
        >
          <i className="fa-sharp fa-solid fa-xmark fa-xl"></i>
        </button>
        <p
          data-cy="previous-diff-answer-header"
          className="mb-6 pr-6 text-[19px] font-medium"
        >
          We want to be sure your income/net worth information is accurate.
        </p>
        {redFlagState === 'income' && (
          <p data-cy="previous-diff-answer-text" className="mb-6">
            Previously on the platform you had indicated that your Income was{' '}
            {investData?.aboutYou?.oldIncome}. Is the amount you inputted above
            accurate?
          </p>
        )}
        {redFlagState === 'networth' && (
          <p data-cy="previous-diff-answer-text" className="mb-6">
            Previously on the platform you had indicated that your Net Worth was{' '}
            {investData?.aboutYou?.oldNetWorth}. Is the amount you inputted
            above accurate?
          </p>
        )}
        {redFlagState === 'both' && (
          <p data-cy="previous-diff-answer-text" className="mb-6">
            Previously on the platform you had indicated that your Income was{' '}
            {investData?.aboutYou?.oldIncome} and Net Worth was{' '}
            {investData?.aboutYou?.oldNetWorth}. Are the amounts you inputted
            above accurate?
          </p>
        )}
        <p className="mb-2">{t('common:pleaseSelectOne')}</p>
        <form onSubmit={onModalSubmit}>
          <div className="mb-6">
            <Controller
              name="modalContinue"
              control={modalControl}
              rules={{ required: 'is required' }}
              render={({ field, fieldState }) => (
                <div
                  className={`flex flex-col gap-px ${
                    fieldState.error && 'border border-alert'
                  }`}
                >
                  <Radio
                    {...field}
                    data-cy="modal-radio-continue"
                    value="continue"
                    label="Yes"
                  />
                  <Radio
                    {...field}
                    data-cy="modal-radio-cancel"
                    value="no"
                    label="No"
                  />
                </div>
              )}
            />
          </div>
          <button
            className="h-10 w-full rounded bg-primary-hover text-16 font-medium hover:bg-primary border-bg-primary text-[#F2F2F2]"
            data-cy="modal-continue-button"
            onClick={onModalSubmit}
          >
            Continue
          </button>
        </form>
      </dialog>
    </div>
  )
}

export default ReitIncomeScreen
