import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { accountsSchema, AccountsDataTypes } from './schema'
import { useSearchParams } from 'react-router-dom'
import { z } from 'zod'

const accountResponseSchema = z.object({
  total_investment_count: z.number().int().min(0),
})

export const getAccounts = async (params: URLSearchParams) => {
  const response = await api.get('/dashboard/investment-accounts/', {
    params,
  })
  const data = accountsSchema.parse(response)
  const accountsDataWithCount = await Promise.all(
    data.accounts.map(async (account) => {
      const accountResponse = await api.get(
        `/dashboard/investment-accounts/${account.uuid}/active-investments`
      )
      const responseData = accountResponseSchema.parse(accountResponse)
      return {
        ...account,
        total_investment_count: responseData.total_investment_count,
      }
    })
  )
  return { ...data, accounts: accountsDataWithCount }
}

export const useOwnershipTypes = () => {
  const [searchParams] = useSearchParams()

  return useQuery<AccountsDataTypes>({
    queryKey: ['ownership-types', searchParams.get('page')],
    queryFn: () => getAccounts(searchParams),
    staleTime: Infinity,
    keepPreviousData: true,
  })
}
