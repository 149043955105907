// import { z } from 'zod'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTrigger,
} from '@/components/ui/dialog'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { useQueryClient } from '@tanstack/react-query'

// const investmentMenuSchema = z.object({
//   id: z.string(),
//   investment_uuid: z.string().uuid(),
// })

// type MenuTypes = z.infer<typeof investmentMenuSchema>

export const WaitlistInvestmentMenu = () => {
  const queryClient = useQueryClient()
  return (
    <Popover>
      <PopoverTrigger>
        <i className="px-6 py-4 fa-regular fa-ellipsis-vertical"></i>
      </PopoverTrigger>
      <PopoverContent
        side="left"
        className="w-[187px] text-base px-0 py-2 rounded flex flex-col"
      >
        <Dialog>
          <DialogTrigger>
            <div className="w-full text-left px-4 py-3 hover:bg-bg-light">
              Remove from List
            </div>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogDescription className="text-content-black text-left">
                <div className="text-content-black font-medium text-[22px] mb-10">
                  Remove this investment?
                </div>
                <div className="text-16 mb-10">
                  The investment will be removed from your waitlist.
                </div>
                <div className="flex gap-4">
                  <Button
                    variant="RM"
                    size="RM"
                    onClick={() => {
                      // TO DO: Hook up remove from waitlist functionality
                      queryClient.invalidateQueries(['investments'])
                    }}
                  >
                    Yes - remove it.
                  </Button>
                  <Button variant="RMSecondary" size="RMSecondary">
                    No - keep it.
                  </Button>
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      </PopoverContent>
    </Popover>
  )
}
