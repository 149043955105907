import { Controller, useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import { useInvestDataState } from '../_investDataState'
import FormFooter from '../../shared/FormFooter'
import FileUpload from '../../../components/FileUpload'

interface FormType {
  photoID: string
  jointPhotoID: string
}

const UploadScreen = () => {
  const nav = useNavigate()
  const queryClient = useQueryClient()
  const { data: investData } = useInvestDataState()

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormType>({
    defaultValues: {
      photoID: '',
    },
  })

  const handleAfterUpload = (file: File) => {
    setValue('photoID', file.name)
  }

  const onSubmit = handleSubmit((data: FormType) => {
    console.log('Photo Screen data: ', data)
    //add filename
    queryClient.setQueryData<FormType | undefined>(['invest'], (oldData) => {
      if (oldData)
        return {
          ...oldData,
          individual: {
            photoID: data.photoID,
          },
        }
    })
    // if fail IDology again, go to photo screen
    nav(`/invest/${investData.opportunity.id}/signature`)
  })

  return (
    <div>
      <h1 className="mb-6 text-2xl font-bold text-content-black">
        Upload Your Documents
      </h1>
      <p className="mb-6 text-16 text-content-black">
        Please upload the following documents to complete your investment.
      </p>

      <form onSubmit={onSubmit}>
        <div className="mb-2 text-16">Upload Check Image</div>
        <div className="mb-6">
          <Controller
            name="photoID"
            control={control}
            rules={{ required: 'File is required' }}
            render={({ field }) => (
              <FileUpload
                {...field}
                accept="image/*,.pdf"
                label="Upload Photo"
                uploadFileFunction={handleAfterUpload}
              />
            )}
          />
          {errors?.photoID?.message && (
            <span className="mb-1 text-xs text-alert">
              {errors.photoID.message}
            </span>
          )}
          <p className="mt-4 text-center text-[13px]">
            Upload a JPG, JPEG, PNG, or PDF
          </p>
        </div>
      </form>
      <div className="fixed bottom-0 left-0 w-full px-4">
        <FormFooter
          backLocation={investData?.backLocation}
          submitFunc={onSubmit}
        />
      </div>
    </div>
  )
}

export default UploadScreen
