import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useInvestDataState } from './_investDataState'

import { getAccredQuestions, postAccredQuestions } from '../shared/_api'
import { AccredTypes } from '../shared/_types'

export const useAccredDataState = () => {
  const { data: investData } = useInvestDataState()

  return useQuery({
    queryKey: ['accred'],
    queryFn: async () => {
      const data = await getAccredQuestions(investData?.investment?.id)
      return {
        ...data,
      }
    },
    enabled: !!investData?.investment,
    staleTime: Infinity,
  })
}

export const useAccredMutation = () => {
  const { data: investData } = useInvestDataState()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: AccredTypes) =>
      postAccredQuestions(investData?.investment?.id, {
        question_list: data.question_list,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['invest'],
      })
    },
  })
}
