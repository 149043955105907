export const verification = {
  eav: `Accredited Investor Verification`,
  eavCopy: `Due to Securities and Exchange Commission requirements, further
  steps to validate your status as an Accredited Investor are
  needed.`,
  verificationMethod: `Verification Method`,
  verificationMethodCopy: `Select your preferred verification method below.`,
  preferredMethod: `Preferred Method`,
  authorizedThirdParty: `Authorized Third Party`,
  thirdPartyMethod: `Third-Party Method`,
  thirdPartyMethodCopy: `Please provide verification of your accreditation from a third party such as an accountant or attorney.`,
  thirdPartyForm: `Third-Party Form`,
  thirdPartyFormCopy: `Provide a letter dated within the last 90 days from your attorney, accountant or broker dealer verifying your
  accreditation status.`,
  additionalInformation: `Additional Information`,
  additionalInformationCopy: `Please provide any additional context/comments you deem relevant about the information you are providing.`,
  thirdPartyVerification: `Third-Party Verification`,
  thirdPartyVerificationCopy: `Provide the contact info for your CPA/accountant or attorney, and we will send the necessary forms.`,
  thirdPartyType: `Third Party Type`,
  income: `Income`,
  lastTwoYears: `Upload your income verification documents for the last two years`,
  perSEC: `Per SEC requirements, we need to validate your income for the last 2 years. You may provide a W2, 1040, 1099, or tax return.`,
  northCap: `North Capital Private Securities ("NCPS") is contracted to perform accreditation verification on your behalf. You authorize your documents to be shared with NCPS.`,
  taxDocumentTypes: `You may provide a W2, 1040, 1099, or tax return.`,
  netWorth: `Net Worth`,
  netWorthCopy1: `For us to verify your net worth meets minimum requirements, typical documents submitted may include:`,
  netWorthVerify: `Upload your net worth verification documents`,
  bankNinetyDays: `Bank or brokerage statements dated within the last 90 days`,
  scheduleInvestment: `Schedule of investment properties with copies of current mortgage statements, if applicable`,
  uploadRecentNetWorth: `Upload your recent year net worth verification documents`,
  netWorth1: `Net Worth Document 1 (required):`,
  netWorth2: `Net Worth Document 2 (optional):`,
  netWorth3: `Net Worth Document 3 (optional):`,
}
