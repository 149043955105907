import { Skeleton } from '@/components/ui/skeleton'

export function SkeletonItems({ count = 1 }: { count: number }) {
  return Array.from({ length: count }, (_, index) => ({
    key: `${index}`,
  })).map((item) => (
    <div key={item.key} className="flex w-full items-center p-4 space-x-4">
      <div className="space-y-2">
        <Skeleton className="h-4 w-[100px]" />
        <Skeleton className="h-4 w-[350px]" />
        <Skeleton className="h-4 w-[200px]" />
        <Skeleton className="h-4 w-[200px]" />
      </div>
    </div>
  ))
}
