export const tenThirtyOne = {
  header: `1031 Details`,
  existingExchange: `Is this investment part of an existing 1031 exchange whereby you are using proceeds from the sale of another property to fund this investment?`,
  irs1031: `Internal Revenue Code Section 1031`,
  irs1031Modal: `Internal Revenue Code Section 1031 (“Section 1031”)
  contains complex tax concepts and certain tax consequences
  may vary depending on the individual circumstances of each
  investor.
  <br />
  <br />
  RM Securities and its affiliates make no representation or
  warranty of any kind with respect to the tax consequences
  of your investment or that the IRS will not challenge any
  such treatment. You should consult with and rely on your
  own tax advisor about the tax aspects with respect to your
  particular circumstances.`,
  exchangeDetails: `Since this is a 1031 Exchange, we need to collect a few additional details.`,
  propertyDetails: `Property Details`,
  additionalDetails: `Additional Details`,
  accommodatorInfo: `Accommodator Info`,
  accommodatorInfoCopy: `In a 1031 Exchange, investors work with an accommodator as well as the sponsor of the investment opportunity. Sometimes these companies are also known as facilitators or qualified intermediaries. Do you have an accommodator to facilitate your exchange?`,
  contactInfo: `Accommodator Contact Info`,
  contactInfoCopy: `Please provide your accommodator's contact information below.`,
}
