interface ContactUsProps {
  links: {
    label: string
    to: string | (() => void)
  }[]
}

export const ContactUs = ({ links }: ContactUsProps) => {
  return (
    <div className="w-full flex border-t border-[#E7E7E7] pt-6 items-center box-border">
      <div className="text-[17px] font-medium">Contact us</div>
      <div className="ml-6 flex-1">
        {links.map((link, index) =>
          typeof link.to !== 'string' ? (
            <button
              className="text-[14px] leading-[16px] px-4 py-2 cursor-pointer inline-flex items-center"
              key={index}
              onClick={(e) => {
                e.preventDefault()
                if (typeof link.to === 'function') {
                  link.to()
                }
              }}
            >
              <span>{link.label}</span>
              <i className="text-primary w-2 h-4 ml-2 fa-regular fa-angle-right" />
            </button>
          ) : (
            <a
              className="text-[14px] leading-[16px] px-4 py-2 cursor-pointer inline-flex items-center"
              key={index}
              href={link.to}
            >
              <span>{link.label}</span>
              <i className="text-primary w-2 h-4 ml-2 fa-regular fa-angle-right" />
            </a>
          )
        )}
      </div>
    </div>
  )
}

export default ContactUs
