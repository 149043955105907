import { useQuery } from '@tanstack/react-query'
import { api } from '@/routes/shared/_api'
import { CommPrefsTypes, commPrefsSchema } from './schema'

export const getCommPrefs = async () => {
  const response = await api.get('/dashboard/profile/comm-prefs')
  return commPrefsSchema.parse(response)
}

export const useCommPrefs = () => {
  return useQuery<CommPrefsTypes>({
    queryKey: ['comm-prefs'],
    queryFn: getCommPrefs,
    staleTime: Infinity,
  })
}
