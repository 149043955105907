import { ReactNode, forwardRef } from 'react'
import { FieldError } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'

interface InputProps {
  name: string
  label: string | ReactNode
  placeholder?: string
  helpText?: string | ReactNode
  error: string | FieldError | undefined
  onBlur?: () => void
  onChange?: () => void
  value: string | undefined
}

export const MoneyInput = forwardRef<HTMLInputElement, InputProps>(
  (props, _ref) => {
    const {
      name,
      onBlur,
      onChange,
      placeholder,
      value,
      label,
      helpText,
      error,
    } = props
    return (
      <div>
        <span
          className={`mb-2 block text-base font-normal ${
            error ? 'text-alert' : 'text-content-black'
          }`}
        >
          {label}
        </span>
        <NumericFormat
          className={`mb-2 h-[42px] w-full rounded border focus:outline-none focus:ring-1 focus:border-primary ${
            error ? 'border-alert' : 'border-input'
          } px-3 py-1.5 text-16`}
          thousandSeparator=","
          prefix="$"
          allowNegative={false}
          placeholder={placeholder}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          pattern="\d*"
          data-cy={`money-input-${name}`}
        />
        {error && (
          <p className="mb-1 text-xs text-alert">
            {(error as FieldError).message}
          </p>
        )}
        {helpText && (
          <div className="text-xs text-content-light">{helpText}</div>
        )}
      </div>
    )
  }
)

export default MoneyInput
