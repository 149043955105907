import { Auth } from '@aws-amplify/auth'
declare global {
  interface Window {
    Cypress?: Cypress.Cypress
  }
}

export const awsConfig = {
  Auth: {
    region: 'us-west-2',
    userPoolId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `us-west-2_Z1cMSCXWl`
        : `us-west-2_iOmHSc6io`,
    userPoolWebClientId:
      window.location.origin === 'https://my.realtymogul.com'
        ? `715inci7q6uq2kpv3fdv9opknj`
        : `2iivupb66i0h6ng72dt0im61gp`,
  },
}

export const getUser = () =>
  Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => user)

export const cognitoLogout = () => {
  Auth.signOut()
}
