import { forwardRef } from 'react'
interface LikertProps {
  name: string
  value: string
  label: string
  checked?: boolean
  errorMessage?: string
}

export const Likert = forwardRef<HTMLInputElement, LikertProps>(
  (props, ref) => {
    return (
      <div>
        <input
          className="peer hidden"
          type="radio"
          id={`${props.name}-${props.value}`}
          ref={ref}
          checked={props.checked}
          {...props}
        />
        <label
          className="relative flex w-full cursor-pointer select-none px-4 py-3 text-content-light hover:cursor-pointer hover:bg-primary-hover hover:text-white peer-checked:bg-primary peer-checked:text-white"
          htmlFor={`${props.name}-${props.value}`}
          data-cy={`${props.name}-${props.value}`}
        >
          {props.label}
        </label>
      </div>
    )
  }
)

export default Likert
