import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInvestDataState } from '../_investDataState'
import { useMutateEAV } from '../_eavDataState'
import { getDrupalUrl, uploadNetWorthForm } from '../../shared/_api'
import { findRoute } from '../../../rules/findRoute'

import FormFooter from '../../shared/FormFooter'
import FileUpload from '../../../components/FileUpload'
import Stepper from '../../../components/Stepper'
import Sidebar from '../Sidebar'
import ServerError from '../../../components/ErrorAlert/ServerError'

interface IncomeVerificationFormTypes {
  preferredMethod: string
  additional_info: string | undefined
  recentDocumentFirst: File
  recentDocumentSecond?: File
  recentDocumentThird?: File
}

const NetWorthVerification = () => {
  const [serverError, setServerError] = useState('')
  const nav = useNavigate()
  const { data: investData } = useInvestDataState()
  const { mutateAsync: updateEAV } = useMutateEAV()
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<IncomeVerificationFormTypes>({
    defaultValues: {
      preferredMethod:
        investData?.verification?.preferredMethod || 'submitForm',
    },
  })

  const handleAfterUpload = (file: File) => {
    setValue('recentDocumentFirst', file)
  }

  const handleAfterUpload2 = (file: File) => {
    setValue('recentDocumentSecond', file)
  }

  const handleAfterUpload3 = (file: File) => {
    setValue('recentDocumentThird', file)
  }

  // const backLocation = `/invest/${investData?.investment.id}/verification`

  const onSubmit = handleSubmit((data) => {
    console.log(errors, data)
    Promise.all([
      uploadNetWorthForm(investData?.investment?.id, data.recentDocumentFirst),
      data.recentDocumentSecond &&
        uploadNetWorthForm(
          investData?.investment?.id,
          data.recentDocumentSecond
        ),
      data.recentDocumentThird &&
        uploadNetWorthForm(
          investData?.investment?.id,
          data.recentDocumentThird
        ),
    ]).then(() => {
      updateEAV({
        verification_type: 'net_worth',
        additional_info: data.additional_info,
      })
        .then(() => {
          if (investData.investment?.status === 'pledge') {
            window.location.href = `${getDrupalUrl()}investor-dashboard/active-investments`
          } else {
            findRoute({
              ...investData,
              investment_state: {
                ...investData.investment_state,
                eav: 'submitted',
              },
            }).then((route) => {
              nav(`/invest/${investData.opportunity.id}/${route.type}`)
            })
          }
        })
        .catch((error) => {
          console.error(error.message)
          setServerError(error.message)
        })
    })
  })

  return (
    <div className="p-6 xl:p-0">
      <Stepper currentSection={2} currentStep={4} totalStepCount={5} />
      <div className="flex gap-[72px] mb-6">
        <div className="w-full">
          <h1 className="mb-2 text-2xl font-bold text-content-black">
            {t('verification:eav')}
          </h1>
          <p className="mb-6 text-base italic text-content-light">
            {t('verification:netWorth')}
          </p>
          <p className="mb-6 text-xl font-medium">
            {t('verification:netWorthVerify')}
          </p>
          <p className="mb-6 text-16 text-content-black">
            {t('verification:netWorthCopy1')}
          </p>

          <ul className="mb-6 ml-3 list-disc pl-4">
            <li>{t('verification:bankNinetyDays')}</li>
            <li>{t('verification:scheduleInvestment')}</li>
          </ul>
          <form onSubmit={onSubmit} className="xl:">
            <div className="mb-6">
              <p className="mb-6 text-[19px] font-medium">
                {t('verification:uploadRecentNetWorth')}
              </p>
              <p className="mb-6 text-16 text-content-black">
                {t('verification:netWorth1')}
              </p>
              <Controller
                name="recentDocumentFirst"
                control={control}
                rules={{ required: 'File is required' }}
                render={({ field, fieldState }) => (
                  <div>
                    <FileUpload
                      {...field}
                      accept=".pdf"
                      label={t('common:uploadDocument')}
                      uploadFileFunction={handleAfterUpload}
                    />
                    {fieldState.error && (
                      <span className="mb-1 text-xs text-alert">
                        {fieldState.error.message}
                      </span>
                    )}
                  </div>
                )}
              />

              <p className="my-4 text-center text-[13px]">
                {t('common:pdfOnly')}
              </p>
            </div>
            <div className="mb-6">
              <p className="mb-6 text-16 text-content-black">
                {t('verification:netWorth2')}
              </p>
              <Controller
                name="recentDocumentSecond"
                control={control}
                render={({ field }) => (
                  <FileUpload
                    {...field}
                    accept=".pdf"
                    label={t('common:uploadDocument')}
                    uploadFileFunction={handleAfterUpload2}
                  />
                )}
              />
              {errors?.recentDocumentSecond?.message && (
                <span className="mb-1 text-xs text-alert">
                  {errors.recentDocumentSecond.message}
                </span>
              )}
              <p className="my-4 text-center text-[13px]">
                {t('common:pdfOnly')}
              </p>
            </div>
            <div className="mb-6">
              <p className="mb-6 text-16 text-content-black">
                {t('verification:netWorth3')}
              </p>
              <Controller
                name="recentDocumentThird"
                control={control}
                render={({ field }) => (
                  <FileUpload
                    {...field}
                    accept=".pdf"
                    label={t('common:uploadDocument')}
                    uploadFileFunction={handleAfterUpload3}
                  />
                )}
              />
              {errors?.recentDocumentThird?.message && (
                <span className="mb-1 text-xs text-alert">
                  {errors.recentDocumentThird.message}
                </span>
              )}
              <p className="my-4 text-center text-[13px]">
                {t('common:pdfOnly')}
              </p>
            </div>
            <p className="mb-6 text-xl font-medium">
              {t('verification:additionalInformation')}
            </p>
            <p className="mb-6 text-16 text-content-black">
              {t('verification:additionalInformationCopy')}
            </p>
            <textarea
              className="mb-6 h-[100px] w-full rounded border border-[#A4C2D5] p-3"
              {...register('additional_info')}
            />
            <p className="mb-6 text-16 text-content-black">
              {t('verification:northCap')}
            </p>
            <FormFooter submitFunc={onSubmit} />
            {serverError && (
              <ServerError
                serverError={serverError}
                id={investData?.investment?.id}
              />
            )}
          </form>
        </div>
        <div className="hidden xl:block">
          <Sidebar />
        </div>
      </div>
    </div>
  )
}

export default NetWorthVerification
