type UnreadFilterTypes = {
  count: number
  unreadCount?: number
  handleUnreadClick: () => void
  handleViewAllClick: () => void
  unreadFilterOn?: string
}

export function UnreadFilter({
  count,
  unreadCount,
  handleUnreadClick,
  handleViewAllClick,
  unreadFilterOn = 'false',
}: UnreadFilterTypes) {
  const handleClick = () => {
    if (unreadFilterOn === 'true') handleViewAllClick()
    else handleUnreadClick()
  }

  return unreadFilterOn === 'true' ? (
    <div>
      {unreadCount && (
        <>
          <span>{`${unreadCount} unread - `}</span>
          <span className="cursor-pointer underline" onClick={handleClick}>
            view all
          </span>
        </>
      )}
    </div>
  ) : (
    <div>
      {count} messages
      {unreadCount && ', '}
      {unreadCount && (
        <span className="underline cursor-pointer" onClick={handleClick}>
          {unreadCount} unread
        </span>
      )}
    </div>
  )
}
